import React, { useMemo } from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const CrInvoicingTypePicker: React.FC = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  const options = useMemo<Option<ContractForm['cr_facturation_type']>[]>(
    () => [
      {
        value: 'initial_estimated_regulation_monthly_consolidated_yearly_definitive',
        label: 'sales_management.cr_invoice_type_initial_estimated_regulation_monthly_consolidated_yearly_definitive',
      },
      {
        value: 'initial_estimated_regulation_yearly_definitive',
        label: 'sales_management.cr_invoice_type_initial_estimated_regulation_yearly_definitive',
      },
      {
        value: 'initial_consolidated_regulation_definitive',
        label: 'sales_management.cr_invoice_type_initial_consolidated_regulation_definitive',
      },
      {
        value: 'initial_spot_regulation_yearly_definitive',
        label: 'sales_management.cr_invoice_type_initial_spot_regulation_yearly_definitive',
      },
    ],
    [],
  );

  return (
    <Controller
      name="cr_facturation_type"
      control={control}
      rules={fieldRules('cr_facturation_type')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.cr_invoice_type"
          size="lg"
          options={options}
          selectedOption={field.value}
          onChange={(option) => field.onChange(option?.value)}
          classNamePrefix="select-invoicing-type"
          readOnly={readonly}
          hasError={!!fieldState.error}
          isSearchable
        />
      )}
    />
  );
};
