import React from 'react';
import { Button } from '@GDM/Button';
import { Hr } from '@GDM/Hr';
import { useInstallationFields } from '@pages/Installations/Installation/hooks/useInstallationFields';
import { useShowMeterForm } from '../../context/showMeterForm.context';
import { useInstallationFormQuery } from '../../useInstallationFormQuery';
import { CodeInput } from './fields/CodeInput';
import { IpCheckbox } from './fields/IpCheckbox';
import { IpInput } from './fields/IpInput';
import { KeyInput } from './fields/KeyInput';
import MandateSelect from './fields/MandateSelect/MandateSelect';
import { MeterPassAddressInput } from './fields/MeterPassAddressInput';
import { MeterTypeSelect } from './fields/MeterTypeSelect';
import { ModelSelect } from './fields/ModelSelect';
import { NumberInput } from './fields/NumberInput';
import { PrmInput } from './fields/PrmInput/PrmInput';
import { ProductionCheckbox } from './fields/ProductionCheckbox';
import { SerialNumberInput } from './fields/SerialNumberInput';
import { ShuntInput } from './fields/ShuntInput';
import { TcpPortInput } from './fields/TcpPortInput';

export const MeterSettingsSection = () => {
  const { hasField } = useInstallationFields();
  const { showMeterForm, setShowMeterForm } = useShowMeterForm();
  const {
    query: { data: installation },
  } = useInstallationFormQuery();

  return (
    <div className="d-flex flex-column">
      {!installation?.has_meter && (
        <div className="p-3">
          <Button
            text={showMeterForm ? 'common.cancel' : 'admin.installations.add_installation_meter'}
            variant={showMeterForm ? 'link-secondary' : 'link'}
            size="sm"
            data-cy="create-meter"
            icon={showMeterForm ? 'XSquare' : 'PlusSquare'}
            onClick={() => setShowMeterForm((state) => !state)}
          />
        </div>
      )}

      {showMeterForm && (
        <>
          {hasField('mandate_uuid') && (
            <>
              <div className="p-3">
                <MandateSelect />
              </div>
              <Hr />
            </>
          )}

          <div className="p-3 d-flex flex-column gap-3 hide-empty">
            {hasField('prm') && <PrmInput />}
            {hasField('producteur') && <ProductionCheckbox />}
            {hasField('boitier_ip') && <IpCheckbox />}

            <div className="d-flex gap-3 hide-empty">
              {hasField('model') && <ModelSelect />}
              {hasField('serial_number') && <SerialNumberInput />}
              {hasField('meter_pass_address') && <MeterPassAddressInput />}
              {hasField('code') && <CodeInput />}
            </div>

            <div className="hide-empty">
              <div className="d-flex gap-3 hide-empty">
                {hasField('key') && <KeyInput />}
                {hasField('numero') && <NumberInput />}
                {hasField('ip') && <IpInput />}
                {hasField('tcp_port') && <TcpPortInput />}
              </div>

              <div className="d-flex gap-3 hide-empty">
                {hasField('aiguillage') && <ShuntInput />}
                {hasField('typecompteur') && <MeterTypeSelect />}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
