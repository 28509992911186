import 'react-datepicker/dist/react-datepicker.css';
import React, { useContext, useMemo, useState } from 'react';
import localeContext from '@context/locale.context';
import { Icon } from '@GDM/Icon';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { isValidDate } from '@utils/isValidDate';
import classNames from 'classnames';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { datePickerContext } from './date-picker.context';
import styles from './date-picker.module.scss';
import { DatePickerContext, DatePickerProps, PreDefinedPeriods } from './date-picker.types';
import { DatePickerContainer } from './DatePickerContainer';
import { DatePickerHeader } from './DatePickerHeader';
import { DISPLAY_DATE_FORMATS } from './display-date-formats';
import { DisplayInput } from './DisplayInput';
import { LOCALES } from './i18n';
import { TimePicker } from './TimePicker';

registerLocale('fr', LOCALES.fr);
registerLocale('es', LOCALES.es);
registerLocale('it', LOCALES.it);

/**
 * Generates a <DatePicker /> component with a custom localized header.
 *
 * @prop hasPredefinedPeriods defines whether or not the pre defined periods (next week,
 * this week, etc) should be shown. This prop set to true will display the default
 * pre-defined periods. It is possible to customise which periods are displayed, using:
 * @prop periods
 */
function BaseDatePicker(
  {
    className,
    customPeriods,
    'data-cy': dataCy,
    dateFormat,
    disabled,
    endDate,
    errorMessage,
    hasError,
    hasPreDefinedPeriods,
    id,
    tooltip,
    isClearable,
    label,
    maxDate,
    minDate,
    name,
    onCalendarClose,
    onChange,
    onClickPeriod,
    periods,
    picks = 'default',
    placeholderText,
    readOnly,
    selected,
    selectedPeriod: preSelectedPeriod,
    selectsRange,
    showHeader = true,
    showPeriodBadge,
    size,
    startDate,
    transformDate,
  }: DatePickerProps,
  ref?: React.LegacyRef<ReactDatePicker<never, undefined>>,
): JSX.Element {
  const locale = useContext(localeContext);
  const { t } = useTranslation();
  const [selectedPeriod, setSelectedPeriod] = useState<DatePickerContext['selectedPeriod'] | PreDefinedPeriods>(
    preSelectedPeriod,
  );

  const contextValue: DatePickerContext = useMemo(
    () => ({
      customPeriods,
      hasError,
      hasPreDefinedPeriods,
      maxDate,
      minDate,
      onChange: onChange as (
        date: typeof selectsRange extends true ? [Date | null, Date | null] : Date | null,
        event?: React.SyntheticEvent<unknown, Event> | undefined,
      ) => void | undefined,
      onClickPeriod,
      periods,
      picks,
      placeholderText,
      selectedPeriod: selectedPeriod || preSelectedPeriod,
      selectsRange: (selectsRange as typeof selectsRange extends true ? true : false | undefined) || false,
      setSelectedPeriod,
      showPeriodBadge,
      size,
    }),
    [
      customPeriods,
      hasError,
      hasPreDefinedPeriods,
      maxDate,
      minDate,
      onChange,
      onClickPeriod,
      periods,
      picks,
      placeholderText,
      selectedPeriod,
      preSelectedPeriod,
      selectsRange,
      showPeriodBadge,
      size,
    ],
  );
  const selectedDate = selected && isValidDate(selected) ? new Date(selected) : undefined;
  const displayDateFormat = dateFormat || DISPLAY_DATE_FORMATS[picks];

  return (
    <datePickerContext.Provider value={contextValue}>
      <div className={styles.wrapper}>
        {!!label && (
          <label htmlFor={id} className={styles.label}>
            <span title={t(label)}>{t(label)}</span>
            {tooltip && <Icon name="Info" size={14} title={tooltip} className={classNames('ml-1', styles.tooltip)} />}
          </label>
        )}
        <ReactDatePicker
          autoComplete="off"
          calendarContainer={DatePickerContainer}
          calendarStartDay={1}
          customInput={<DisplayInput isClearable={isClearable} />}
          customTimeInput={<TimePicker />}
          clearButtonClassName={styles['clear-button']}
          dateFormat={displayDateFormat}
          disabled={disabled}
          disabledKeyboardNavigation
          endDate={selectsRange ? endDate : null}
          id={id}
          isClearable={isClearable}
          locale={locale}
          maxDate={maxDate}
          minDate={minDate}
          name={name}
          onCalendarClose={onCalendarClose}
          readOnly={readOnly}
          ref={ref}
          selected={selectedDate}
          selectsRange={selectsRange}
          shouldCloseOnSelect={picks !== 'datetime'}
          showMonthYearPicker={picks === 'months'}
          showTimeInput={picks === 'datetime'}
          showYearPicker={picks === 'years'}
          startDate={selectsRange ? startDate : null}
          strictParsing={true}
          timeCaption={t('common.select_time')}
          timeInputLabel=""
          wrapperClassName={classNames(className, dataCy && `cy-${dataCy}`, styles['date-picker'])}
          onChange={(value, e) => {
            setSelectedPeriod(PreDefinedPeriods.Custom);
            const realValue = value as Date & [Date | null, Date | null];
            const internalValue = (transformDate ? transformDate(realValue) : realValue) as Date &
              [Date | null, Date | null];

            onChange?.(internalValue, e);
          }}
          renderCustomHeader={(props) =>
            picks !== 'years' &&
            showHeader && (
              <DatePickerHeader
                {...props}
                yearOnly={picks === 'months'}
                maxDate={maxDate}
                minDate={minDate}
                locale={locale}
                data-cy={dataCy}
              />
            )
          }
        />
        {!!errorMessage && <Text size="sm" className="mt-1" type="danger" text={errorMessage} />}
      </div>
    </datePickerContext.Provider>
  );
}

export const DatePicker = React.forwardRef(BaseDatePicker) as (
  props: DatePickerProps & { ref?: React.Ref<ReactDatePicker> },
) => JSX.Element;
