import { constrainFractionDigits, toDate } from '@utils/formatters';
import { ContractForm, ContractSubPeriod, ContractSubPeriodForm, Fee } from '@utils/types/contract';

export function parseSubPeriods(subPeriods: ContractSubPeriod[]): ContractForm['contract_sub_periods_attributes'] {
  const parsedSubPeriods: ContractSubPeriodForm[] = subPeriods.map((period) => {
    // Parse Aggreg fees
    const aggreg_fees: ContractSubPeriodForm['aggreg_fees'] = [
      ...(period.aggreg_fee_fixed ? [{ value: period.aggreg_fee_fixed, type: 'fixed' } as Fee] : []),
      ...(period.aggreg_fee_percentage
        ? [{ value: constrainFractionDigits(period.aggreg_fee_percentage * 100), type: 'percentage' } as Fee]
        : []),
    ];

    const volume_type = period.profile_hedge_attributes ? 'hedge_profile' : 'full';

    return {
      ...period,
      volume_type,
      aggreg_fees,
      period_type: period.market_future_id ? 'market_future' : 'custom',
      price_model_attributes: period.price_model,
      dates: {
        start_date: toDate(period.start_date),
        end_date: toDate(period.end_date),
      },
    };
  });

  return parsedSubPeriods || [];
}
