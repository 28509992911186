import React from 'react';
import { Input } from '@GDM/forms';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { getSymbol } from '@utils/currency/getSymbol';
import { Controller } from 'react-hook-form';
import { STATIC_PRICE_TYPES } from '../PriceTypePicker';

export const DisplayPrice: React.FC<{ index: number }> = ({ index }) => {
  const { control, watch, readonly } = useContractForm();
  const { fieldConfig, fieldRules, isDisabled } = useContractFields();
  const config = fieldConfig(`contract_sub_periods_attributes.${index}.display_price`);
  const rules = fieldRules(`contract_sub_periods_attributes.${index}.display_price`);
  const priceType = watch(`contract_sub_periods_attributes.${index}.price_type`);
  const disabled = isDisabled(`contract_sub_periods_attributes.${index}.display_price`);
  const currency = watch('currency');
  const fieldIsIrrelevant = priceType ? STATIC_PRICE_TYPES.includes(priceType) : false;

  return (
    <Controller
      name={`contract_sub_periods_attributes.${index}.display_price`}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          data-cy={`display-price-${index}`}
          label="common.price"
          size="lg"
          type="number"
          value={field.value ?? ''}
          hasError={!!fieldState.error}
          suffix={config?.unit || `${getSymbol(currency)}/MWh`}
          autoComplete="off"
          disabled={disabled || fieldIsIrrelevant}
          tooltip={disabled ? 'sales_management.info.has_price_rule' : null}
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
