import React, { useEffect, useRef } from 'react';
import { ProgressStepper } from '@GDM/forms';
import { Contract } from '@utils/types/contract';
import isEqual from 'lodash/isEqual';
import { useBuilder } from '../../Contract/Form/builder';
import { useContractForm, useHedgeBlocks, useSubPeriods } from '../../Contract/Form/hooks';

export const ReadOnlyForm: React.FC<{ contract: Contract }> = ({ contract }) => {
  const { steps } = useBuilder();
  const { setContract } = useContractForm();
  const { subPeriods, collapseAll } = useSubPeriods();
  const { hedgeBlocks, collapseAll: collapseAllHedgeBlocks } = useHedgeBlocks();
  const contractId = useRef<string | null>(null);
  const subPeriodRefs = useRef<string[] | undefined>();
  const hedgeBlockRefs = useRef<string[] | undefined>();

  // We're bringing in new data from an external source, and updating the context values accordingly.
  // I use refs for comparison to reduce the amount of re-renders.
  useEffect(() => {
    if (contract && contractId.current !== contract.id) {
      setContract(contract);
      contractId.current = contract.id;
    }

    const subPeriodIds = subPeriods?.fields.map((f) => f.field_internal_id).sort();
    const hedgeBlockIds = hedgeBlocks?.fields.map((f) => f.field_internal_id).sort();

    if (!isEqual(subPeriodIds, subPeriodRefs.current)) {
      collapseAll?.();
      subPeriodRefs.current = subPeriodIds;
    }

    if (!isEqual(hedgeBlockIds, hedgeBlockRefs.current)) {
      collapseAllHedgeBlocks?.();
      hedgeBlockRefs.current = hedgeBlockIds;
    }
  }, [collapseAll, collapseAllHedgeBlocks, contract, hedgeBlocks?.fields, setContract, steps, subPeriods?.fields]);

  return <ProgressStepper steps={steps} showNav={false} />;
};
