import React, { useMemo } from 'react';
import { ProgressStepper } from '@GDM/forms';
import classNames from 'classnames';
import styles from '../contract.module.scss';
import { FormAlerts } from '../FormAlerts';
import { useBuilder } from './builder';
import { CONTRACT_TYPE_FIELDS, COMMON_FIELDS } from './builder/config';
import { useContractForm } from './hooks';

export const Form: React.FC = () => {
  const { steps } = useBuilder();
  const { isLoading, getValues } = useContractForm();
  const contractType = getValues('type');

  const infoBoxDefinitions = useMemo(() => {
    const fields = contractType ? [...CONTRACT_TYPE_FIELDS[contractType], ...COMMON_FIELDS] : [...COMMON_FIELDS];

    return fields.reduce<Record<string, { title?: string; text: string }>>((map, field) => {
      if (!field.info) return map;

      return { ...map, [field.name]: field.info };
    }, {});
  }, [contractType]);

  return (
    <>
      <FormAlerts />
      <ProgressStepper
        steps={steps}
        className={classNames('mt-4', styles.stepper)}
        isLoading={isLoading}
        containerMaxWidth={60}
        infoBoxDefinitions={infoBoxDefinitions}
      />
    </>
  );
};
