import { useMemo } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { Step } from '@GDM/forms';
import { FormSection } from '@pages/Contracts/Contract/contracts.types';
import { BUYERS_THAT_HAVE_SY_BY_CEGEDIM } from '@utils/constants';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import { useContractFields, useContractForm } from '../hooks';
import { useMarketPlayers } from '../hooks/requests/useMarketPlayers';
import { DEFAULT_STEPS, VARIABLE_STEPS } from './config';
import { getFieldSection, getSections } from './helpers';

export const useBuilder = (): { steps: Partial<Record<FormSection, Step>> } => {
  const { watch } = useContractForm();
  const { installations } = useInstallationsContext<InstallationWithMeterInfo>();
  const [contractType, installationUuid, buyerId] = watch(['type', 'installation_uuid', 'buyer_id']);
  const { data: marketPlayers } = useMarketPlayers();
  const { allFields, hasField } = useContractFields();

  /**
   * Add to this object any step that has a special condition (when it's based on more
   * that just the contract type.)
   *
   * _e.g._: the `REMIT` section should only be displayed if the installation
   * has a power higher than 10MW (this is unrelated to the contract type.)
   *
   * **You should still add the corresponding fields to the config files.**
   */
  const EXTRA_CONDITIONS: Partial<Record<FormSection, boolean>> = useMemo(() => {
    const selectedInstallation = installations?.find(({ uuid }) => uuid === installationUuid);
    const edfOaId = marketPlayers?.find(({ short_name }) => BUYERS_THAT_HAVE_SY_BY_CEGEDIM.includes(short_name))?.id;

    return {
      // Only display invoicing settings for buyers that have SY by Cegedim.
      'invoicing-settings': buyerId === edfOaId,
      // Only display REMIT section for contracts that have installations higher than 10MW.
      remit: Boolean(selectedInstallation?.p_max && selectedInstallation.p_max > 10000),
    };
  }, [buyerId, marketPlayers, installationUuid, installations]);

  const requiredSections = getSections(contractType);

  const steps: Partial<Record<FormSection, Step>> = useMemo(() => {
    // Get all dynamic sections from the list computed by `getSections`, based on the contract type.
    const variableSections: Partial<Record<FormSection, Step>> = requiredSections.reduce((acc, curr) => {
      if (!VARIABLE_STEPS[curr] || (curr in EXTRA_CONDITIONS && !EXTRA_CONDITIONS[curr])) return acc;

      const fields = allFields?.filter((field) => getFieldSection(field.name, contractType) === curr);
      // Used to hide the section if none of its fields are visible.
      const noFieldsAreVisible = fields.every((f) => !hasField(f));

      if (noFieldsAreVisible) return acc;

      const sections: Partial<Record<FormSection, Step>> = { ...acc, [curr]: VARIABLE_STEPS[curr] };

      return sections;
    }, {});

    return {
      ...DEFAULT_STEPS,
      ...variableSections,
    };
  }, [EXTRA_CONDITIONS, allFields, contractType, hasField, requiredSections]);

  return { steps };
};
