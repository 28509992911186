import React from 'react';
import ControlledInput from '@components/FormInputs/ControlledInput';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { sectionStyles } from '@GDM/forms';
import { getSymbol } from '@utils/currency/getSymbol';
import { ContractSubPeriod } from '@utils/types/contract/sub-period';
import classNames from 'classnames';
import { IndexFormulaPicker } from '../../../Pricing/IndexFormulaPicker/IndexFormulaPicker';
import { RateChangePicker } from '../../../Pricing/RateChangePicker/RateChangePicker';
import { DatesPicker } from '../DatesPicker/DatesPicker';
import { CONTRACT_OPTIONS_MAP } from '../PriceTypePicker/options';
import styles from '../sub-period.module.scss';
import { SubPeriodTemplateProps } from '../types';

export function GoSubPeriod({
  index,
  control,
  readonly,
  currency,
  priceType,
}: SubPeriodTemplateProps & { priceType?: ContractSubPeriod['price_type'] }) {
  const priceIsIndexed = priceType === 'indexed';

  return (
    <>
      <div className={styles.section}>
        <div className={styles['row-3']}>
          <DatesPicker index={index} />
          <ControlledSelect
            control={control}
            options={CONTRACT_OPTIONS_MAP.ContractGo || []}
            name={`contract_sub_periods_attributes.${index}.price_type`}
            label="common.price_type"
            size="lg"
            rules={{ required: true }}
            menuPlacement="top"
            readOnly={readonly}
          />
          <ControlledInput
            control={control}
            name={`contract_sub_periods_attributes.${index}.display_price`}
            label="common.price"
            size="lg"
            type="number"
            autoComplete="off"
            readOnly={readonly}
            full
            rules={{ required: true }}
            suffix={`${getSymbol(currency)}/MWh`}
          />
        </div>
        {priceIsIndexed && (
          <div className={classNames(sectionStyles.row, 'mt-4')}>
            <IndexFormulaPicker />
            <RateChangePicker />
          </div>
        )}
      </div>
    </>
  );
}
