import React, { useMemo, useState } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { Select } from '@GDM/forms';
import { Option as TOption } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { SPOT_SOURCES } from '@utils/types/contract/constants';
import { CountryCode } from '@utils/types/countries';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { hasCountryDefinition } from '../../../helpers';
import { useContractFields, useContractForm } from '../../../hooks';
import { useValidateUniquenessRequest } from '../../../hooks/useValidateUniquenessRequest';
import { FormattedOption } from './FormattedOption';
import { Option } from './Option';

export const InstallationPicker: React.FC = () => {
  const { installations, isLoading } = useInstallationsContext();
  const { control, setValue, getValues, marketPlayer, readonly, trigger } = useContractForm();
  const [installationCountry, setInstallationCountry] = useState<CountryCode | null>(null);
  const { fieldRules } = useContractFields();
  const { validate } = useValidateUniquenessRequest();

  const options = useMemo(() => {
    return (
      installations?.map(({ name, uuid, energy, country, status }) => ({
        label: name,
        value: uuid,
        energy,
        country,
        status,
      })) || []
    );
  }, [installations]);

  const onChange = async (
    option: (TOption & { country?: CountryCode }) | null,
    field: ControllerRenderProps<ContractForm, 'installation_uuid'>,
  ): Promise<void> => {
    field.onChange(option?.value);

    const installation = installations?.find((installation) => installation.uuid === option?.value);
    setValue('seller_id', installation?.owner_uuid || null);

    if (installation?.energy) {
      setValue('energy', installation.energy);
    }

    // Set default area for sub period price areas if they don't exist yet
    const subPeriods = getValues('contract_sub_periods_attributes');
    if (subPeriods.length && installation) {
      const area = installation.country ?? null;

      subPeriods.forEach((_, index) => {
        setValue(`contract_sub_periods_attributes.${index}.price_model_attributes.area`, area);

        if (area && hasCountryDefinition(area) && SPOT_SOURCES[area].length === 1) {
          setValue(`contract_sub_periods_attributes.${index}.price_model_attributes.source`, SPOT_SOURCES[area][0]);
        }
      });
    }

    // Check that contract installation/dates/seller/type is unique
    validate();

    // Reset buyer value (only non-aggregator users) and contract type if country changes.
    if (installationCountry && option?.country !== installationCountry) {
      setInstallationCountry(option?.country || null);
      setValue('type', null);
      if (!marketPlayer) setValue('buyer_id', null);
    }

    // Reset installation SIRET number if Sy by Cegedim option is set.
    if (getValues('invoicing_platform') === 'cegedim') {
      if (installation?.siret) {
        setValue('_non_contract_props.installation.siret', installation?.siret);
      } else {
        if (await trigger('_non_contract_props.installation.siret')) trigger('_non_contract_props.installation.siret');
      }
    }
  };

  return (
    <Controller
      name="installation_uuid"
      control={control}
      rules={fieldRules('installation_uuid')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.installation"
          size="lg"
          options={options}
          selectedOption={field.value}
          onChange={(option) => onChange(option, field)}
          components={{ Option }}
          formatOptionLabel={FormattedOption}
          classNamePrefix="select-installation"
          hasError={!!fieldState.error}
          isLoading={isLoading}
          readOnly={readonly}
          isSearchable
        />
      )}
    />
  );
};
