import React, { useEffect } from 'react';
import { sectionStyles } from '@GDM/forms';
import classNames from 'classnames';
import { ContractSection } from '../../components';
import { useContractForm, useSubPeriods } from '../../hooks';
import { SubPeriod } from './SubPeriod';
import { SubPeriodActions } from './SubPeriodActions';
import styles from './subperiods.module.scss';

export const SubPeriods: React.FC = () => {
  const { readonly } = useContractForm();
  const { collapseAll, subPeriods } = useSubPeriods();

  useEffect(() => {
    if ((subPeriods?.fields && subPeriods.fields.length > 5) || readonly) collapseAll?.();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (readonly && !subPeriods?.fields?.length) return null;

  return (
    <ContractSection data-cy="sub-periods">
      <div className={classNames(styles.wrapper, 'p-3')}>
        {subPeriods?.fields?.map((period, i) => (
          <SubPeriod key={period.field_internal_id} index={i} periodId={period.field_internal_id} />
        ))}
      </div>

      {!readonly && (
        <div className={sectionStyles['section-footer']}>
          <SubPeriodActions />
        </div>
      )}
    </ContractSection>
  );
};
