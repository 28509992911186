import React from 'react';
import { Toggle } from '@GDM/Toggle';
import { Controller, type Control, type Path, type FieldValues } from 'react-hook-form';

type ToggleProps = React.ComponentProps<typeof Toggle>;

export const ControlledToggle = <T extends FieldValues>(
  props: Omit<ToggleProps, 'onChange' | 'value'> & {
    name: Path<T>;
    control: Control<T>;
  },
) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => <Toggle {...props} value={field.value} onChange={field.onChange} />}
    />
  );
};
