import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { Text } from '@GDM/Text';
import useMarketFutures from '@hooks/requests/useMarketFutures';
import { ContractForm } from '@utils/types/contract';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { useContractForm, useSubPeriods } from '../../../../hooks';
import { findNextCalPeriods } from './findNextCalPeriods';
import { useValidation } from './useValidation';

export const DatesPicker: React.FC<{ index: number }> = ({ index }) => {
  const { control, getValues, setValue, watch, trigger, readonly } = useContractForm();
  const { subPeriods, fromMarketFutures } = useSubPeriods();
  const validateSubPeriodDates = useValidation(index);

  const contractDates = watch(['start_date', 'end_date']);

  const { data: marketFutures, error } = useMarketFutures(contractDates[0], contractDates[1]);

  const preSelectedPeriod = marketFutures?.find(
    (i) => i.id === getValues(`contract_sub_periods_attributes.${index}.market_future_id`),
  )?.name;

  const customPeriods: Array<{ name: string; dates: [Date, Date] }> = [
    ...(marketFutures?.map((i) => ({
      name: i.name,
      dates: [new Date(i.start_date), new Date(i.end_date)] as [Date, Date],
    })) || []),
    {
      name: 'sales_management.contract_dates',
      dates: contractDates,
    },
  ];

  const onChange = (
    value: [Date | null, Date | null],
    field: ControllerRenderProps<ContractForm, `contract_sub_periods_attributes.${number}.dates`>,
  ): void => {
    field.onChange({ ...field.value, start_date: value[0], end_date: value[1] });
    trigger('contract_sub_periods_attributes');
  };

  const onClickPeriod = (period: string) => {
    const selected = marketFutures?.find((i) => i.name === period);

    if (selected && marketFutures) {
      setValue(`contract_sub_periods_attributes.${index}.market_future`, selected);
      setValue(`contract_sub_periods_attributes.${index}.market_future_id`, selected.id);

      // Auto generate the next periods if the user selects a CAL period and it's the last in the list
      if (selected.time_type === 'year' && index + 1 === subPeriods?.fields.length) {
        const cals = findNextCalPeriods(selected, marketFutures);
        if (cals) fromMarketFutures(cals);
      }
    }
  };

  return (
    <div>
      <Controller
        name={`contract_sub_periods_attributes.${index}.dates`}
        control={control}
        rules={{ validate: validateSubPeriodDates }}
        render={({ field, fieldState }) => (
          <>
            <DatePicker
              {...field}
              customPeriods={customPeriods}
              data-cy={`dates-picker-${index}`}
              endDate={field.value?.end_date}
              hasError={!!error || !!fieldState.error}
              label="sales_management.sub_period.start_end_dates"
              maxDate={contractDates[1]}
              minDate={contractDates[0]}
              onChange={(dates) => onChange(dates, field)}
              onClickPeriod={onClickPeriod}
              readOnly={readonly}
              // Makes React Form Hook Controller and React DatePicker work together
              // https://github.com/react-hook-form/react-hook-form/issues/9126#issuecomment-1298424347
              ref={(ref) => field.ref({ focus: ref?.setFocus })}
              selectedPeriod={preSelectedPeriod}
              selectsRange
              showPeriodBadge
              size="lg"
              startDate={field.value?.start_date}
            />
            {error && (
              <Text
                className="mt-1"
                size="sm"
                text="sales_management.form_errors.problem_loading_market_future"
                type="danger"
              />
            )}
            {fieldState.error && <Text className="mt-1" size="sm" text={fieldState?.error?.message} type="danger" />}
          </>
        )}
      />
    </div>
  );
};
