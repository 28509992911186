import React from 'react';
import { Restricted } from '@components/Restricted';
import { useInstallationsContext } from '@context/installations.context';
import { Button } from '@GDM/Button';
import type { InstallationWithMeterInfo } from '@utils/types/installation';
import get from 'lodash/get';
import { useContractForm } from '../Form/hooks';
import styles from './page-actions.module.scss';
import { REQUIRED_FIELDS_FOR_DRAFT } from './required-fields-for-draft';

export const PageActions: React.FC = () => {
  const { formState, isLoading, readonly, uuid, isEditing, isDraft, getValues, submitForm, watch } = useContractForm();
  const installationUuid = watch('installation_uuid');
  const { installations } = useInstallationsContext<InstallationWithMeterInfo>();
  const selectedInstallation = installations?.find((installation) => installationUuid === installation.uuid);
  const savingDraftIsDisabled = !REQUIRED_FIELDS_FOR_DRAFT.every((v) => !get(formState?.errors, v) && !!getValues(v));
  const isInstallationDraft = selectedInstallation?.status === 'draft';
  const savingIsDisabled = !formState.isValid || isLoading || isInstallationDraft;

  return (
    <div className={styles.wrapper}>
      <Button
        text="common.back"
        variant="link"
        size="sm"
        href="/v2/contracts"
        className="mr-3"
        data-cy="back-to-contracts"
      />
      {!readonly && (
        <>
          {(isDraft || !isEditing) && (
            <Button
              text="common.save_draft"
              variant="outline"
              size="sm"
              disabled={savingDraftIsDisabled}
              data-cy="save-draft-contract"
              onClick={() => {
                const contractData = getValues();
                submitForm?.({ ...contractData, status: 'draft' });
              }}
            />
          )}
          <Button
            text="common.save"
            variant="primary-2"
            size="sm"
            disabled={savingIsDisabled}
            data-cy="submit-contract"
            onClick={() => {
              const contractData = getValues();
              submitForm?.({ ...contractData, status: 'complete' });
            }}
          />
        </>
      )}

      {readonly && (
        <Restricted permissions={['display:edit_salesfocus']}>
          <Button text="common.edit" variant="primary-2" size="sm" href={`/v2/contracts/${uuid}/edit`} />
        </Restricted>
      )}
    </div>
  );
};
