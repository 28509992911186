import React, { useEffect, useMemo, useState } from 'react';
import { Input } from '@GDM/forms';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Controller, useFieldArray } from 'react-hook-form';
import styles from './hedge-profile-caps.module.scss';

export const HedgeProfileCaps = ({ subPeriodIndex, className }: { subPeriodIndex: number; className?: string }) => {
  const { control, getValues, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  const period = getValues(`contract_sub_periods_attributes.${subPeriodIndex}.profile_hedge_attributes.period`);
  const dates = getValues(`contract_sub_periods_attributes.${subPeriodIndex}.dates`);

  const startDate = dates?.start_date?.getTime();
  const endDate = dates?.end_date?.getTime();

  const { fields, replace } = useFieldArray({
    control,
    shouldUnregister: true,
    name: `contract_sub_periods_attributes.${subPeriodIndex}.profile_hedge_attributes.profile_hedge_caps_attributes`,
    keyName: 'hedge_profile_cap_id',
  });

  const [initialFields] = useState(fields);
  const [initialPeriod] = useState(period);

  const profilePeriods = useMemo(() => {
    if (!startDate || !endDate || readonly) return initialFields;

    const cap = period !== initialPeriod ? 0 : undefined;
    const periods =
      period === 'monthly' ? getMonthlyPeriods(startDate, endDate, cap) : getYearlyPeriods(startDate, endDate, cap);

    return periods;
  }, [endDate, initialFields, initialPeriod, period, readonly, startDate]);

  useEffect(() => {
    replace(profilePeriods);
  }, [profilePeriods, replace]);

  const formatMap = { yearly: 'YYYY-MM-DD', monthly: 'YYYY-MM' };
  const format = formatMap[period];

  return (
    <div className={classNames(styles['container'], className)}>
      {fields.map(({ start_date, end_date, hedge_profile_cap_id }, index) => (
        <div className={classNames(styles['wrapper'])} key={hedge_profile_cap_id}>
          <div className={classNames(styles['prefix'], 'fw:700')}>
            <span>{dayjs(start_date).format(format)}</span>
            {period === 'yearly' && (
              <>
                <span>&gt;</span>
                <span>{dayjs(end_date).format('YYYY-MM-DD')}</span>
              </>
            )}
          </div>
          <Controller
            name={`contract_sub_periods_attributes.${subPeriodIndex}.profile_hedge_attributes.profile_hedge_caps_attributes.${index}.cap`}
            control={control}
            rules={{
              ...fieldRules(
                `contract_sub_periods_attributes.${subPeriodIndex}.profile_hedge_attributes.profile_hedge_caps_attributes.${index}.cap`,
              ),
            }}
            render={({ field }) => (
              <Input
                {...field}
                className={styles['input']}
                size="lg"
                type="number"
                min={1}
                suffix="kWh"
                data-cy={`contract_sub_periods_attributes.${subPeriodIndex}.profile_hedge_attributes.profile_hedge_caps_attributes.${index}.cap`}
                value={(field.value && Number(field.value)) || undefined}
                readOnly={readonly}
                full
              />
            )}
          />
        </div>
      ))}
    </div>
  );
};

function getMonthlyPeriods(start: number, end: number, cap?: number) {
  const periods: { start_date: Date; end_date: Date; id: undefined; cap?: number }[] = [];
  let current = dayjs(start);
  const last = dayjs(end);
  let nextIsLast = false;

  while (!nextIsLast) {
    const potentialNext = current.endOf('month');
    const next = potentialNext.isBefore(last) ? potentialNext : last;
    periods.push({ start_date: current.toDate(), end_date: next.toDate(), id: undefined, cap });

    current = next.add(1, 'day');
    nextIsLast = next === last;
  }

  return periods;
}

function getYearlyPeriods(start: number, end: number, cap?: number) {
  const periods: { start_date: Date; end_date: Date; id: undefined; cap?: number }[] = [];
  let current = dayjs(start);
  const last = dayjs(end);
  let nextIsLast = false;

  while (!nextIsLast) {
    const potentialNext = current.add(1, 'year').subtract(1, 'day');
    const next = potentialNext.isBefore(last) ? potentialNext : last;
    periods.push({ start_date: current.toDate(), end_date: next.toDate(), id: undefined, cap });

    current = next.add(1, 'day');
    nextIsLast = next === last;
  }

  return periods;
}
