import React from 'react';
import { Input } from '@GDM/forms';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { getSymbol } from '@utils/currency/getSymbol';
import { Controller } from 'react-hook-form';

export const Price: React.FC<{ index: number }> = ({ index }) => {
  const { control, readonly, watch } = useContractForm();
  const { fieldConfig } = useContractFields();
  const config = fieldConfig(`hedge_blocks_attributes.${index}.price`);

  const currency = watch('currency');

  return (
    <Controller
      name={`hedge_blocks_attributes.${index}.price`}
      control={control}
      rules={config?.rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          data-cy={`hedge-display-price-${index}`}
          label="common.price"
          size="lg"
          step="0.01"
          type="number"
          value={field.value ?? ''}
          hasError={!!fieldState.error}
          suffix={config?.unit || `${getSymbol(currency)}/MWh`}
          autoComplete="off"
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
