import React, { useEffect, useMemo } from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useInstallationsContext } from '@context/installations.context';
import { sectionStyles } from '@GDM/forms';
import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import MarketPlayer from '@utils/types/market_player';
import classNames from 'classnames';
import { Control } from 'react-hook-form';
import { useContractFields, useContractForm, useContractProgress } from '../../hooks';
import { useMarketPlayers } from '../../hooks/requests/useMarketPlayers';
import { useValidateUniquenessRequest } from '../../hooks/useValidateUniquenessRequest';
import { PMax } from '../Cap/PMax';
import { CardI } from './CardI';
import { ContractNumber } from './ContractNumber';
import { ContractTypePicker } from './ContractTypePicker';
import { CurrencyPicker } from './CurrencyPicker';
import { EndDatePicker } from './EndDatePicker';
import { ErpCode } from './ErpCode';
import { ExternalRef } from './ExternalRef';
import { useInstallationCountry } from './hooks/useInstallationCountry';
import { useBuyersOptions } from './hooks/useMarketPlayersOptions';
import { InstallationPicker } from './InstallationPicker';
import { OwnerErpCode } from './OwnerErpCode';
import { SellerPicker } from './SellerPicker';
import { SignatureDatePicker } from './SignatureDatePicker';
import { StartDatePicker } from './StartDatePicker';

export const GeneralInfo = () => {
  const { sectionFields: allAllowedFields, hasField: shouldDisplay } = useContractFields();
  const { marketPlayer, intermediaries, watch, control, readonly, setValue } = useContractForm();

  const sectionFields = useMemo(() => {
    const fieldNamesToRemove: ContractFormField['name'][] = [
      ...(!intermediaries?.data?.length ? (['seller_id'] as ContractFormField['name'][]) : []),
      ...(marketPlayer ? (['buyer_id'] as ContractFormField['name'][]) : []),
    ];

    return [...allAllowedFields.filter((f) => !fieldNamesToRemove.includes(f.name))];
  }, [allAllowedFields, intermediaries?.data?.length, marketPlayer]);

  useContractProgress(sectionFields);

  const hasField = (fieldName: ContractFormField['name']): boolean => {
    const field = sectionFields?.find(({ name }) => name === fieldName);
    if (!field) return false;

    return shouldDisplay(field);
  };

  const { validate } = useValidateUniquenessRequest();

  // PART OF THE REFACTORING EFFORT
  // This effect gathers reactive form-wide side effects
  // The idea is keep inputs simple and to handle those effects
  // Here the side effect is coming from the now deleted BuyerPicker (RIP)
  //
  // Goal: Every non-generic input (InstallationPicker I'm looking at you) should be dismantled
  // And replaced by a Controlled Input and if needed its side effeect logic put in this useEffect
  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name === 'buyer_id') {
        setValue('credential_id', null);
        validate();
      }
    });

    return () => subscription.unsubscribe();
  }, [setValue, watch, validate]);

  const installationUuid = watch('installation_uuid');
  const { installations } = useInstallationsContext();
  const installationCountry = useInstallationCountry(installations, installationUuid);

  const { data: marketPlayers, isPending, isError } = useMarketPlayers();
  const buyersOptions = useBuyersOptions(marketPlayers, installationCountry);

  const marketPlayersAreNotAvailable = !installationUuid || isError || isPending;
  const contractType = watch('type');

  return (
    <div className={sectionStyles.container} data-cy="general-information">
      <div className="p-3">
        <div
          className={classNames(sectionStyles.row, {
            [sectionStyles['two-per-row']]: hasField('buyer_id') || hasField('seller_id'),
          })}
        >
          <ContractNumber />
          <InstallationPicker />
          <ControlledSelect
            name="buyer_id"
            control={control}
            options={buyersOptions}
            rules={{ required: true }}
            isLoading={isPending}
            label="sales_management.buyer"
            size="lg"
            isDisabled={marketPlayersAreNotAvailable}
            noOptionsMessage="sales_management.no_buyer_for_country"
            classNamePrefix="select-buyer"
            readOnly={readonly}
            isSearchable
          />
          {hasField('seller_id') && <SellerPicker />}
          <CurrencyPicker />
          {contractType === 'ContractCppa' && (
            <CppaMarketPlayers
              control={control}
              isDisabled={marketPlayersAreNotAvailable}
              readonly={readonly}
              options={buyersOptions}
              isLoading={isPending}
            />
          )}
        </div>

        <div className={sectionStyles.row}>
          <StartDatePicker />
          <EndDatePicker />
        </div>

        <div className={sectionStyles.row}>
          {hasField('erp_code') && <ErpCode />}
          {hasField('card_i') && <CardI />}
          {hasField('_non_contract_props.installation.owner_erp_code') && <OwnerErpCode />}
          {hasField('_non_contract_props.installation.external_ref') && <ExternalRef />}
          {hasField('signature_date') && <SignatureDatePicker />}
          {hasField('p_max') && <PMax />}
        </div>
      </div>

      <div className={sectionStyles['section-footer']}>
        <div className="pt-3 pr-3 pl-3">
          <ContractTypePicker />
        </div>
      </div>
    </div>
  );
};

function CppaMarketPlayers({
  control,
  isDisabled,
  readonly,
  isLoading,
  options,
}: {
  options: (Option<string> & { type: MarketPlayer['player_type'] })[];
  control: Control<ContractForm>;
  isDisabled: boolean;
  readonly: boolean | undefined;
  isLoading: boolean;
}) {
  const balancingResponsiblePartyOptions = useMemo(() => {
    return options.filter(({ type }) => type === 'offtaker');
  }, [options]);

  return (
    <>
      <ControlledSelect
        name="additional_buyer_id"
        control={control}
        options={options}
        isLoading={isLoading}
        label="contracts.final_buyer"
        size="lg"
        isDisabled={isDisabled}
        classNamePrefix="select-buyer"
        readOnly={readonly}
        isSearchable
      />
      <ControlledSelect
        name="balancing_responsible_party_id"
        control={control}
        options={balancingResponsiblePartyOptions}
        isLoading={isLoading}
        label="contracts.brp"
        size="lg"
        isDisabled={isDisabled}
        classNamePrefix="select-buyer"
        readOnly={readonly}
        isSearchable
        tooltip="contracts.brp_info"
      />
    </>
  );
}
