import React from 'react';
import { useUser } from '@context/User.context';
import { Badge } from '@GDM/Badge';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { endOfPreviousMonth, startOfNextMonth } from '@utils/date';
import { formatNumber, formatSystemDate } from '@utils/formatters';
import classNames from 'classnames';
import { useContractForm, useHedgeBlocks } from '../../../hooks';
import { makeHedgeBlock } from '../helpers';
import styles from './hedge-block-header.module.scss';

export const HedgeBlockHeader: React.FC<{
  index: number;
  blockId: string;
}> = ({ index, blockId }) => {
  const { getValues, trigger, watch, getFieldState, readonly } = useContractForm();
  const { isCollapsed, toggleBlock, hedgeBlocks } = useHedgeBlocks();
  const { t } = useTranslation();
  const { locale } = useUser();

  const addBlockBefore = (): void => {
    const [start_date, end_date] = getValues(['start_date', 'end_date']);

    const newBlock = makeHedgeBlock({
      start_date: formatSystemDate(startOfNextMonth(start_date)),
      end_date: formatSystemDate(endOfPreviousMonth(end_date)),
    });

    if (newBlock) hedgeBlocks?.insert(index, newBlock);
    trigger('hedge_blocks_attributes');
  };

  const onRemoveBlock = (): void => {
    hedgeBlocks?.remove(index);
    trigger('hedge_blocks_attributes');
  };

  const block = watch(`hedge_blocks_attributes.${index}`);
  const currency = watch('currency');
  const price = Number(block.price ?? '-');
  const averagePower = Number(block.average_power ?? '-');
  const title = `${t('sales_management.block')} ${index + 1}`;
  const startDate = block.dates?.start_date;
  const endDate = block.dates?.end_date;
  const formattedDates = `${formatSystemDate(startDate)} / ${formatSystemDate(endDate)}`;
  const marketFutureName = block.market_future?.name;
  const displayPrice = formatNumber(price, locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? '-';
  const displayPower = formatNumber(averagePower, locale, { maximumFractionDigits: 2 }) ?? '-';
  const blockError = getFieldState(`hedge_blocks_attributes.${index}`)?.error;

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <span onClick={() => toggleBlock?.(blockId)}>{t(title)}</span>
        {isCollapsed?.(blockId) && (
          <div className={classNames(styles['collapsed-dates'], blockError && styles['has-error'])}>
            <span className={styles['collapsed-prefix']}>&ndash;&nbsp;</span>
            <Badge variant={blockError ? 'danger' : 'primary-1'} label={marketFutureName} />{' '}
            <span>{formattedDates}</span>
            <span className="pl-2">
              (<b>{displayPrice}</b> {getSymbol(currency)}/MWh / <b>{displayPower}</b> MW)
            </span>
            {blockError && (
              <Icon
                name="AlertCircle"
                title="common.contains_errors"
                size={14}
                className={styles['collapsed-warning']}
              />
            )}
          </div>
        )}
      </div>
      {!readonly && (
        <>
          <Button icon="Plus" title="sales_management.add_block_before" onClick={addBlockBefore} floating />
          <Button
            icon="Trash2"
            variant="secondary"
            title="common.delete"
            onClick={onRemoveBlock}
            className="ml-1"
            floating
          />
        </>
      )}
    </div>
  );
};
