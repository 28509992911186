import { ContractForm } from '@utils/types/contract';
import { ValidateResult } from 'react-hook-form';

export function noSpacesIfHasCegedimActive(
  contractNb: ContractForm['contract_nb'],
  invoicingPlatform: ContractForm['invoicing_platform'],
): ValidateResult {
  const isCegedimActive = invoicingPlatform === 'cegedim';
  if (!isCegedimActive || !contractNb) return true;

  return (
    (isCegedimActive && /^[A-Za-z0-9-_]+$/.test(contractNb)) || 'sales_management.contract_number_cannot_contain_spaces'
  );
}
