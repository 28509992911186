import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';

/**
 * All the fields that are in the `general-information` section.
 * They don't depend on the contract type.
 * */
export const COMMON_FIELDS: ContractFormField[] = [
  {
    name: 'contract_nb',
    required: false,
    rules: { pattern: { value: /^[A-Za-z0-9-_ ]+$/, message: 'errors.only_letters_numbers' } },
    info: { title: 'sales_management.contract_nb', text: 'contracts.info.contract_nb' },
  },
  { name: 'buyer_id', required: true, info: { title: 'sales_management.buyer', text: 'contracts.info.buyer' } },
  { name: 'seller_id', required: true, info: { title: 'sales_management.seller', text: 'contracts.info.seller' } },
  { name: 'end_date', required: true },
  { name: 'installation_uuid', required: true },
  { name: 'start_date', required: true },
  {
    name: 'type',
    required: true,
    info: { title: 'sales_management.contract_type', text: 'contracts.info.contract_type' },
  },
  { name: 'sent_to_remit', required: false },
  { name: 'erp_code', required: false, configurable: true },
  { name: 'card_i', required: false, configurable: true },
  {
    name: '_non_contract_props.installation.owner_erp_code',
    configurable: true,
    info: {
      title: 'sales_management.configurable_fields._non_contract_props.installation.owner_erp_code',
      text: 'sales_management.configurable_fields._non_contract_props.installation.owner_erp_code_info',
    },
  },
  {
    name: '_non_contract_props.installation.external_ref',
    configurable: true,
    info: {
      title: 'sales_management.configurable_fields._non_contract_props.installation.external_ref',
      text: 'sales_management.configurable_fields._non_contract_props.installation.external_ref_info',
    },
  },
  { name: 'signature_date', required: false, configurable: true },
  { name: 'currency', required: true },
  { name: 'energy' },
];
