import React from 'react';
import { Select } from '@GDM/forms';
import { useRequest } from '@hooks/useRequest';
import { v2_edf_agencies_path } from '@utils/routes';
import { Option } from '@utils/types/common-types';
import { EdfAgency } from '@utils/types/edf_agency';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const EdfAgencyIdPicker: React.FC = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();
  const { data, loading, error } = useRequest<EdfAgency[]>(v2_edf_agencies_path(), 'GET');

  const options: Option<string>[] =
    data?.map((agency) => ({
      label: agency.name,
      value: agency.id?.toString(),
    })) || [];

  return (
    <Controller
      name="edf_agency_id"
      control={control}
      rules={fieldRules('edf_agency_id')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.edf_agency_id"
          classNamePrefix="edf-agency-picker"
          menuPlacement="top"
          size="lg"
          options={options}
          isLoading={loading}
          selectedOption={field.value?.toString()}
          onChange={(option) => field.onChange(option?.value ? parseInt(option.value) : null)}
          readOnly={readonly}
          hasError={!!fieldState.error}
          isDisabled={!!error}
          isClearable
          isSearchable
        />
      )}
    />
  );
};
