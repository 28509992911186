import React, { useEffect } from 'react';
import ControlledInput from '@components/FormInputs/ControlledInput';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { sectionStyles } from '@GDM/forms';
import { getSymbol } from '@utils/currency/getSymbol';
import { Option } from '@utils/types/common-types';
import {
  ContractForm,
  ContractSubPeriod,
  HedgeProfilePeriod,
  PriceAboveCapType,
  VolumeType,
} from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import classNames from 'classnames';
import { Control } from 'react-hook-form';
import { BirthdayDatePicker } from '../../../Pricing/BirthdayDatePicker';
import { IndexFormulaPicker } from '../../../Pricing/IndexFormulaPicker';
import { RateChangePicker } from '../../../Pricing/RateChangePicker';
import { AggregFees } from '../AggregFees';
import { DatesPicker } from '../DatesPicker';
import { HedgeProfileCaps } from '../HedgeProfileCaps';
import { NegativePriceRulePicker } from '../NegativePriceRulePicker';
import { PriceRulePicker } from '../PriceRulePicker/PriceRulePicker';
import { CONTRACT_OPTIONS_MAP } from '../PriceTypePicker/options';
import styles from '../sub-period.module.scss';
import { SubPeriodTemplateProps } from '../types';

const VOLUME_TYPE_OPTIONS: Option<VolumeType>[] = [
  { value: 'full', label: 'sales_management.whole' },
  { value: 'hedge_profile', label: 'sales_management.hedge_profile_volume' },
];

const PRICE_ABOVE_CAP_OPTIONS: Option<PriceAboveCapType>[] = [
  { value: 'capture_price', label: 'pricing.maps.capture_price' },
  { value: 'baseload', label: 'pricing.baseload_price' },
  { value: 'positive_capture_price', label: 'pricing.maps.positive_capture_price' },
];

export const SoaSubPeriod = ({
  index,
  priceType,
  volumeType,
  control,
  readonly,
  rateChange,
  priceRule,
  currency,
  installationCountry,
  somePriceRulesAreAvailable,
}: SubPeriodTemplateProps & {
  control: Control<ContractForm>;
  readonly?: boolean;
  installationCountry: CountryCode;
  rateChange: ContractForm['rate_change'];
  priceRule: ContractSubPeriod['price_rule'];
  volumeType?: ContractSubPeriod['volume_type'];
  priceType?: ContractSubPeriod['price_type'];
  somePriceRulesAreAvailable: boolean;
}) => {
  const priceRuleBasedPriceTypes: ContractSubPeriod['price_type'][] = ['spot', 'imbalance_pos'];
  const subPeriodIsPriceRuleBased = priceRuleBasedPriceTypes.includes(priceType);
  const canHaveHedgeProfile = priceType === 'fixed';
  const showHedgeProfile = canHaveHedgeProfile && volumeType === 'hedge_profile';

  return (
    <>
      <div className={styles.section}>
        <div className={styles['row-3']}>
          <DatesPicker index={index} />
          <ControlledSelect
            control={control}
            options={CONTRACT_OPTIONS_MAP.ContractSoa || []}
            name={`contract_sub_periods_attributes.${index}.price_type`}
            label="common.price_type"
            size="lg"
            menuPlacement="top"
            readOnly={readonly}
          />
          {canHaveHedgeProfile && (
            <ControlledSelect
              shouldUnregister
              label="sales_management.volume_type"
              size="lg"
              name={`contract_sub_periods_attributes.${index}.volume_type`}
              control={control}
              options={VOLUME_TYPE_OPTIONS}
              readOnly={readonly}
              defaultValue="full"
            />
          )}
        </div>
      </div>

      {showHedgeProfile && (
        <>
          <div className={styles.section}>
            <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
              <ControlledInput
                control={control}
                name={`contract_sub_periods_attributes.${index}.display_price`}
                label="sales_management.hedge_profile_below_price"
                labelStyle={{ fontWeight: 'normal' }}
                size="lg"
                type="number"
                autoComplete="off"
                readOnly={readonly}
                full
                disabled={Boolean(priceRule)}
                suffix={`${getSymbol(currency)}/MWh`}
              />
              <ControlledSelect
                control={control}
                name={`contract_sub_periods_attributes.${index}.profile_hedge_attributes.price_above_cap_type`}
                label="sales_management.hedge_profile_above_price"
                labelStyle={{ fontWeight: 'normal' }}
                size="lg"
                options={PRICE_ABOVE_CAP_OPTIONS}
                readOnly={readonly}
              />
            </div>
            <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
              <AggregFees index={index} />
            </div>
          </div>
          <HedgeProfileSection index={index} control={control} readonly={readonly} />
        </>
      )}

      {!showHedgeProfile && (
        <div className={styles.section}>
          <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
            <ControlledInput
              control={control}
              name={`contract_sub_periods_attributes.${index}.display_price`}
              label="common.price"
              size="lg"
              type="number"
              autoComplete="off"
              readOnly={readonly}
              full
              disabled={subPeriodIsPriceRuleBased}
              suffix={`${getSymbol(currency)}/MWh`}
            />
          </div>
          <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
            <AggregFees index={index} />
          </div>
          <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
            <div className="wider">
              <NegativePriceRulePicker index={index} />
            </div>
          </div>
          {somePriceRulesAreAvailable && (
            <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
              <PriceRulePicker index={index} />
            </div>
          )}
          {(priceType === 'indexed' || installationCountry === 'UK') && (
            <div className={styles['row-3']}>
              <IndexFormulaPicker />
              <RateChangePicker />
              {rateChange === 'commissioning_date' && <BirthdayDatePicker />}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const HEDGE_PROFILE_PERIOD_OPTIONS: Option<HedgeProfilePeriod>[] = [
  { value: 'monthly', label: 'subscriptions.frequencies.monthly' },
  { value: 'yearly', label: 'subscriptions.frequencies.yearly' },
];

function HedgeProfileSection({
  index,
  control,
  readonly,
}: {
  index: number;
  control: Control<ContractForm>;
  readonly?: boolean;
}) {
  useEffect(() => {
    return () => control.unregister(`contract_sub_periods_attributes.${index}.profile_hedge_attributes`);
  }, [control, index]);

  return (
    <div className={styles.section}>
      <div className={styles['row-3']}>
        <ControlledSelect
          shouldUnregister
          label="sales_management.profile"
          size="lg"
          name={`contract_sub_periods_attributes.${index}.profile_hedge_attributes.period`}
          control={control}
          options={HEDGE_PROFILE_PERIOD_OPTIONS}
          readOnly={readonly}
          defaultValue="monthly"
          className={styles['columns-1-3']}
        />
        <HedgeProfileCaps className={styles['columns-3-3']} subPeriodIndex={index} />
      </div>
    </div>
  );
}
