import React, { useEffect, useMemo } from 'react';
import { useCredentialsContext } from '@context/credentials.context';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const CredentialIdPicker: React.FC = () => {
  const { control, readonly, setValue, watch } = useContractForm();
  const { fieldRules, isDisabled } = useContractFields();
  const { cegedimCredentials, edfOaCredentials, isLoading, error } = useCredentialsContext();

  const [invoicingPlatform, contractType, calcBase] = watch(['invoicing_platform', 'type', 'calc_base']);
  const credentials = invoicingPlatform === 'edf_oa' ? edfOaCredentials : cegedimCredentials;

  const options: Option<string>[] = useMemo(() => {
    return credentials.map((c) => ({
      label: c.username,
      value: c.id,
    }));
  }, [credentials]);

  // Side-effect: after loading the list of credentials, if there's only one, set it.
  useEffect(() => {
    if (!watch('credential_id') && options?.length === 1) {
      setValue('credential_id', options[0].value);
    }
  }, [options, setValue, watch]);

  const disabled = isDisabled('credential_id') || !!error || options.length === 0;

  let info: string | null = null;
  let label = 'sales_management.cegedim_credential_label';

  // Special exceptional condition.
  // Please avoid referring to contract types inside components.
  // Any contract type-specific logic should be handled in the config files.
  // We have this here because the label and tooltip for this field change based on the contract type.
  if (contractType === 'ContractOa' && calcBase === 'index') {
    info = 'sales_management.edf_oa_credential_message';
    label = 'sales_management.edf_oa_credential_label';
  }

  return (
    <Controller
      name="credential_id"
      control={control}
      rules={fieldRules('credential_id')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label={label}
          classNamePrefix="credential-picker"
          menuPlacement="top"
          size="lg"
          options={options}
          isLoading={isLoading}
          selectedOption={field.value}
          onChange={(option) => field.onChange(option?.value)}
          readOnly={readonly}
          hasError={!!fieldState.error}
          isDisabled={disabled}
          tooltip={info}
          isSearchable
        />
      )}
    />
  );
};
