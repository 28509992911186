import pick from 'lodash/pick';
import { InstallationForm } from '../installation.types';

export const formatMeterBody = (values: InstallationForm) => {
  const clonedValues: Partial<InstallationForm> = { ...values };

  const meterBody = {
    ...pick(clonedValues, [
      'prm',
      'numero',
      'key',
      'mandate_uuid',
      'aiguillage',
      'code',
      'ip',
      'tcp_port',
      'boitier_ip',
      'serial_number',
      'model',
      'typecompteur',
      'meter_pass_address',
      'producteur',
    ]),
    installation_uuid: clonedValues.uuid,
  };

  return meterBody;
};
