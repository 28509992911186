import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Select } from '@GDM/forms';
import { Text } from '@GDM/Text';
import { Option } from '@utils/types/common-types';
import compact from 'lodash/compact';
import { ExportFormData, MeasureType } from 'pages/Export/export.types';
import { Controller, useFormContext } from 'react-hook-form';

export const MeasureTypeSelect: React.FC = () => {
  const user = useUser();
  const isForecast = user.roles?.includes('viewer_forecast');
  const { control, watch, getValues } = useFormContext<ExportFormData>();
  const sample = watch('sample');

  const measuresOptions: Option<MeasureType>[] = compact([
    { label: 'monitoring.export.active', value: 'active' },
    { label: 'monitoring.export.corrected', value: 'corrected' },
    { label: 'monitoring.export.reactive_positive', value: 'reactive_positive' },
    { label: 'monitoring.export.reactive_negative', value: 'reactive_negative' },
    { label: 'monitoring.export.tension', value: 'tension' },
    { label: 'monitoring.export.consumption', value: 'consumption' },
    isForecast && { label: 'monitoring.export.forecast', value: 'forecast' },
    user.email == 'streemdemo@streem.eu' && isForecast && { label: 'monitoring.export.dispatch', value: 'dispatch' }, // TODO allow this option for all users once prod team approved
    { label: 'monitoring.export.spot_epex', value: 'spot_epex' },
    { label: 'monitoring.export.spot_nordpool', value: 'spot_nordpool' },
    { label: 'monitoring.export.spot_imrp', value: 'spot_imrp' },
    { label: 'monitoring.export.positive_imbalance', value: 'positive_imbalance' },
    { label: 'monitoring.export.negative_imbalance', value: 'negative_imbalance' },
  ]);

  const validate = useMemo(
    () => ({
      priceCompatible: (values: MeasureType[]) => {
        const sample = getValues('sample');

        const isSpot = values.includes('spot');
        const isImbalance = values.includes('positive_imbalance') || values.includes('negative_imbalance');
        const isMixed = isSpot && isImbalance;

        if ((isSpot || isMixed) && sample === 'daily') {
          return isMixed ? 'monitoring.export.errors.mixed_price_error' : 'monitoring.export.errors.spot_price_error';
        }

        if (isImbalance && (sample === 'hourly' || sample === 'daily')) {
          return 'monitoring.export.errors.imbalance_price_error';
        }

        return true;
      },
    }),
    [getValues],
  );

  return (
    <Controller
      name="measureTypes"
      control={control}
      rules={{ required: true, validate }}
      render={({ field, fieldState }) => (
        <>
          <Select
            id="measureTypes"
            options={measuresOptions}
            label="monitoring.export.mesure_type"
            isMulti
            selectedOptions={field.value}
            onChange={(options) => field.onChange(options?.map((option) => option.value))}
            hasError={fieldState.error?.type === 'priceCompatible'}
            isOptionDisabled={(option) => {
              if (
                (sample === 'daily' &&
                  ['spot_epex', 'spot_nordpool', 'spot_imrp', 'positive_imbalance', 'negative_imbalance'].includes(
                    option.value,
                  )) ||
                (sample === 'hourly' && ['positive_imbalance', 'negative_imbalance'].includes(option.value))
              ) {
                return true;
              }

              return false;
            }}
            classNamePrefix="export-measure-types"
          />
          {fieldState.error?.type === 'priceCompatible' && (
            <Text type="danger" text={fieldState.error?.message || 'errors.unknown_error'} />
          )}
        </>
      )}
    />
  );
};
