import React, { useMemo } from 'react';
import { Select } from '@GDM/forms';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { Option } from '@utils/types/common-types';
import { ContractType, PriceType } from '@utils/types/contract';
import { Controller } from 'react-hook-form';
import { CONTRACT_OPTIONS_MAP } from './options';

export const SwapPriceTypePicker: React.FC<{ index: number }> = ({ index }) => {
  const { control, watch, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  const [contractType, subPeriodPhaseType] = watch(['type', `contract_sub_periods_attributes.${index}.phase_type`]);

  const options: Option<PriceType>[] = useMemo(
    () => CONTRACT_OPTIONS_MAP[contractType as ContractType] ?? [],
    [contractType],
  );

  const isOptionDisabled = (option: Option<PriceType>) => {
    return subPeriodPhaseType === 'testing' && option.value === 'm0';
  };

  return (
    <Controller
      name={`contract_sub_periods_attributes.${index}.extra_params.swap_price_type`}
      control={control}
      rules={fieldRules(`contract_sub_periods_attributes.${index}.extra_params.swap_price_type`)}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.swap_price_type"
          size="lg"
          menuPlacement="top"
          options={options}
          selectedOption={field.value}
          onChange={(option) => field.onChange(option?.value)}
          isOptionDisabled={isOptionDisabled}
          classNamePrefix={`select-swap-price-type-${index}`}
          readOnly={readonly}
          hasError={!!fieldState.error}
        />
      )}
    />
  );
};
