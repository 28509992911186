import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';

export const OAFields: ContractFormField[] = [
  {
    name: 'dispositif',
    required: true,
    info: { title: 'sales_management.dispositif', text: 'contracts.info.dispositif' },
  },
  {
    name: 'sub_type',
    required: true,
    info: { title: 'sales_management.sub_type_select', text: 'contracts.info.sub_type' },
  },
  { name: 'calc_base', required: true },
  {
    name: 'cut_time',
    requiredForFieldValues: { calc_base: 'load_curve' },
    onlyForFieldValues: [{ field: 'calc_base', value: 'load_curve' }],
  },
  {
    name: 'invoicing_period',
    requiredForFieldValues: { calc_base: 'index' },
    info: { title: 'common.index_type', text: 'contracts.info.invoicing_period' },
    onlyForFieldValues: [{ field: 'calc_base', value: 'index' }],
  },
  {
    name: 'rate_change',
    required: true,
    info: { title: 'sales_management.rate_change', text: 'contracts.info.rate_change' },
  },
  { name: 'birthday_date', required: true },
  { name: 'index_formula', required: true },
  { name: 'producer_price_insee_index_ref', required: false },
  { name: 'labour_cost_insee_index_ref', required: false },
  {
    name: 'annual_cap',
    requiredForFieldValues: {
      sub_type: [
        'Photo_2006_V1',
        'Photo_2006_V3',
        'Photo_2010_V1',
        'Photo_2011M_V1',
        'Photo_2011M_V2',
        'Photo_2011S_V1',
        'Photo_2011_V1',
        'FS11',
        'FS13',
        'FV11_SF3_V2',
        'FV11_SF4_V2',
        'FV11_SF5_V1',
        'FV11_SF6',
        'FV11_SF6_V1',
        'FV11_SF6_V2',
        'FV11_SF7_V2',
        'FV13_V1',
        'FV14_V1',
        'FV16BOA_V01',
        'FV16BOA_V02',
      ],
    },
  },
  { name: 'p_max', required: true, info: { title: 'sales_management.p_max', text: 'contracts.info.p_max' } },
  {
    name: 'contract_sub_periods_attributes.0.display_price',
    required: true,
    info: { title: 'sales_management.ref_price', text: 'contracts.info.ref_price' },
  },
  { name: 'invoicing_platform', required: false, onlyForFieldValues: [{ field: 'calc_base', value: 'load_curve' }] },
  {
    name: 'credential_id',
    required: false,
    onlyForFieldValues: [{ field: 'calc_base', present: true }],
    disabledForFieldValues: [{ field: 'invoicing_platform', excludeValue: 'cegedim' }],
  },
  { name: 'edf_agency_id', required: false, onlyForFieldValues: [{ field: 'calc_base', value: 'load_curve' }] },
  {
    name: '_non_contract_props.installation.siret',
    onlyForCountries: ['FR'],
    required: false,
    info: { title: 'sales_management.installation_siret', text: 'sales_management.cegedim_siret_info' },
    onlyForFieldValues: [{ field: 'calc_base', value: 'load_curve' }],
    disabledForFieldValues: [{ field: 'invoicing_platform', excludeValue: 'cegedim' }],
  },
  { name: 'external_pdf_link', required: false, permission: 'display:non_engie' },
  { name: 'sent_to_remit' },
  {
    name: 'power_augmentation',
    info: { title: 'sales_management.power_augmentation_title', text: 'sales_management.power_augmentation_text' },
    onlyForFieldValues: [
      { field: 'energy', value: 'solar' },
      { field: 'calc_base', value: 'index' },
    ],
  },
  {
    name: 'power_augmentation_percentage',
    requiredForFieldValues: { power_augmentation: true },
    rules: { min: 0, max: 100 },
    onlyForFieldValues: [
      { field: 'power_augmentation', value: true },
      { field: 'energy', value: 'solar' },
      { field: 'calc_base', value: 'index' },
    ],
  },
  {
    name: 'power_augmentation_start_date',
    requiredForFieldValues: { power_augmentation: true },
    onlyForFieldValues: [
      { field: 'power_augmentation', value: true },
      { field: 'energy', value: 'solar' },
      { field: 'calc_base', value: 'index' },
    ],
  },
  { name: 'above_cap_price' },
  { name: 'is_indexed' },
];
