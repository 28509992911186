import React from 'react';
import { Select } from '@GDM/forms';
import { useContractForm, useContractFields } from '@pages/Contracts/Contract/Form/hooks';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { VolumeType } from '@utils/types/contract/sub-period';
import classNames from 'classnames';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import styles from '../sub-period.module.scss';

const options: Option<VolumeType>[] = [
  { value: 'full', label: 'sales_management.whole' },
  { value: 'hedge_profile', label: 'sales_management.hedge_profile_volume' },
];

export const VolumeTypePicker: React.FC<{ index: number }> = ({ index }) => {
  const { control, readonly, setValue } = useContractForm();
  const { fieldRules } = useContractFields();

  const fieldName = `contract_sub_periods_attributes.${index}.volume_type` as const;

  const onChange = (
    value: VolumeType | undefined,
    field: ControllerRenderProps<ContractForm, `contract_sub_periods_attributes.${number}.volume_type`>,
  ): void => {
    field.onChange(value);

    setValue(fieldName, value);
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={fieldRules(fieldName)}
      render={({ field, fieldState }) => (
        <div className={classNames(styles['select-container'], 'wider')}>
          <Select
            {...field}
            label="sales_management.volume_type"
            size="lg"
            options={options}
            selectedOption={field.value || 'full'}
            onChange={(option) => onChange(option?.value, field)}
            hasError={!!fieldState.error}
            readOnly={readonly}
          />
        </div>
      )}
    />
  );
};
