import React from 'react';
import { Button } from '@GDM/Button';
import { endOfPreviousMonth, startOfNextMonth } from '@utils/date';
import { formatSystemDate } from '@utils/formatters';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useContractForm, useHedgeBlocks } from '../../../hooks';
import { makeHedgeBlock } from '../helpers';
import styles from './hedge-blocks-actions.module.scss';

export const HedgeBlocksActions: React.FC = () => {
  const { watch, trigger } = useContractForm();
  const { hedgeBlocks } = useHedgeBlocks();

  const [start_date, end_date, hedge_blocks_attributes] = watch(['start_date', 'end_date', 'hedge_blocks_attributes']);

  const addHedgeBlock = async (): Promise<void> => {
    let start = formatSystemDate(startOfNextMonth(start_date));
    const end = formatSystemDate(endOfPreviousMonth(end_date));

    if (hedge_blocks_attributes?.length) {
      const lastBlockEndDate = dayjs(hedge_blocks_attributes.at(-1)?.dates?.end_date).tz('UTC').endOf('day').toDate();

      const lastPossibleBlockEndDate = endOfPreviousMonth(end_date);
      const nextPossibleBlockStartDate = startOfNextMonth(hedge_blocks_attributes.at(-1)?.dates?.end_date);

      if (lastBlockEndDate < lastPossibleBlockEndDate) start = formatSystemDate(nextPossibleBlockStartDate);
    }

    const block = makeHedgeBlock({
      start_date: start,
      end_date: end,
    });

    if (block) hedgeBlocks?.append(block);

    // This makes sure the validation is triggered in the next loop, which is needed to make
    // sure the new hedge dates are validated.
    if (await trigger('hedge_blocks_attributes')) trigger('hedge_blocks_attributes');
  };

  return (
    <div className={classNames('p-3', styles.wrapper)}>
      <Button
        size="sm"
        variant="primary-2"
        text="sales_management.add_hedge_block"
        onClick={addHedgeBlock}
        data-cy="add-hedge-block"
      />
    </div>
  );
};
