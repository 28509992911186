import { useCallback, useMemo } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { useUser } from '@context/User.context';
import { useStep } from '@GDM/forms';
import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';
import { getSymbol } from '@utils/currency/getSymbol';
import { ContractForm, ContractType } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import { ControllerRenderProps, FieldPath, FieldPathValue, UseControllerProps } from 'react-hook-form';
import { COMMON_FIELDS, CONTRACT_TYPE_FIELDS } from '../builder/config';
import { getFieldSection } from '../builder/helpers';
import { getFieldIndex } from '../helpers/getFieldIndex';
import { valueMatches } from '../helpers/valueMatches';
import { useConfigurableFields } from './useConfigurableFields';
import { useContractForm } from './useContractForm';

type OnChangeFunctionValue<T extends FieldPath<ContractForm>> = FieldPathValue<ContractForm, T>;

type OnChangeFunction = <T extends FieldPath<ContractForm>>(
  field: ControllerRenderProps<ContractForm, T>,
  value: OnChangeFunctionValue<T> | null | undefined,
) => Promise<void>;

export const useContractFields = (): {
  /** All the fields that are potentially available for a given contract type. */
  allFields: ContractFormField[];
  /** All the fields that are potentially available for a given form section. */
  sectionFields: ContractFormField[];
  /**
   * Checks if a single field or list of fields are to be displayed or not
   * (config-based rules may prevent certain fields from appearing). */
  hasFields: (fields: Array<ContractFormField['name']> | ContractFormField['name'], index?: number) => boolean;
  /**
   * Checks if a specific field is available. The difference with `hasFields`
   * is that you can pass a `FormField` object or a `FormField` name.
   * @param field a string or a `FormField` object.
   */
  hasField: (field: ContractFormField['name'] | ContractFormField, index?: number) => boolean;
  isRequired: (field: ContractFormField['name'] | ContractFormField) => boolean;
  isDisabled: (field: ContractFormField['name'] | ContractFormField) => boolean;
  fieldRules: (field: ContractFormField['name']) => UseControllerProps['rules'];
  /**
   * Checks the config to find any other fields that might depend on the one given.
   * This can include the `onlyForFieldValue` and `requiredForFieldValue` fields.
   */
  dependantFields: (field: ContractFormField['name']) => ContractFormField['name'][];
  fieldConfig: (field: ContractFormField['name']) => {
    defaultValue?: FieldPathValue<ContractForm, typeof field> | null;
    rules?: UseControllerProps['rules'];
    unit?: string;
  } | null;
  /**
   * Changes the value of a field and triggers validation for it and any fields that depend on it.
   */
  onChangeField: OnChangeFunction;
} => {
  const { watch, trigger, getValues } = useContractForm();
  const { stepId } = useStep();
  const { installations } = useInstallationsContext();
  const { isAuthorized } = useUser();
  const { visibleFields } = useConfigurableFields();

  const [contractType, currency] = watch(['type', 'currency']);
  const installation = installations?.find(({ uuid }) => uuid === watch('installation_uuid'));

  /** All the fields that are potentially available for a given contract type. */
  const allFields = useMemo(
    () => [...COMMON_FIELDS, ...((contractType ? CONTRACT_TYPE_FIELDS[contractType] : []) || [])],
    [contractType],
  );

  /** All the fields that are potentially available for a given form section. */
  const sectionFields = useMemo(() => {
    return allFields?.filter((field) => getFieldSection(field.name, contractType) === stepId);
  }, [allFields, contractType, stepId]);

  const findField = (fieldName: ContractFormField['name'] | ContractFormField) => {
    if (typeof fieldName !== 'string') return fieldName;

    const fieldConfigName = fieldName.match(/.([0-9]+)./) ? fieldName.replace(/.([0-9]+)./g, '.0.') : fieldName;

    return sectionFields?.find(({ name }) => name === fieldConfigName);
  };

  const hasField = (input: ContractFormField['name'] | ContractFormField, index?: number): boolean => {
    const fieldIndex = getFieldIndex(input, index);
    const field = findField(input);

    if (!field) return false;

    const {
      onlyForFieldValues,
      excludedForFieldValues,
      excludedForCountries,
      onlyForCountries,
      permission,
      configurable,
      name,
    } = field;

    return !!(
      (!onlyForFieldValues || onlyForFieldValues.every((f) => valueMatches(f, getValues, fieldIndex))) &&
      (!excludedForFieldValues || !excludedForFieldValues.some((f) => valueMatches(f, getValues, fieldIndex))) &&
      (!excludedForCountries || !excludedForCountries.includes(installation?.country as CountryCode)) &&
      (!onlyForCountries || onlyForCountries.includes(installation?.country as CountryCode)) &&
      (!permission || isAuthorized([permission])) &&
      (!configurable || visibleFields[name])
    );
  };

  const hasFields = (fields: Array<ContractFormField['name']> | ContractFormField['name'], index?: number): boolean => {
    if (!Array.isArray(fields)) return hasField(fields, index);

    return fields.every((f) => hasField(f, index));
  };

  const isRequired = (input: ContractFormField['name'] | ContractFormField): boolean => {
    const field = findField(input);
    if (!field) return false;

    if (!field.requiredForFieldValues) return !!field.required;

    const requiredForFieldDefs = Object.entries(field.requiredForFieldValues) as [ContractFormField['name'], string][];

    return !!requiredForFieldDefs.find(([dependantField, value]) => {
      return getValues(dependantField) === value || (Array.isArray(value) && value.includes(getValues(dependantField)));
    });
  };

  const dependantFields = useCallback(
    (field: ContractFormField['name']): ContractFormField['name'][] => {
      const fieldsWithDependencies = CONTRACT_TYPE_FIELDS[contractType as ContractType]
        ?.filter(
          (f) =>
            (f.requiredForFieldValues && (f.requiredForFieldValues as Record<string, object>)[field]) ||
            (f.onlyForFieldValues && f.onlyForFieldValues.find((i) => i.field === field)) ||
            (f.excludedForFieldValues && f.excludedForFieldValues.find((i) => i.field === field)),
        )
        ?.map((f) => f.name);

      return !fieldsWithDependencies || !fieldsWithDependencies.length ? [] : fieldsWithDependencies;
    },
    [contractType],
  );

  const isDisabled = (input: ContractFormField['name'] | ContractFormField, index?: number): boolean => {
    const field = findField(input);
    const fieldIndex = getFieldIndex(input, index);
    if (!field || !field.disabledForFieldValues) return false;

    return !!field.disabledForFieldValues.find((f) => valueMatches(f, getValues, fieldIndex));
  };

  const fieldRules = (field: ContractFormField['name']): UseControllerProps['rules'] => {
    const fieldConfiguration = findField(field);
    if (!fieldConfiguration) return;

    let required = fieldConfiguration.required;

    if (fieldConfiguration.requiredForFieldValues) {
      const fieldsToCheck = Object.entries(fieldConfiguration.requiredForFieldValues) as [
        ContractFormField['name'],
        typeof fieldConfiguration.requiredForFieldValues,
      ][];

      required = fieldsToCheck.every(([field, value]) => {
        const valueToCheck = getValues(field);

        return valueToCheck === value || (Array.isArray(value) && value.includes(valueToCheck));
      });
    }

    return { ...fieldConfiguration?.rules, validate: fieldConfiguration.validate, required };
  };

  const fieldConfig = (field: ContractFormField['name']) => {
    const fieldDef = sectionFields?.find((f) => f.name === field);
    if (!fieldDef) return null;

    return {
      rules: { ...fieldDef?.rules, validate: fieldDef.validate, required: fieldDef.required },
      defaultValue: fieldDef.defaultValue,
      unit: fieldDef.unit?.replace('{CURRENCY}', getSymbol(currency)),
    };
  };

  const onChangeField: OnChangeFunction = useCallback(
    async (field, value) => {
      field.onChange(value);
      const fieldsToTrigger = dependantFields(field.name);
      if (fieldsToTrigger?.length && (await trigger(field.name))) trigger(fieldsToTrigger);
    },
    [dependantFields, trigger],
  );

  return {
    allFields,
    dependantFields,
    fieldConfig,
    fieldRules,
    hasField,
    hasFields,
    isDisabled,
    isRequired,
    onChangeField,
    sectionFields,
  };
};
