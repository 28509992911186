import type { NegativePrice } from '../negative-prices.types';
import { getFormattedNegativeHours } from './getFormattedNegativeHours';

describe('getFormattedNegativeHours', () => {
  const data: NegativePrice[] = [
    {
      start_time: '2021-01-01T00:00:00Z',
      end_time: '2021-01-01T01:00:00Z',
      price: -0.01,
      production: 93,
      productible: 102,
      estimated_cost: null,
      nb_negative_price_hours: 1,
      nb_production_hours_on_negative_prices: 1,
      nb_interruption_hours_on_negative_prices: 0,
      total_production_on_negative_prices: 93,
      total_productible_on_negative_prices: 102,
    },
    {
      start_time: '2021-01-01T01:00:00Z',
      end_time: '2021-01-01T02:00:00Z',
      price: -0.01,
      production: 48,
      productible: 48,
      estimated_cost: null,
      nb_negative_price_hours: 1,
      nb_production_hours_on_negative_prices: 1,
      nb_interruption_hours_on_negative_prices: 0,
      total_production_on_negative_prices: 48,
      total_productible_on_negative_prices: 48,
    },
    {
      start_time: '2021-01-01T02:00:00Z',
      end_time: '2021-01-01T03:00:00Z',
      price: -0.023,
      production: 55,
      productible: 55,
      estimated_cost: null,
      nb_negative_price_hours: 1,
      nb_production_hours_on_negative_prices: 1,
      nb_interruption_hours_on_negative_prices: 0,
      total_production_on_negative_prices: 55,
      total_productible_on_negative_prices: 55,
    },
    {
      start_time: '2021-01-02T00:00:00Z',
      end_time: '2021-01-02T01:00:00Z',
      price: -0.11,
      production: 0,
      productible: 50,
      estimated_cost: null,
      nb_negative_price_hours: 1,
      nb_production_hours_on_negative_prices: 0,
      nb_interruption_hours_on_negative_prices: 1,
      total_production_on_negative_prices: 0,
      total_productible_on_negative_prices: 50,
    },
    {
      start_time: '2021-01-02T01:00:00Z',
      end_time: '2021-01-02T02:00:00Z',
      price: -0.07,
      production: 56,
      productible: 56,
      estimated_cost: null,
      nb_negative_price_hours: 1,
      nb_production_hours_on_negative_prices: 1,
      nb_interruption_hours_on_negative_prices: 0,
      total_production_on_negative_prices: 56,
      total_productible_on_negative_prices: 56,
    },
    {
      start_time: '2021-01-02T02:00:00Z',
      end_time: '2021-01-02T03:00:00Z',
      price: -0.09,
      production: 30,
      productible: 35,
      estimated_cost: null,
      nb_negative_price_hours: 1,
      nb_production_hours_on_negative_prices: 1,
      nb_interruption_hours_on_negative_prices: 0,
      total_production_on_negative_prices: 30,
      total_productible_on_negative_prices: 35,
    },
    {
      start_time: '2021-02-01T00:00:00Z',
      end_time: '2021-02-01T01:00:00Z',
      price: -0.1149,
      production: 38,
      productible: 39,
      estimated_cost: null,
      nb_negative_price_hours: 1,
      nb_production_hours_on_negative_prices: 1,
      nb_interruption_hours_on_negative_prices: 0,
      total_production_on_negative_prices: 38,
      total_productible_on_negative_prices: 39,
    },
    {
      start_time: '2021-02-01T01:00:00Z',
      end_time: '2021-02-01T02:00:00Z',
      price: -0.9765,
      production: 0,
      productible: 10,
      estimated_cost: null,
      nb_negative_price_hours: 1,
      nb_production_hours_on_negative_prices: 0,
      nb_interruption_hours_on_negative_prices: 1,
      total_production_on_negative_prices: 0,
      total_productible_on_negative_prices: 10,
    },
    {
      start_time: '2021-02-01T02:00:00Z',
      end_time: '2021-02-01T03:00:00Z',
      price: -0.7482,
      production: 0,
      productible: 0,
      estimated_cost: null,
      nb_negative_price_hours: 1,
      nb_production_hours_on_negative_prices: 0,
      nb_interruption_hours_on_negative_prices: 1,
      total_production_on_negative_prices: 0,
      total_productible_on_negative_prices: 0,
    },
  ];

  it('should return the correct data for daily granularity', () => {
    const result = getFormattedNegativeHours(data, 'daily');

    expect(result).toEqual([
      {
        startDate: '2021-01-01',
        endDate: '2021-01-01',
        negativePriceHours: 3,
        productionHours: 3,
        interruptionHours: 0,
        productionOnNegativeHours: 196,
        productibleOnNegativeHours: 205,
        estimatedCostOnNegativeHours: 0,
      },
      {
        startDate: '2021-01-02',
        endDate: '2021-01-02',
        negativePriceHours: 3,
        productionHours: 2,
        interruptionHours: 1,
        productionOnNegativeHours: 86,
        productibleOnNegativeHours: 141,
        estimatedCostOnNegativeHours: 0,
      },
      {
        startDate: '2021-02-01',
        endDate: '2021-02-01',
        negativePriceHours: 3,
        productionHours: 1,
        interruptionHours: 2,
        productionOnNegativeHours: 38,
        productibleOnNegativeHours: 49,
        estimatedCostOnNegativeHours: 0,
      },
    ]);
  });

  it('should return the correct data for monthly granularity', () => {
    const result = getFormattedNegativeHours(data, 'monthly');

    expect(result).toEqual([
      {
        startDate: '2021-01',
        endDate: '2021-01',
        negativePriceHours: 6,
        productionHours: 5,
        interruptionHours: 1,
        productionOnNegativeHours: 282,
        productibleOnNegativeHours: 346,
        estimatedCostOnNegativeHours: 0,
      },
      {
        startDate: '2021-02',
        endDate: '2021-02',
        negativePriceHours: 3,
        productionHours: 1,
        interruptionHours: 2,
        productionOnNegativeHours: 38,
        productibleOnNegativeHours: 49,
        estimatedCostOnNegativeHours: 0,
      },
    ]);
  });
});
