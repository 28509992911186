import React from 'react';
import { useUser } from '@context/User.context';
import { Icon, IconNames } from '@GDM/Icon';
import { Locale } from '@utils/types/common-types';
import styles from './user-menu-language-picker.module.scss';

const LANGUAGE_CODE_NAME: Record<Locale, { code: IconNames; name: string }> = {
  fr: { code: 'FR', name: 'Français' },
  en: { code: 'UK', name: 'English' },
  es: { code: 'ES', name: 'Español' },
  it: { code: 'IT', name: 'Italiano' },
};

export const LanguageRow: React.FC<{ locale: Locale }> = ({ locale }) => {
  const { email } = useUser();

  const EASTER_EGGED_FLAGS: typeof LANGUAGE_CODE_NAME = {
    ...LANGUAGE_CODE_NAME,
    ...(email === 'eferry@streem.eu' ? { en: { code: 'IE', name: 'Béarla' } } : {}),
    ...(email === 'emejia@streem.eu' ? { en: { code: 'US', name: 'English' } } : {}),
    ...(email === 'emejia@streem.eu' ? { es: { code: 'HN', name: 'Español' } } : {}),
    ...(email === 'lnicolle@streem.eu' ? { fr: { code: 'BZH', name: 'Gallegaj' } } : {}),
  };

  return (
    <div className={styles['language-row']}>
      <Icon name={EASTER_EGGED_FLAGS[locale].code} size={16} className={styles.icon} />
      <div>{EASTER_EGGED_FLAGS[locale].name}</div>
    </div>
  );
};
