import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const PowerAugmentationStartDate = () => {
  const { control, readonly, getValues, watch } = useContractForm();
  const [startDate, endDate] = watch(['start_date', 'end_date']);
  const { fieldRules } = useContractFields();

  const validate = (value: Date | null) => {
    const [startDate, endDate] = getValues(['start_date', 'end_date']);

    if (!value || !startDate || !endDate) return true;

    // value should be in between start_date and end_date, if not returns error as string
    if (startDate && endDate && value && (value < startDate || value > endDate)) {
      return 'sales_management.power_augmentation_start_date_error';
    }

    return true;
  };

  return (
    <Controller
      control={control}
      name="power_augmentation_start_date"
      rules={{ ...fieldRules('power_augmentation_start_date'), validate }}
      render={({ field, fieldState }) => (
        <DatePicker
          {...field}
          size="lg"
          selected={field.value}
          readOnly={readonly}
          hasError={!!fieldState?.error}
          errorMessage={fieldState?.error?.message}
          label="sales_management.power_augmentation_start_date"
          maxDate={endDate}
          minDate={startDate}
        />
      )}
    />
  );
};
