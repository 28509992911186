import { useMemo } from 'react';
import type { InstallationWithMeterInfo } from '@utils/types/installation';
import type { DeepPartial } from 'react-hook-form';
import type { InstallationForm } from '../installation.types';

export const useValuesFromRequest = (installation?: InstallationWithMeterInfo) => {
  return useMemo<DeepPartial<InstallationForm> | undefined>(() => {
    if (installation) {
      let baseValues: DeepPartial<InstallationForm> = {
        uuid: installation.uuid,
        name: installation.name,
        country: installation.country ?? 'FR',
        energy: installation.energy,
        company_number: installation.owner_company_number,
        short_name: installation.owner_short_name,
        owner_id: installation.owner_uuid,
      };

      if (installation.country === 'FR' || installation.country === 'ES') {
        baseValues.boitier_ip = true;
      }

      if (installation.meter) {
        baseValues = {
          ...baseValues,
          prm: installation.meter?.prm,
          numero: installation.meter?.numero,
          key: installation.meter?.key,
          mandate_uuid: installation.meter?.mandate_uuid,
          aiguillage: installation.meter?.aiguillage,
          code: installation.meter?.code,
          ip: installation.meter?.ip,
          tcp_port: installation.meter?.tcp_port,
          boitier_ip: installation.meter?.boitier_ip,
          serial_number: installation.meter?.serial_number,
          model: installation.meter?.model,
          typecompteur: installation.meter?.typecompteur,
          meter_pass_address: installation.meter?.meter_pass_address,
          producteur: installation.meter?.producteur,
        };
      }

      return baseValues;
    }
  }, [installation]);
};
