import React from 'react';
import { useCredentialsContext } from '@context/credentials.context';
import { Toggle } from '@GDM/Toggle';
import { Controller } from 'react-hook-form';
import { useContractForm } from '../../../hooks';

export const ToggleCegedim: React.FC = () => {
  const { control, readonly, trigger } = useContractForm();
  const { cegedimCredentials } = useCredentialsContext();

  const disabled = cegedimCredentials.length === 0;
  const tooltip = cegedimCredentials.length === 0 ? 'sales_management.no_cegedim_credential_exists' : null;

  return (
    <Controller
      name="invoicing_platform"
      control={control}
      render={({ field }) => (
        <Toggle
          name={field.name}
          value={field.value === 'cegedim'}
          onChange={(value) => {
            field.onChange(value ? 'cegedim' : null);
            trigger('contract_nb');
          }}
          label="sales_management.sy"
          data-cy="send-to-cegedim-toggle"
          readOnly={readonly}
          disabled={disabled}
          tooltip={tooltip}
        />
      )}
    />
  );
};
