import { UseControllerProps, useFormContext } from 'react-hook-form';
import { solarFields } from '../config/solar-fields';
import { windFields } from '../config/wind-fields';
import { SimulationFormField, SimulationForm } from './simulations.types';

const allFields = [...solarFields, ...windFields];

export const useFieldRules = () => {
  const { watch } = useFormContext<SimulationForm>();

  const fieldRules = (field: SimulationFormField['name']): UseControllerProps['rules'] => {
    const fieldConfiguration = allFields?.find((f) => f.name === field);

    if (!fieldConfiguration) return;

    let requiredForFieldValues = false;

    if (fieldConfiguration.requiredForFieldValues) {
      const fieldsToCheck = Object.entries(fieldConfiguration.requiredForFieldValues) as [
        SimulationFormField['name'],
        typeof fieldConfiguration.requiredForFieldValues,
      ][];

      requiredForFieldValues = fieldsToCheck.every(([field, value]) => {
        const valueToCheck = watch(field);

        return valueToCheck === value || (Array.isArray(value) && value.includes(valueToCheck));
      });
    }

    const required = fieldConfiguration.required || fieldConfiguration.rules?.required || requiredForFieldValues;

    return { ...fieldConfiguration?.rules, validate: fieldConfiguration.validate, required };
  };

  return { fieldRules };
};
