import React, { useMemo } from 'react';
import { CredentialsContext, credentialsContext } from '@context/credentials.context';
import { useRequest } from '@hooks/useRequest';
import { v2_credentials_path } from '@utils/routes';
import { Credential } from '@utils/types/credential';

export const CredentialsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    data: cegedimCredentials,
    loading: cegedimLoading,
    error: cegedimError,
  } = useRequest<Credential[]>(v2_credentials_path({ source: 'cegedim' }), 'GET');

  const {
    data: edfOaCredentials,
    loading: edfOaLoading,
    error: edfOaError,
  } = useRequest<Credential[]>(v2_credentials_path({ source: 'edf_oa' }), 'GET');

  const contextValue: CredentialsContext = useMemo(
    () => ({
      credentials: [...(cegedimCredentials || []), ...(edfOaCredentials || [])],
      cegedimCredentials: cegedimCredentials || [],
      edfOaCredentials: edfOaCredentials || [],
      isLoading: cegedimLoading || edfOaLoading,
      error: cegedimError || edfOaError,
    }),
    [cegedimCredentials, cegedimError, cegedimLoading, edfOaCredentials, edfOaError, edfOaLoading],
  );

  return <credentialsContext.Provider value={contextValue}>{children}</credentialsContext.Provider>;
};
