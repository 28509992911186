import React, { useMemo } from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const SchemePicker: React.FC = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules, onChangeField } = useContractFields();

  const options = useMemo<Option<ContractForm['dispositif']>[]>(
    () => [
      { value: 'ao_cre', label: 'sales_management.dispositif_ao_cre' },
      { value: 'guichet_ouvert', label: 'sales_management.dispositif_guichet_ouvert' },
    ],
    [],
  );

  return (
    <Controller
      name="dispositif"
      control={control}
      rules={fieldRules('dispositif')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.dispositif"
          size="lg"
          options={options}
          selectedOption={field.value}
          onChange={(option) => onChangeField(field, option?.value)}
          classNamePrefix="select-scheme"
          readOnly={readonly}
          hasError={!!fieldState.error}
          isSearchable
        />
      )}
    />
  );
};
