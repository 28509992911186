import React, { useMemo } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { Select } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';
import { useValidateUniquenessRequest } from '../../../hooks/useValidateUniquenessRequest';

export const SellerPicker: React.FC = () => {
  const { t } = useTranslation();
  const { installations } = useInstallationsContext();
  const { fieldRules } = useContractFields();
  const { control, watch, readonly, intermediaries } = useContractForm();
  const { validate } = useValidateUniquenessRequest();

  const installationUuid = watch('installation_uuid');
  const installation = installations?.find(({ uuid }) => uuid === installationUuid);

  const options = useMemo(() => {
    const options = intermediaries?.data?.map(({ short_name, id }) => ({ label: short_name, value: id })) || [];

    if (installation && installation.owner_uuid) {
      options.push({
        label: `${installation?.owner_short_name || ''} (${t('sales_management.owner')})`.trim(),
        value: installation.owner_uuid,
      });
    }

    return options.sort(sortOptionsByLabelAsc);
  }, [installation, intermediaries?.data, t]);

  return (
    <Controller
      name="seller_id"
      control={control}
      rules={fieldRules('seller_id')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.seller"
          size="lg"
          options={options}
          isLoading={intermediaries?.loading}
          selectedOption={field.value}
          onChange={(option) => {
            field.onChange(option?.value);
            validate();
          }}
          isDisabled={!installationUuid || !!intermediaries?.error || intermediaries?.loading}
          noOptionsMessage="sales_management.no_seller"
          classNamePrefix="select-seller"
          hasError={!!fieldState.error}
          readOnly={readonly}
          isSearchable
        />
      )}
    />
  );
};
