import React from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { Input } from '@GDM/forms';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import { Controller } from 'react-hook-form';

export const AveragePower: React.FC<{ index: number }> = ({ index }) => {
  const { control, watch, readonly } = useContractForm();
  const { fieldConfig } = useContractFields();
  const config = fieldConfig(`hedge_blocks_attributes.${index}.average_power`);
  const { installations } = useInstallationsContext<InstallationWithMeterInfo>();
  const installationUuid = watch('installation_uuid');
  const installationPMax = installations?.find(({ uuid }) => uuid === installationUuid)?.p_max;

  return (
    <Controller
      name={`hedge_blocks_attributes.${index}.average_power`}
      control={control}
      rules={config?.rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          data-cy={`hedge-display-average-power-${index}`}
          label="sales_management.average_power"
          size="lg"
          type="number"
          max={installationPMax}
          step="0.01"
          value={field.value?.toString() || ''}
          hasError={!!fieldState.error}
          suffix="MW"
          autoComplete="off"
          tooltip={
            typeof installationPMax === 'number' && !!field.value && field.value > installationPMax
              ? 'sales_management.info.value_higher_than_installation_p_max'
              : undefined
          }
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
