import { getMarketPlayers } from '@api/marketPlayers';
import { useQuery } from '@tanstack/react-query';

/*
 * This hook fetches the list of market players,
 * most of the time market players are the direct buyers of the energy produced.
 * They can also be sellers, final (non-invoiced) buyers,
 * Balancing Responsible Party like offtakers
 */
export const useMarketPlayers = () => {
  return useQuery({
    queryKey: ['marketPlayers'],
    queryFn: getMarketPlayers,
  });
};
