import React, { useEffect } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { Input } from '@GDM/forms';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { validateSiret } from '@utils/validators/siret';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const InstallationSiret: React.FC = () => {
  const { control, readonly, setValue, watch } = useContractForm();
  const { fieldRules, isDisabled } = useContractFields();
  const { installations } = useInstallationsContext();
  const { t } = useTranslation();

  const installationUuid = watch('installation_uuid');
  const installation = installations?.find(({ uuid }) => uuid === installationUuid);
  const installationOwnerSiren = installation?.owner_company_number;

  const disabled = isDisabled('_non_contract_props.installation.siret');

  const validate = (siret: string) => {
    if (disabled || !siret) return true;

    return validateSiret(siret, installationOwnerSiren);
  };

  // Side effect: when installations are loaded, set this.
  useEffect(() => {
    if (installation?.siret) setValue('_non_contract_props.installation.siret', installation.siret);
  }, [installation?.siret, setValue]);

  return (
    <Controller
      name="_non_contract_props.installation.siret"
      control={control}
      rules={{
        ...fieldRules('_non_contract_props.installation.siret'),
        validate,
      }}
      render={({ field, fieldState }) => (
        <div>
          <Input
            {...field}
            label="sales_management.installation_siret"
            data-cy="installation-siret"
            size="lg"
            value={field.value ?? ''}
            hasError={!!fieldState.error}
            tooltip={fieldState.error ? t('sales_management.owner_siren', { siren: installationOwnerSiren }) : null}
            disabled={disabled}
            readOnly={readonly}
            full
          />
          {fieldState.error && (
            <Text
              data-cy="installation-siret-error"
              className="mt-1"
              size="sm"
              text={fieldState?.error?.message}
              type="danger"
            />
          )}
        </div>
      )}
    />
  );
};
