import React from 'react';
import { Section } from '@GDM/forms';
import { useContractFields, useContractProgress } from '../hooks';

export const ContractSection: React.FC<React.PropsWithChildren<{ className?: string; 'data-cy'?: string }>> = ({
  children,
  className,
  'data-cy': dataCy,
}) => {
  const { sectionFields } = useContractFields();
  useContractProgress(sectionFields);

  return (
    <Section className={className} data-cy={dataCy}>
      {children}
    </Section>
  );
};
