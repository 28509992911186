import React from 'react';
import { ContractSection } from '../../components';
import { SentToRemit } from './SentToRemit';

export const Remit: React.FC = () => {
  return (
    <ContractSection className="py-4 px-3">
      <SentToRemit />
    </ContractSection>
  );
};
