import { isANumber } from '@utils/isANumber';
import { ContractFormField } from '../../contracts.types';

export function getFieldIndex(input: ContractFormField['name'] | ContractFormField, index?: number): string | null {
  if (index !== undefined && isANumber(index)) return `.${index}.`;

  if (typeof input === 'string') return input.match(/.([0-9]+)./)?.[0] ?? null;

  return null;
}
