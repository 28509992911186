import React from 'react';
import { SELECT_COMPONENTS } from '@GDM/forms';
import { EnergyType, Option as TOption } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries';
import type Installation from '@utils/types/installation';
import { GroupBase, OptionProps } from 'react-select';
import { FormattedOption } from './FormattedOption';

type ExtendedOption = TOption & { country?: CountryCode; energy?: EnergyType; status?: Installation['status'] };

export const Option: React.ComponentType<OptionProps<ExtendedOption, boolean, GroupBase<ExtendedOption>>> = (props) => {
  return (
    <SELECT_COMPONENTS.Option {...props}>
      <FormattedOption {...props.data} />
    </SELECT_COMPONENTS.Option>
  );
};
