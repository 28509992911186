import { sortOptionsByLabelAsc } from '@utils/sorters';
import { CountryCode } from '@utils/types/countries';
import MarketPlayer from '@utils/types/market_player';

export const useBuyersOptions = (marketPlayers: MarketPlayer[] | undefined, operationCountry: CountryCode) => {
  return (marketPlayers || [])
    .filter(
      ({ country, operating_countries }) =>
        country === operationCountry || operating_countries?.includes(operationCountry),
    )
    .map((player) => ({ label: player.short_name, value: player.id, type: player.player_type }))
    .sort(sortOptionsByLabelAsc);
};
