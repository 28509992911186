import { useMemo } from 'react';
import { DataPeriod } from '@utils/types/dataPeriod';
import { ReactiveConstraint } from '@utils/types/reactive_constraint';
import { ProductionTimeSeriesRecord } from '../production.types';
import { computePowerMinMaxAverage } from '../utils/computePowerMinMaxAverage';

export const useReactiveEnergy = ({
  timeSeries: { rawReactivePositiveEnergyProduction, rawReactiveNegativeEnergyProduction },
  dataPeriod,
  chartExtremes,
  injectedActiveEnergy,
  constraint,
  reactivePenaltyPrice,
}: {
  dataPeriod: DataPeriod;
  chartExtremes: [number, number];
  timeSeries: Partial<
    Pick<ProductionTimeSeriesRecord, 'rawReactivePositiveEnergyProduction' | 'rawReactiveNegativeEnergyProduction'>
  >;
  injectedActiveEnergy?: number;
  constraint?: ReactiveConstraint;
  reactivePenaltyPrice?: number | null;
}) => {
  // Positive (Withdrawn) Reactive Power or Energy
  const withdrawnReactiveMinMaxAverage = useMemo(() => {
    if (!rawReactivePositiveEnergyProduction?.values.length) return;

    return computePowerMinMaxAverage(rawReactivePositiveEnergyProduction.values, dataPeriod, chartExtremes);
  }, [rawReactivePositiveEnergyProduction, dataPeriod, chartExtremes]);

  // Negative (Injected) Reactive Power or Energy
  const injectedReactiveMinMaxAverage = useMemo(() => {
    if (!rawReactiveNegativeEnergyProduction?.values.length) return;

    return computePowerMinMaxAverage(rawReactiveNegativeEnergyProduction.values, dataPeriod, chartExtremes);
  }, [rawReactiveNegativeEnergyProduction, dataPeriod, chartExtremes]);

  const withdrawnReactiveEnergy = withdrawnReactiveMinMaxAverage?.energy;
  const injectedReactiveEnergy = injectedReactiveMinMaxAverage?.energy;

  const reactiveEnergyBalance = (withdrawnReactiveEnergy ?? 0) - (injectedReactiveEnergy ?? 0);

  const aggregations = {
    injectedReactive: injectedReactiveMinMaxAverage,
    withdrawnReactive: withdrawnReactiveMinMaxAverage,
  };

  if (!injectedActiveEnergy) {
    return { aggregations, tanPhi: undefined, reactiveEnergyBalance };
  }

  const tanPhi = reactiveEnergyBalance / injectedActiveEnergy;

  // This hook only handles tanPhi constraint
  if (
    constraint?.reactive_type !== 'tan_phi' ||
    constraint?.tanphi_min === undefined ||
    constraint?.tanphi_max === undefined
  ) {
    return { aggregations, tanPhi, reactiveEnergyBalance };
  }

  let billableReactiveEnergy = 0;

  const injectsWhenItShouldWithdraw = reactiveEnergyBalance < 0 && !constraint.injection;
  const withdrawsWhenItShouldInject = reactiveEnergyBalance > 0 && constraint.injection;

  const isInOpposition = injectsWhenItShouldWithdraw || withdrawsWhenItShouldInject;
  const aboveConstraint = Math.abs(tanPhi) > constraint.tanphi_max;
  const belowConstraint = Math.abs(tanPhi) < constraint.tanphi_min;

  const areReactiveConstraintsRespected = !isInOpposition && !aboveConstraint && !belowConstraint;

  if (isInOpposition) {
    billableReactiveEnergy = injectedActiveEnergy * constraint.tanphi_min + reactiveEnergyBalance;
  } else if (aboveConstraint) {
    billableReactiveEnergy = injectedActiveEnergy * constraint.tanphi_max - reactiveEnergyBalance;
  } else if (belowConstraint) {
    billableReactiveEnergy = injectedActiveEnergy * constraint.tanphi_min - reactiveEnergyBalance;
  }

  const tanPhiPenalty = reactivePenaltyPrice ? billableReactiveEnergy * reactivePenaltyPrice : undefined;

  return {
    aggregations: {
      injectedReactive: injectedReactiveMinMaxAverage,
      withdrawnReactive: withdrawnReactiveMinMaxAverage,
    },
    reactiveEnergyBalance,
    tanPhi,
    areReactiveConstraintsRespected,
    tanPhiPenalty,
  };
};
