import { ContractForm } from '@utils/types/contract';
import get from 'lodash/get';
import { DeepPartial, FieldPath } from 'react-hook-form';

/**
 * Check if the provided value is set and valid. It must be either a number or a string.
 * Used to update the progress value of a specific section.
 */
export const isSetAndValid = (formValues: DeepPartial<ContractForm>, field: FieldPath<ContractForm>): boolean => {
  const fieldValue = get(formValues, field);

  if (fieldValue === null || fieldValue === undefined) return false;

  if (typeof fieldValue === 'object') return Object.values(fieldValue).every(Boolean);

  return !!fieldValue || fieldValue === 0;
};
