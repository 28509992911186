import { DataPeriod } from '@utils/types/dataPeriod';
import { TimeSeries } from '@utils/types/timeSeries';
import { PowerMinMaxAverage } from '../production.types';
import { computeTimeSeriesAggregation } from './computeTimeSeriesAggregation';

export const computePowerMinMaxAverage = (
  timeSeries: TimeSeries,
  dataPeriod: DataPeriod,
  timeFilter?: [number, number],
  onProcessing?: (point: TimeSeries[number]) => void,
): PowerMinMaxAverage => {
  const timeSeriesAggregation = computeTimeSeriesAggregation(timeSeries, timeFilter, onProcessing);
  // minute based periods come with power (kW) timeseries
  if (dataPeriod === 'minutes') {
    // A simple average of the power data points gives us the average power
    const average = timeSeriesAggregation.sum / timeSeriesAggregation.count;

    const millisecondsInAnHour = 1000 * 60 * 60;
    const elapsedTime = (timeSeriesAggregation.end - timeSeriesAggregation.start) / millisecondsInAnHour;

    return {
      min: timeSeriesAggregation.min,
      max: timeSeriesAggregation.max,
      energy: average * elapsedTime,
      average: average,
    };
  }

  // No extremum displayed for daily and monthly power period
  return {
    energy: timeSeriesAggregation.sum,
    // on monthly and daily periods data points are begining of a given period
    average: timeSeriesAggregation.sum / timeSeriesAggregation.hoursCount[dataPeriod],
  };
};
