import { ConfigurableFieldsContext } from '../../contracts.types';

const CONFIGURABLE_FIELDS_KEY = 'contracts.configurable-fields';

export function getConfiguredFields(): ConfigurableFieldsContext['visibleFields'] | null {
  const item = localStorage.getItem(CONFIGURABLE_FIELDS_KEY);

  if (!item) return null;
  const parsedItem = JSON.parse(item);

  // Make sure it's a dictionary (without going through all the possible fields)
  return parsedItem.constructor === Object ? parsedItem : null;
}

export function setConfiguredFields(fields: ConfigurableFieldsContext['visibleFields']): void {
  localStorage.setItem(CONFIGURABLE_FIELDS_KEY, JSON.stringify(fields));
}
