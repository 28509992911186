import React from 'react';
import { Input } from '@GDM/forms';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { Controller } from 'react-hook-form';

export const Multiplier: React.FC<{ index: number }> = ({ index }) => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  return (
    <Controller
      name={`contract_sub_periods_attributes.${index}.multiplier`}
      control={control}
      rules={fieldRules(`contract_sub_periods_attributes.${index}.multiplier`)}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          data-cy={`multiplier-input-${index}`}
          label="sales_management.multiplier"
          size="lg"
          type="number"
          value={field.value ?? ''}
          min={0.1}
          max={5}
          step={0.1}
          hasError={!!fieldState.error}
          suffix="REC/MWh"
          autoComplete="off"
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
