import { createContext, useContext } from 'react';
import { RequestError } from '@hooks/useRequest';
import { Credential } from '@utils/types/credential';

export type CredentialsContext = {
  credentials: Credential[];
  cegedimCredentials: Credential[];
  edfOaCredentials: Credential[];
  isLoading?: boolean;
  error?: RequestError | null;
};

export const credentialsContext = createContext<CredentialsContext>({
  credentials: [],
  cegedimCredentials: [],
  edfOaCredentials: [],
  isLoading: false,
});

export const useCredentialsContext = (): CredentialsContext => useContext(credentialsContext);
