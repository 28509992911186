import React from 'react';
import { IconNames } from '@GDM/Icon';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './radio-buttons.module.scss';
import { RadioButtonLabel } from './RadioButtonLabel';

export type RadioOption<T> = {
  label: string;
  value: T;
  icon?: IconNames;
};

export type RadioButtonsProps<T> = {
  className?: string;
  disabled?: boolean;
  label?: string;
  name?: string;
  onChange?: (value: RadioOption<T>['value']) => void;
  options: RadioOption<T>[];
  readOnly?: boolean;
  selected?: RadioOption<T>['value'] | null;
  size?: 'lg' | 'sm';
  tooltip?: string;
};

export function RadioButtons<T extends string | number | boolean | undefined>({
  className,
  disabled,
  label,
  name,
  onChange,
  options,
  readOnly,
  selected,
  size,
  tooltip,
}: RadioButtonsProps<T>): JSX.Element {
  const { t } = useTranslation();
  const uuid = Math.floor((1 + Math.random()) * 0x10000).toString(16);

  return (
    <div className="d-flex flex-column justify-content-start">
      {label && <label className={styles.label}>{t(label)}</label>}
      <Tooltip tooltip={tooltip}>
        <div
          className={classNames(
            className,
            styles['radio-buttons'],
            size && styles[size],
            { [styles.disabled]: disabled },
            { [styles.readonly]: readOnly },
          )}
        >
          {options.map((option) => (
            <div
              key={option.value?.toString()}
              className={classNames(styles['radio-button'], option.value === selected && styles['radio-button-active'])}
            >
              <input
                name={name}
                id={`${name || uuid}-${option.value}`}
                type="radio"
                disabled={disabled || readOnly}
                value={option.value?.toString()}
                onChange={() => onChange?.(option.value)}
                checked={option.value === selected}
              />
              <RadioButtonLabel
                htmlFor={`${name || uuid}-${option.value}`}
                label={option.label}
                icon={option.icon}
                data-cy={`${name || uuid}-${option.value}`}
              />
            </div>
          ))}
        </div>
      </Tooltip>
    </div>
  );
}
