import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';

export const CRFields: ContractFormField[] = [
  { name: 'p_max', configurable: true },
  { name: 'cr_facturation_type', required: true },
  { name: 'contract_sub_periods_attributes.0.dates', required: true },
  {
    name: 'contract_sub_periods_attributes.0.neg_price_rule',
    disabledForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_rule', present: true }],
  },
  {
    name: 'contract_sub_periods_attributes.0.display_price',
    required: false,
    disabledForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_rule', present: true }],
  },
  {
    name: 'contract_sub_periods_attributes.0.aggreg_fees',
    required: false,
    disabledForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_rule', present: true }],
  },
  {
    name: 'contract_sub_periods_attributes.0.price_type',
    required: false,
    disabledForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_rule', present: true }],
  },
  {
    name: 'contract_sub_periods_attributes.0.price_model_attributes.source',
    onlyForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_type', value: 'spot' }],
    info: { title: 'sales_management.price_model.source', text: 'sales_management.price_model.source_info' },
  },
  { name: 'contract_sub_periods_attributes.0.price_rule', required: false },
  { name: 'external_pdf_link', required: false, permission: 'display:non_engie' },
  { name: 'sent_to_remit' },
];
