import React, { useEffect, useState } from 'react';
import { useMandatesContext } from '@context/Mandates.context';
import { Input, useField } from '@GDM/forms';
import { Text } from '@GDM/Text';
import { useRequest } from '@hooks/useRequest';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { parseTypecompteur } from '@pages/Installations/Installation/utils/parseTypeCompteur';
import { ping_v2_meters_path } from '@utils/routes';
import { Controller } from 'react-hook-form';
import { PingResponse } from './prm.types';

export const PrmInput: React.FC = () => {
  const { control, watch, setValue, setError, clearErrors } = useInstallationForm();
  const [warning, setWarning] = useState<string | null>(null);
  const { rules, disabled } = useField('prm');
  const { data: mandates } = useMandatesContext();
  const [mandateUuid, prm] = watch(['mandate_uuid', 'prm']);
  const selectedMandate = mandates?.find((mandate) => mandate.uuid === mandateUuid);
  const pingRequest = useRequest<PingResponse>(
    ping_v2_meters_path({ params: { prm, mandate: selectedMandate?.representative } }),
    'GET',
    true,
  );

  const handleCheck = () => {
    if (!selectedMandate) return;

    clearErrors('prm');

    pingRequest.execute?.();
  };

  useEffect(() => {
    if (pingRequest.data) {
      setValue('key', pingRequest.data?.line?.key || '', { shouldValidate: true });
      setValue('numero', pingRequest.data?.line?.number || '', { shouldValidate: true });
      setValue('boitier_ip', pingRequest.data?.type === 'IP', { shouldValidate: true });
      setValue('typecompteur', parseTypecompteur(pingRequest.data?.meter_type), { shouldValidate: true });
    }
  }, [pingRequest.data, setValue]);

  useEffect(() => {
    if (pingRequest.error) {
      setWarning(pingRequest.error.code || 'admin.installations.prm_error');
    }
  }, [pingRequest.error, setError]);

  return (
    <Controller
      name="prm"
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <div>
          <Input
            {...field}
            label="PRM"
            size="lg"
            full
            pattern="[0-9]{14}"
            suffixButtonProps={{
              onClick: handleCheck,
              variant: 'primary-2',
              text: 'admin.installations.validate_prm',
              disabled: !mandateUuid || !field.value || field.value.length !== 14 || pingRequest.loading,
              isLoading: pingRequest.loading,
              'data-cy': 'validate-prm',
            }}
            data-cy="meter-prm"
            hasError={!!fieldState.error}
            errorMessage={fieldState.error?.message}
            warningMessage={warning}
            disabled={disabled}
          />

          <Text text="admin.installations.prm_help" size="sm" className="mt-2" />
        </div>
      )}
    />
  );
};
