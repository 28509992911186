import React from 'react';
import { Button } from '@GDM/Button';
import { CONTRACT_TYPES_WITH_DOUBLE_AGGREG_FEES } from '@pages/Contracts/Contract/Form/builder/config';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { ContractType } from '@utils/types/contract';
import classNames from 'classnames';
import { useFieldArray } from 'react-hook-form';
import styles from './aggreg-fees.module.scss';
import { AggregFee } from './AggregFee';

export const AggregFees: React.FC<{ index: number }> = ({ index }) => {
  const { control, watch, getValues, readonly } = useContractForm();
  const { isDisabled } = useContractFields();

  const disabled = isDisabled(`contract_sub_periods_attributes.${index}.aggreg_fees`);
  const contractType = watch('type');
  const canHaveMultipleFees = CONTRACT_TYPES_WITH_DOUBLE_AGGREG_FEES.includes(contractType as ContractType);

  const aggregFees = useFieldArray({
    control,
    name: `contract_sub_periods_attributes.${index}.aggreg_fees`,
    keyName: 'aggreg_fees_id',
  });

  const addAggregFee = () => {
    const type = getValues(`contract_sub_periods_attributes.${index}.aggreg_fees.0`)?.type;
    aggregFees.append({ value: null, type: type === 'fixed' || !type ? 'percentage' : 'fixed' });
  };

  return (
    <div className={styles.wrapper}>
      {/* When editing a contract that has no previously set fees, we have to make sure they can add at least one. */}
      {aggregFees.fields?.length === 0 && (
        <div className={classNames(styles['fee-wrapper'])}>
          <AggregFee subPeriodIndex={index} aggregFeeIndex={0} isDisabled={disabled} />
        </div>
      )}

      {aggregFees?.fields?.map((fee, i) => (
        <div className={classNames(styles['fee-wrapper'])} key={fee.aggreg_fees_id}>
          <AggregFee subPeriodIndex={index} aggregFeeIndex={i} isDisabled={disabled} />
          {aggregFees.fields.length > 1 && !readonly && (
            <Button
              icon="Trash2"
              variant="secondary"
              tooltip="common.delete"
              onClick={() => aggregFees.remove(i)}
              disabled={disabled}
              className="ml-1"
              size="xs"
              floating
            />
          )}
        </div>
      ))}

      {aggregFees.fields.length <= 1 && canHaveMultipleFees && !readonly && (
        <Button
          className={styles['plus-button']}
          icon="Plus"
          text="sales_management.add_fee"
          onClick={addAggregFee}
          variant="link"
          disabled={disabled}
          size="sm"
          data-cy="add-aggreg-fee"
        />
      )}
    </div>
  );
};
