import React, { useMemo } from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';
import styles from './rate-change-picker.module.scss';

export const RateChangePicker: React.FC = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules, onChangeField } = useContractFields();

  const options = useMemo<Option<ContractForm['rate_change']>[]>(
    () => [
      { value: 'commissioning_date', label: 'sales_management.commissioning_date' },
      { value: '1st_nov', label: 'sales_management.1st_nov' },
      { value: '1st_jan', label: 'sales_management.1st_jan' },
    ],
    [],
  );

  return (
    <Controller
      name="rate_change"
      control={control}
      rules={fieldRules('rate_change')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.rate_change"
          menuPlacement="top"
          size="lg"
          className={styles.picker}
          options={options}
          selectedOption={field.value}
          onChange={(option) => onChangeField(field, option?.value)}
          classNamePrefix="select-rate-change"
          readOnly={readonly}
          hasError={!!fieldState.error}
          isSearchable
        />
      )}
    />
  );
};
