import type { BookInstallation } from '@pages/DashboardMeter/Tabs/Characteristics/characteristics.types';
import axiosInstance from '@utils/axiosInstance';
import { v2_book_get_installations_for_book_path, v2_installation_path, v2_installations_path } from '@utils/routes';
import type { InstallationWithMeterInfo } from '@utils/types/installation';

export const getInstallations = async () => {
  return (await axiosInstance(false).get<InstallationWithMeterInfo[]>(v2_installations_path())).data;
};

export const getInstallationByName = async (name?: InstallationWithMeterInfo['name']) => {
  return (await axiosInstance(false).get<InstallationWithMeterInfo>(v2_installation_path(name || ''))).data;
};

export const getInstallationsByBookId = async (uuid: string) => {
  return (await axiosInstance(false).get<BookInstallation[]>(v2_book_get_installations_for_book_path(uuid))).data;
};

export const createInstallation = async (installation: Partial<InstallationWithMeterInfo>) => {
  return (await axiosInstance(false).post<InstallationWithMeterInfo>(v2_installations_path(), { installation })).data;
};

export const updateInstallation = async (
  name: InstallationWithMeterInfo['name'],
  installation: Partial<InstallationWithMeterInfo>,
) => {
  return (await axiosInstance(false).patch<InstallationWithMeterInfo>(v2_installation_path(name), { installation }))
    .data;
};

export const deleteInstallation = async (name: InstallationWithMeterInfo['name']) => {
  return axiosInstance(false).delete(v2_installation_path(name));
};
