import { useMemo } from 'react';
import { isANumber } from '@utils/isANumber';
import { DataPeriod } from '@utils/types/dataPeriod';
import { TimeSeries } from '@utils/types/timeSeries';
import { ProductionTimeSeriesRecord } from '../production.types';
import { computePowerMinMaxAverage } from '../utils/computePowerMinMaxAverage';

export function useConsumption({
  rawActiveEnergyConsumption,
  dataPeriod,
  chartExtremes,
  activeEnergyMap,
}: {
  rawActiveEnergyConsumption: ProductionTimeSeriesRecord['rawActiveEnergyConsumption'] | undefined;
  dataPeriod: DataPeriod;
  chartExtremes: [number, number];
  activeEnergyMap: Record<number, number> | undefined;
}): { consumption?: number; autoConso?: number; autoProd?: number } {
  return useMemo(() => {
    let autoConso;
    let autoProd;
    if (!rawActiveEnergyConsumption?.values.length) return {};
    let consumptionProducedLocally = 0;
    let totalProdOnConsoIndex = 0;
    const incrementConsumptionAndProduction = ([time, consumption]: TimeSeries[number]) => {
      const production = activeEnergyMap?.[time];
      consumptionProducedLocally += Math.min(production || 0, consumption);
      if (isANumber(production)) totalProdOnConsoIndex += production;
    };
    const { energy: consumption } = computePowerMinMaxAverage(
      rawActiveEnergyConsumption.values,
      dataPeriod,
      chartExtremes,
      incrementConsumptionAndProduction,
    );

    if (consumption > 0) {
      autoConso = Math.round((100 * consumptionProducedLocally) / consumption);
    }

    if (totalProdOnConsoIndex > 0) {
      autoProd = Math.round((100 * consumptionProducedLocally) / totalProdOnConsoIndex);
    }

    return { consumption, autoConso, autoProd };
  }, [rawActiveEnergyConsumption, dataPeriod, chartExtremes, activeEnergyMap]);
}
