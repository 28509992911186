import React from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { sectionStyles } from '@GDM/forms';
import { useContractFields, usePriceRules } from '@pages/Contracts/Contract/Form/hooks';
import { ContractSubPeriodForm } from '@utils/types/contract';
import classNames from 'classnames';
import { AggregFees } from '../AggregFees';
import { DatesPicker } from '../DatesPicker';
import { DisplayPrice } from '../DisplayPrice';
import { Multiplier } from '../Multiplier';
import { NegativePriceRulePicker } from '../NegativePriceRulePicker';
import { PhaseTypePicker } from '../PhaseTypePicker';
import { PriceRulePicker } from '../PriceRulePicker';
import { PriceTypePicker } from '../PriceTypePicker';
import { SourcePicker } from '../SourcePicker';
import styles from '../sub-period.module.scss';
import { SwapFixedPrice } from '../SwapFixedPrice';
import { SwapPriceTypePicker } from '../SwapPriceTypePicker';
import { SwapScaleFactor } from '../SwapScaleFactor';

/** Component meant to keep "legacy" templating behaviour while spliting it away in contract types specific templates*/
export const DefaultSubPeriod = ({ index, period }: { index: number; period: ContractSubPeriodForm }) => {
  // Utimately we should do without the hasField(s) system
  // Since the current system is highly dependent on it those methods are provided in this scope
  // While templating logic is progressively moved out of this component
  const { hasFields, hasField } = useContractFields();

  //TO DO: Turn needed data into props and lift up method calls outside of the component
  const { isLoading: installationsIsLoading } = useInstallationsContext();
  const { priceRules } = usePriceRules();
  const hasPriceRules = !!priceRules?.length;

  return (
    <div className="p-3 pb-2">
      <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
        {period.phase_type && <PhaseTypePicker index={index} />}
        <DatesPicker index={index} />
        {hasField(`contract_sub_periods_attributes.${index}.price_type`) &&
          !hasField(`contract_sub_periods_attributes.${index}.extra_params.swap_fixed_price`) && (
            <PriceTypePicker index={index} />
          )}

        {hasField(`contract_sub_periods_attributes.${index}.price_model_attributes.source`) &&
          !hasField(`contract_sub_periods_attributes.${index}.extra_params.swap_fixed_price`) && (
            <SourcePicker index={index} />
          )}

        {hasField(`contract_sub_periods_attributes.${index}.display_price`) && <DisplayPrice index={index} />}

        {hasField(`contract_sub_periods_attributes.${index}.price_model.source`) && <SourcePicker index={index} />}
        {hasField(`contract_sub_periods_attributes.${index}.multiplier`) && !installationsIsLoading && (
          <Multiplier index={index} />
        )}
      </div>

      <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
        {hasField(`contract_sub_periods_attributes.${index}.aggreg_fees`) && <AggregFees index={index} />}
      </div>

      {hasField(`contract_sub_periods_attributes.${index}.extra_params.swap_fixed_price`) && (
        <div className="mb-3">
          <div className={styles['subtitle']}>+ Leg A</div>
          <div className={sectionStyles.row}>
            <SwapFixedPrice index={index} />
            <PriceTypePicker index={index} />
          </div>
        </div>
      )}

      {hasFields([
        `contract_sub_periods_attributes.${index}.extra_params.swap_scale_factor`,
        `contract_sub_periods_attributes.${index}.extra_params.swap_price_type`,
      ]) && (
        <div className="mb-3">
          <div className={styles['subtitle']}>- Leg B</div>
          <div className={sectionStyles.row}>
            <SwapScaleFactor index={index} />
            <SwapPriceTypePicker index={index} />
            <SourcePicker index={index} />
          </div>
        </div>
      )}

      {hasFields(`contract_sub_periods_attributes.${index}.neg_price_rule`) && (
        <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
          <div className="wider">
            <NegativePriceRulePicker index={index} />
          </div>
        </div>
      )}

      {hasField(`contract_sub_periods_attributes.${index}.price_rule`) && hasPriceRules && (
        <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
          <PriceRulePicker index={index} />
        </div>
      )}
    </div>
  );
};
