import React from 'react';
import { Restricted } from '@components/Restricted';
import { useUser } from '@context/User.context';
import { ExtendableBox } from '@GDM/ExtendableBox';
import { Checkbox } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { DataTypeFiltersForm as DataTypeFiltersForm } from '@utils/types/dataFilter';
import { DataPeriod } from '@utils/types/dataPeriod';
import { UseFormReturn } from 'react-hook-form';
import { chartColors } from '../constants/chartColors';
import styles from '../production.module.scss';

export const DataTypeFilters = ({
  meterType,
  inverterFilterDisabled,
  periodType,
  showForecast,
  isAutoConso = false,
  form,
}: {
  meterType?: string;
  periodType: DataPeriod;
  inverterFilterDisabled: boolean;
  isAutoConso?: boolean;
  showForecast?: boolean;
  form: UseFormReturn<DataTypeFiltersForm>;
}) => {
  const { t } = useTranslation();
  const { watch, setValue } = form;
  const [
    showConso,
    showCurtailmentLine,
    showInverters,
    showMarketPrices,
    showPotentialPower,
    showProdCorrected,
    showProdCorrectedHistory,
    showProdExtrapolated,
    showProdForecast,
    showProdDispatch,
    showProduction,
    showReactive,
    showSelfConsumption,
    showSurplus,
    showTension,
  ] = watch([
    'showConso',
    'showCurtailmentLine',
    'showInverters',
    'showMarketPrices',
    'showPotentialPower',
    'showProdCorrected',
    'showProdCorrectedHistory',
    'showProdExtrapolated',
    'showProdForecast',
    'showProdDispatch',
    'showProduction',
    'showReactive',
    'showSelfConsumption',
    'showSurplus',
    'showTension',
  ]);

  const user = useUser();
  const isKw = periodType !== 'daily' && periodType !== 'monthly';

  return (
    <ExtendableBox className={styles['extendable-box']} title="common.graph_settings">
      <div className={styles['graph-filter-container']}>
        <Checkbox
          label={
            periodType === 'monthly' ? 'monitoring.installation.raw_load_curve' : 'monitoring.installation.load_curve'
          }
          checked={showProduction && showProdExtrapolated}
          onChange={(e) => {
            setValue('showProduction', e.target.checked);
            setValue('showProdExtrapolated', e.target.checked);
          }}
          color={chartColors.productionColor}
        />

        {isKw && (
          <Checkbox
            label="monitoring.installation.curtailment_value"
            checked={showCurtailmentLine}
            onChange={(e) => setValue('showCurtailmentLine', e.target.checked)}
            color="var(--grey)"
          />
        )}

        <Restricted permissions={['display:view_monitoring_dashboard_meter_reactive']}>
          {periodType !== 'monthly' && (
            <Checkbox
              label="common.reactive"
              checked={showReactive}
              onChange={(e) => setValue('showReactive', e.target.checked)}
              color={chartColors.reactiveColor}
            />
          )}
        </Restricted>

        <Checkbox
          label="common.consumption"
          checked={showConso}
          onChange={(e) => setValue('showConso', e.target.checked)}
          color={chartColors.consoColor}
        />

        {periodType !== 'monthly' && periodType !== 'daily' && (
          <Checkbox
            label="common.tension"
            checked={showTension}
            onChange={(e) => setValue('showTension', e.target.checked)}
            color={chartColors.tensionColor}
          />
        )}

        {periodType !== 'monthly' && (
          <Checkbox
            label="common.prod_corrected"
            checked={showProdCorrected}
            onChange={(e) => setValue('showProdCorrected', e.target.checked)}
            color={chartColors.correctedProductionColor}
          />
        )}

        {periodType !== 'monthly' && meterType === 'hydro' && (
          <Checkbox
            label="monitoring.installation.prod_corrected_history"
            checked={showProdCorrectedHistory}
            onChange={(e) => setValue('showProdCorrectedHistory', e.target.checked)}
            color={chartColors.correctedProductionHistoryColor}
          />
        )}

        <Checkbox
          label={meterType == 'wind' ? t('common.benchmark') : t('common.productible')}
          checked={showPotentialPower}
          onChange={(e) => setValue('showPotentialPower', e.target.checked)}
          color={chartColors.potentialPowerColor}
        />

        {periodType !== 'monthly' && periodType !== 'daily' && (
          <Checkbox
            label="common.prod_forecast"
            checked={!showForecast ? false : showProdForecast}
            onChange={(e) => setValue('showProdForecast', e.target.checked)}
            disabled={!showForecast}
            color={chartColors.forecastColor}
          />
        )}

        {user.email == 'streemdemo@streem.eu' && periodType !== 'monthly' && periodType !== 'daily' && (
          // TODO remove dummy false once prod team approved
          <Checkbox
            label="common.prod_dispatch"
            checked={!showForecast ? false : showProdDispatch}
            onChange={(e) => setValue('showProdDispatch', e.target.checked)}
            disabled={!showForecast}
            tooltip="common.prod_dispatch_info"
            color={chartColors.dispatchColor}
          />
        )}

        {isAutoConso && (
          <Checkbox
            label="common.autoconso"
            checked={!isAutoConso ? false : showSelfConsumption}
            onChange={(e) => setValue('showSelfConsumption', e.target.checked)}
            disabled={!isAutoConso}
            color={chartColors.selfConsumptionColor}
          />
        )}

        {isAutoConso && (
          <Checkbox
            label="common.surplus"
            checked={!isAutoConso ? false : showSurplus}
            onChange={(e) => setValue('showSurplus', e.target.checked)}
            disabled={!isAutoConso}
            color={chartColors.surplusColor}
          />
        )}

        {periodType !== 'monthly' && (
          <Checkbox
            label="common.scada"
            checked={inverterFilterDisabled ? false : showInverters}
            onChange={(e) => setValue('showInverters', e.target.checked)}
            disabled={inverterFilterDisabled}
            tooltip="common.inverterinfo"
            color={chartColors.scadaColor}
          />
        )}

        {periodType !== 'monthly' && periodType !== 'daily' && (
          <Checkbox
            label="common.market_prices"
            checked={showMarketPrices}
            onChange={(e) => setValue('showMarketPrices', e.target.checked)}
            color={chartColors.marketPricesColor}
          />
        )}
      </div>
    </ExtendableBox>
  );
};
