import { hasCountryDefinition } from '@pages/Contracts/Contract/Form/helpers';
import { Option } from '@utils/types/common-types';
import { PriceModel } from '@utils/types/contract';
import { SPOT_SOURCES } from '@utils/types/contract/constants';
import { CountryCode } from '@utils/types/countries';

export const getSourceOptions = (country: CountryCode | string | null): Option<PriceModel['source']>[] => {
  if (!country) return [];

  // This means that no sources are defined in the SPOT_SOURCES list.
  // This list is defined in the backend and can be synced with the frontend by running the following command:
  // rails runner "./scripts/generate_price_accepted_sources_types.rb"
  if (!hasCountryDefinition(country)) {
    // eslint-disable-next-line no-console
    console.warn(
      `Country with code ${country} has no SPOT sources defined.`,
      "This isn't a critical error, but might want to look into it.",
    );

    return [];
  }

  return SPOT_SOURCES[country].map((source) => ({
    label: source,
    value: source,
  }));
};
