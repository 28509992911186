import React, { InputHTMLAttributes } from 'react';
import { Input, InputProps } from '@GDM/forms';
import { FieldValues, Controller, ControllerProps } from 'react-hook-form';

export default function ControlledInput<T extends FieldValues>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  ...inputProps
}: Omit<InputProps, 'value' | 'onChange'> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'> &
  Omit<ControllerProps<T>, 'render'>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <Input
          {...inputProps}
          onChange={(event) => field.onChange(event.target.value)}
          value={field.value}
          hasError={Boolean(fieldState.error)}
          errorMessage={fieldState.error?.message}
          data-cy={name}
        />
      )}
    />
  );
}
