import { useMemo } from 'react';
import { CountryCode } from '@utils/types/countries';
import cloneDeep from 'lodash/cloneDeep';
import { FieldValues, useFormContext } from 'react-hook-form';
import { FormField } from '../form.types';
import { useInternalFormContext } from '../Form/Form.context';
import { isFieldRequired } from '../utils';

/**
 * You can have multiple field with the same key that are defined differently based on the country
 * This function will return the correct field
 */
export const findCountryField = (fields: FormField<FieldValues>[], country?: CountryCode | null) => {
  if (country) {
    const countryField = fields.find((f) => f.onlyForCountries?.includes(country));

    if (countryField) {
      return countryField;
    }
  }

  return cloneDeep(fields[0]);
};

export const useField = (
  name: string,
): FormField<FieldValues> & {
  disabled: boolean;
} => {
  const { commonFields, country, loading } = useInternalFormContext();
  const { getValues } = useFormContext();
  const fields = commonFields.filter((f) => f.name === name);

  const field = useMemo(() => {
    const internalField = findCountryField(fields, country);

    const required = isFieldRequired(internalField, getValues());

    internalField.rules = { ...internalField.rules, required };
    internalField.required = required;

    return internalField;
  }, [fields, country, getValues]);

  if (!field) {
    throw new Error(`Field ${name} not found, it should be defined in the commonFields prop.`);
  }

  const disabled = loading;

  return { ...field, disabled };
};
