import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';

export const GOFields: ContractFormField[] = [
  { name: 'p_max', configurable: true },
  { name: 'contract_sub_periods_attributes.0.dates', required: true },
  {
    name: 'contract_sub_periods_attributes.0.price_type',
  },
  { name: 'contract_sub_periods_attributes.0.display_price', required: false },
  { name: 'contract_sub_periods_attributes.0.multiplier', required: false, excludedForCountries: ['FR'] },
  { name: 'external_pdf_link', required: false, permission: 'display:non_engie' },
  { name: 'sent_to_remit' },
];
