import React, { useMemo, useState } from 'react';
import {
  DateCell,
  HeadCell,
  NumberCell,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TablePageSizeSelect,
  TablePagination,
} from '@GDM/Table';
import {
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  getSortedRowModel,
  type InitialTableState,
  getPaginationRowModel,
  type TableState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { getSymbol } from '@utils/currency/getSymbol';
import { granularityFormat, type Granularity } from './constants/granularities';
import type { NegativeHour } from './helpers/getFormattedNegativeHours';

export const GroupedByDateNegativePricesTable = ({
  negativeHours,
  isLoading,
  granularity,
  hideZeroProduction,
}: {
  negativeHours?: NegativeHour[];
  isLoading: boolean;
  granularity: Granularity;
  hideZeroProduction: boolean;
}) => {
  const columns = useColumns(granularity);
  const data = negativeHours ?? [];
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const initialState = useMemo<InitialTableState>(() => ({ sorting: [{ id: 'startDate', desc: false }] }), []);

  const columnFilters = useMemo<TableState['columnFilters']>(
    () => [{ id: 'productionOnNegativeHours', value: hideZeroProduction }],
    [hideZeroProduction],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState,
    onPaginationChange: setPagination,
    state: { pagination, columnFilters },
  });

  return (
    <>
      <Table>
        <TableHead table={table} />
        <TableBody table={table} isLoading={isLoading} />
      </Table>

      <TableActions>
        <TablePageSizeSelect
          pageSize={pagination.pageSize}
          setPageSize={table.setPageSize}
          totalEntries={data.length}
        />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </>
  );
};

const useColumns = (granularity: Granularity) => {
  return useMemo(
    () =>
      [
        {
          accessorKey: 'startDate',
          header: 'common.date',
          cell: (row) => <DateCell getValue={row.getValue} format={granularityFormat[granularity]} />,
        },
        {
          accessorKey: 'negativePriceHours',
          header: () => <HeadCell label="common.n_negative_price_hours" className="text-wrap" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          accessorKey: 'productionHours',
          header: () => <HeadCell label="common.n_negative_price_prod_hours" className="text-wrap" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          accessorKey: 'interruptionHours',
          header: () => <HeadCell label="common.n_interruption_hours_on_negative_price" className="text-wrap" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          accessorKey: 'productionOnNegativeHours',
          header: () => <HeadCell label="common.prod_during_negative_price" tooltip="kWh" className="text-wrap" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
          filterFn: (row, id, filterValue) => {
            if (!filterValue) {
              return true;
            }

            return row.original.productionOnNegativeHours > 0;
          },
        },
        {
          accessorKey: 'productibleOnNegativeHours',
          header: () => <HeadCell label="common.productible_during_negative_price" tooltip="kWh" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
          filterFn: (row, id, filterValue) => {
            if (!filterValue) {
              return true;
            }

            return row.original.productionOnNegativeHours > 0;
          },
        },
        {
          accessorKey: 'estimatedCostOnNegativeHours',
          header: () => <HeadCell label="common.estimated_cost" tooltip={getSymbol('EUR')} />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
      ] satisfies ColumnDef<NegativeHour>[],
    [granularity],
  );
};
