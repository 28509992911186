import React, { useMemo } from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../../hooks';
import { CONTRACT_OPTIONS_MAP } from './options';

export const NegativePriceRulePicker: React.FC<{ index?: number }> = ({ index = 0 }) => {
  const { control, readonly, watch } = useContractForm();
  const { fieldRules, isDisabled } = useContractFields();

  const contractType = watch('type');
  const priceType = watch(`contract_sub_periods_attributes.${index}.price_type`);

  const options = useMemo<Option<ContractForm['contract_sub_periods_attributes'][number]['neg_price_rule']>[]>(
    () =>
      contractType
        ? contractType in CONTRACT_OPTIONS_MAP
          ? CONTRACT_OPTIONS_MAP[contractType as keyof typeof CONTRACT_OPTIONS_MAP]
          : CONTRACT_OPTIONS_MAP.default
        : [],
    [contractType],
  );

  const defaultValueIsZero = contractType === 'ContractCr' && priceType === 'm0';
  const defaultValue = defaultValueIsZero ? 'zero' : 'same_as_positive_prices';

  return (
    <Controller
      name={`contract_sub_periods_attributes.${index}.neg_price_rule`}
      control={control}
      rules={fieldRules(`contract_sub_periods_attributes.${index}.neg_price_rule`)}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.neg_price_rule"
          size="lg"
          menuPlacement="top"
          options={options}
          selectedOption={field.value || defaultValue}
          onChange={(option) => field.onChange(option?.value)}
          classNamePrefix="select-neg-price-rule"
          isDisabled={isDisabled(`contract_sub_periods_attributes.${index}.neg_price_rule`)}
          readOnly={readonly}
          hasError={!!fieldState.error}
          isSearchable
        />
      )}
    />
  );
};
