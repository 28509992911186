import React, { useCallback, useMemo, useState } from 'react';
import { ConfigurableFieldsContext } from '../../contracts.types';
import { getConfiguredFields, setConfiguredFields } from '../../Form/helpers';
import { configurableFieldsContext } from './configurable-fields.context';

export const ConfigurableFieldsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [visibleFields, setVisibleFields] = useState<ConfigurableFieldsContext['visibleFields']>(
    getConfiguredFields() || {},
  );

  const setFields = useCallback((fields: ConfigurableFieldsContext['visibleFields']): void => {
    setVisibleFields(fields);
    setConfiguredFields(fields);
  }, []);

  const subPeriodContextValue = useMemo<ConfigurableFieldsContext>(
    () => ({
      visibleFields,
      setVisibleFields: setFields,
    }),
    [setFields, visibleFields],
  );

  return (
    <configurableFieldsContext.Provider value={subPeriodContextValue}>{children}</configurableFieldsContext.Provider>
  );
};
