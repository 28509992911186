import React, { useMemo } from 'react';
import { Button } from '@GDM/Button';
import { formatSystemDate } from '@utils/formatters';
import { ContractSubPeriodForm, ContractType, PhaseType } from '@utils/types/contract';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { CONTRACT_TYPES_WITH_SPECIAL_PHASES } from '../../../builder/config';
import { useContractForm, useSubPeriods } from '../../../hooks';
import { makeSubPeriod } from '../helpers';
import styles from './sub-period-actions.module.scss';

export const SubPeriodActions: React.FC = () => {
  const { watch, trigger } = useContractForm();
  const { subPeriods } = useSubPeriods();

  const [contractType, start_date, end_date, contract_sub_periods_attributes] = watch([
    'type',
    'start_date',
    'end_date',
    'contract_sub_periods_attributes',
  ]);

  const showSpecialPhaseButton = useMemo(
    () => CONTRACT_TYPES_WITH_SPECIAL_PHASES.includes(contractType as ContractType),
    [contractType],
  );

  const addSubPeriod = async (phase_type?: PhaseType): Promise<void> => {
    let end = formatSystemDate(end_date);
    let start = formatSystemDate(start_date);
    let price_model_attributes: ContractSubPeriodForm['price_model_attributes'] = null;

    if (contract_sub_periods_attributes.length) {
      const lastPeriod = contract_sub_periods_attributes.at(-1);

      const dayAfterLastPeriodEnd = dayjs(lastPeriod?.dates?.end_date).add(1, 'day').toDate();
      const dayBeforeFirstPeriodStart = dayjs(contract_sub_periods_attributes[0].dates?.start_date)
        .subtract(1, 'day')
        .toDate();

      if (phase_type) {
        end = formatSystemDate(dayBeforeFirstPeriodStart);
      } else {
        start = formatSystemDate(dayAfterLastPeriodEnd);
      }

      if (lastPeriod) price_model_attributes = lastPeriod.price_model_attributes;
    }

    if (!contractType) return;

    const period = makeSubPeriod(contractType, {
      start_date: start,
      end_date: end,
      phase_type,
      price_model_attributes,
    });

    if (phase_type) {
      subPeriods?.prepend(period);
    } else {
      subPeriods?.append(period);
    }

    // This makes sure the validation is triggered in the next loop, which is needed to make
    // sure the new sub-period dates are validated.
    if (await trigger('contract_sub_periods_attributes')) trigger('contract_sub_periods_attributes');
  };

  return (
    <div className={classNames('p-3', styles.wrapper)}>
      {showSpecialPhaseButton && (
        <Button
          size="sm"
          variant="outline"
          text="sales_management.add_test_period"
          onClick={() => addSubPeriod('testing')}
        />
      )}
      <Button
        size="sm"
        variant="primary-2"
        text="sales_management.add_period"
        onClick={() => addSubPeriod()}
        data-cy="add-period"
      />
    </div>
  );
};
