import React from 'react';
import { sectionStyles } from '@GDM/forms';
import classNames from 'classnames';
import { ContractSection } from '../../components';
import { useContractFields } from '../../hooks';
import { CredentialIdPicker } from './CredentialIdPicker';
import { EdfAgencyIdPicker } from './EdfAgencyIdPicker';
import { InstallationSiret } from './InstallationSiret';
import { ToggleCegedim } from './ToggleCegedim';

export const InvoiceSettings: React.FC = () => {
  const { hasFields } = useContractFields();

  return (
    <ContractSection className="p-3">
      {hasFields('edf_agency_id') && (
        <div className={sectionStyles.row}>
          <EdfAgencyIdPicker />
        </div>
      )}

      <div className={hasFields('edf_agency_id') ? classNames(sectionStyles['section-footer'], 'pt-4') : undefined}>
        {
          // We want to show Cegedim toggle even if there is no cegedim credentials to let clients know this option
          hasFields('invoicing_platform') && (
            <div className={sectionStyles.row}>
              <ToggleCegedim />
            </div>
          )
        }
        <div className={sectionStyles.row}>
          {hasFields('credential_id') && <CredentialIdPicker />}
          {hasFields('_non_contract_props.installation.siret') && <InstallationSiret />}
        </div>
      </div>
    </ContractSection>
  );
};
