import { useContext } from 'react';
import { hedgeBlocksContext } from '../../context';
import { HedgeBlocksContext } from '../../contracts.types';

export const useHedgeBlocks = (): Omit<HedgeBlocksContext, 'setCollapsed'> & {
  isCollapsed?: (id: string) => boolean;
  toggleBlock?: (id: string) => void;
  collapseAll?: () => void;
  unCollapseAll?: () => void;
} => {
  const context = useContext(hedgeBlocksContext);

  const collapseAll = (): void => {
    context.setCollapsed?.(context.hedgeBlocks?.fields.map((p) => p.field_internal_id));
  };

  const unCollapseAll = (): void => {
    context.setCollapsed?.([]);
  };

  const toggleBlock = (id: string): void => {
    context.setCollapsed?.((currentList) => {
      const isCollapsed = currentList?.includes(id);

      return isCollapsed ? currentList?.filter((i) => i !== id) : [...(currentList ?? []), id];
    });
  };

  const isCollapsed = (id: string): boolean => {
    return context.collapsed?.includes(id) || false;
  };

  return {
    ...context,
    isCollapsed,
    toggleBlock,
    collapseAll,
    unCollapseAll,
  };
};
