import React from 'react';
import { Input } from '@GDM/forms';
import { Text } from '@GDM/Text';
import { URL } from '@utils/regexes';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const ExternalPdfLink: React.FC = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  return (
    <Controller
      name="external_pdf_link"
      control={control}
      rules={{ ...fieldRules('external_pdf_link'), pattern: URL }}
      render={({ field, fieldState }) => (
        <>
          <Input
            {...field}
            label="sales_management.external_pdf_link"
            size="lg"
            value={field.value ?? ''}
            hasError={!!fieldState.error}
            tooltip={fieldState?.error?.message}
            data-cy="pdf-link"
            readOnly={readonly}
            full
          />
          {fieldState.error && (
            <Text
              className="mt-1"
              size="sm"
              text={fieldState.error?.message || fieldState.error?.type === 'pattern' ? 'errors.pattern.url' : ''}
              type="danger"
            />
          )}
        </>
      )}
    />
  );
};
