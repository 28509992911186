import React from 'react';
import { useUser } from '@context/User.context';
import { AltContent } from '@GDM/AltContent';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import { formatCurrency } from '@utils/formatters';
import { appendColon, useCurrency } from '@utils/string';
import { dateFormat } from '../helpers';
import { useContractCaps } from '../useContractCaps';

export const CreditNoteChart: React.FC = () => {
  const { invoices } = useContractCaps();
  const { t } = useTranslation();
  const { locale } = useUser();
  const currency = useCurrency('code');
  const filteredInvoices = invoices?.filter((d) => d.contract_type === 'ContractCrEdf');

  const categories = Array.from(
    new Set(
      filteredInvoices
        ?.map((i) => i.issued_at_estimation)
        .sort()
        .map(dateFormat),
    ),
  );

  const data = categories.map((category) => {
    const invoice = filteredInvoices?.find((d) => category === dateFormat(d.issued_at_estimation));

    return invoice?.credit_note_cap || null;
  });

  const color = 'var(--chart-purple)';

  const series: Highcharts.Options['series'] = [
    {
      name: t('common.credit_note_cap'),
      data,
      type: 'column',
      color,
      tooltip: {
        pointFormatter() {
          return `<span style="color: ${color}">●</span> ${appendColon(
            t('common.credit_note_cap_tooltip'),
            locale,
          )} <b>${formatCurrency(this.y, locale, 'compact', { currency })}</b>`;
        },
      },
    },
  ];

  const options: Highcharts.Options = {
    xAxis: { categories },
    yAxis: {
      title: { text: `${t('common.credit_note_cap')} (€)` },
      opposite: true,
    },
  };

  if (!filteredInvoices || filteredInvoices?.length === 0 || !data || data.length === 0)
    return <AltContent label="common.credit_note_cap_no_data" />;

  return <Chart series={series} options={options} hideLegend />;
};
