import React, { useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { SubPeriodsContext } from '../../contracts.types';
import { useContractForm } from '../../Form/hooks';
import { subPeriodsContext } from './sub-periods.context';

export const SubPeriodsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { control } = useContractForm();
  const [collapsedIds, setCollapsedIds] = useState<string[] | undefined>([]);

  const subPeriods = useFieldArray({
    control: control,
    name: 'contract_sub_periods_attributes',
    keyName: 'field_internal_id',
    rules: { required: true },
  });

  const subPeriodContextValue: SubPeriodsContext = useMemo(
    () => ({
      subPeriods,
      collapsed: collapsedIds,
      setCollapsed: setCollapsedIds,
    }),
    [collapsedIds, subPeriods],
  );

  return <subPeriodsContext.Provider value={subPeriodContextValue}>{children}</subPeriodsContext.Provider>;
};
