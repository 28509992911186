import React from 'react';
import { Button } from '@GDM/Button';
import { SectionHeader } from '../../../components';
import { useSubPeriods } from '../../../hooks';

export const SubPeriodHeaderActions: React.FC = () => {
  const { collapseAll, unCollapseAll, collapsed, subPeriods } = useSubPeriods();

  const allAreCollapsed = collapsed?.length === subPeriods?.fields.length;
  const noneAreCollapsed = collapsed?.length === 0;

  if (subPeriods?.fields && subPeriods.fields.length < 1) return null;

  return (
    <SectionHeader>
      <Button
        tooltip="common.un_collapse_all"
        icon="ChevronsDown"
        floating
        onClick={unCollapseAll}
        disabled={noneAreCollapsed}
      />
      <Button
        tooltip="common.collapse_all"
        className="ml-1"
        icon="ChevronsUp"
        floating
        onClick={collapseAll}
        disabled={allAreCollapsed}
      />
    </SectionHeader>
  );
};
