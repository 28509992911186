import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';

export const CrEdfFields: ContractFormField[] = [
  {
    name: 'dispositif',
    required: true,
    info: { title: 'sales_management.dispositif', text: 'contracts.info.dispositif' },
  },
  {
    name: 'sub_type',
    required: true,
    info: { title: 'sales_management.sub_type_select', text: 'contracts.info.sub_type' },
  },
  { name: 'index_formula', required: true },
  {
    name: 'annual_cap',
    info: { title: 'sales_management.annual_cap', text: 'contracts.info.annual_cap' },
    requiredForFieldValues: {
      sub_type: [
        'cr17',
        'E17_V1.0.1',
        'E17_V1.0.2',
        'E17_V2',
        'FSE17CR',
        'FSI_17_CR',
        'FV16SCR_V01',
        'FV16SCR_V2.0.0',
        'FV16SCR_V2.0.1',
      ],
    },
  },
  { name: 'p_max', configurable: true },
  { name: 'financing', required: true },
  { name: 'contract_sub_periods_attributes.0.display_price', required: true },
  { name: 'invoicing_platform', required: false },
  {
    name: 'credential_id',
    required: false,
    disabledForFieldValues: [{ field: 'invoicing_platform', excludeValue: 'cegedim' }],
  },
  { name: 'edf_agency_id', required: false },
  {
    name: '_non_contract_props.installation.siret',
    required: false,
    onlyForCountries: ['FR'],
    info: { title: 'sales_management.installation_siret', text: 'sales_management.cegedim_siret_info' },
    disabledForFieldValues: [{ field: 'invoicing_platform', excludeValue: 'cegedim' }],
  },
  { name: 'external_pdf_link', required: false, permission: 'display:non_engie' },
  { name: 'sent_to_remit' },
  { name: 'above_cap_price' },
  { name: 'is_indexed' },
];
