import React, { useMemo } from 'react';
import { Select } from '@GDM/forms';
import { useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { Option } from '@utils/types/common-types';
import { PhaseType } from '@utils/types/contract';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import styles from './phase-type-picker.module.scss';

export const PhaseTypePicker: React.FC<{ index: number }> = ({ index }) => {
  const { control, readonly } = useContractForm();

  const options: Option<PhaseType>[] = useMemo(
    () => [
      { value: 'testing', label: 'sales_management.phases.testing' },
      { value: 'intermediate', label: 'sales_management.phases.intermediate' },
    ],
    [],
  );

  return (
    <Controller
      name={`contract_sub_periods_attributes.${index}.phase_type`}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <Select
          {...field}
          label="sales_management.phases.select_one"
          size="lg"
          options={options}
          className={classNames(styles.picker, 'fit')}
          selectedOption={field.value}
          onChange={(option) => field.onChange(option?.value)}
          readOnly={readonly}
        />
      )}
    />
  );
};
