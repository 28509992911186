import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { ContractSection } from '../../components';
import { useContractFields } from '../../hooks';
import { CalcBasePicker } from './CalcBasePicker';
import { CrInvoicingTypePicker } from './CrInvoicingTypePicker';
import { CutTimePicker } from './CutTimePicker';
import { InvoicingPeriodPicker } from './InvoicingPeriodPicker';
import { ParticipativeFinancing } from './ParticipativeFinancing';
import { PowerAugmentationPercentage } from './PowerAugmentationPercentage';
import { PowerAugmentationStartDate } from './PowerAugmentationStartDate';
import { PowerAugmentationToggle } from './PowerAugmentationToggle';
import { SchemePicker } from './SchemePicker';
import { SubTypePicker } from './SubTypePicker';

export const ContractParams: React.FC = () => {
  const { hasFields } = useContractFields();

  return (
    <ContractSection>
      <div className="p-3">
        <div className={sectionStyles.row}>
          {hasFields('dispositif') && <SchemePicker />}
          {hasFields('sub_type') && <SubTypePicker />}
        </div>
        <div className={sectionStyles.row}>
          {hasFields('calc_base') && <CalcBasePicker />}
          {hasFields('cut_time') && <CutTimePicker />}
          {hasFields('invoicing_period') && <InvoicingPeriodPicker />}
          {hasFields('financing') && <ParticipativeFinancing />}
        </div>
        <div className={sectionStyles.row}>{hasFields('cr_facturation_type') && <CrInvoicingTypePicker />}</div>
      </div>
      <div className={sectionStyles['section-footer']}>
        <div className="p-3 pb-0">
          <div className={sectionStyles.row}>{hasFields('power_augmentation') && <PowerAugmentationToggle />}</div>
          <div className={sectionStyles.row}>
            {hasFields('power_augmentation_start_date') && <PowerAugmentationStartDate />}
            {hasFields('power_augmentation_percentage') && <PowerAugmentationPercentage />}
          </div>
        </div>
      </div>
    </ContractSection>
  );
};
