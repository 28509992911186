import { ContractFormField } from '../../contracts.types';

/**
 * Used to update section progress.
 */
export function valueIsRequired(
  field: ContractFormField['name'] | null,
  requiredFields: ContractFormField['name'][],
): boolean {
  if (!field) return true;

  const requiredPrefixes = requiredFields.map((f) => f.split('.')[0]);

  // `field` is undefined when we reset the form (e.g when editing)
  // So we need to check for `undefined` to get the right progress value when first arriving
  // on the [:id]/edit page.
  // When adding a sub-period, only the `fieldArray` name is triggered, but we also want to include
  // this, even if it's not explicitly in the list.
  if (requiredFields.includes(field) || requiredPrefixes.includes(field)) return true;

  // If the field isn't immediately recognised, then it might be a sub-field in a `fieldArray`,
  // like a field in the sub-periods list for example. In that particular case, only
  // `contract_sub_periods_attributes.0.{prop_name}` will be specified, but we still want to check for
  // any element in that list `contract_sub_periods_attributes.1...`, `contract_sub_periods_attributes.2...`
  const currentFieldPrefix = field.split('.')?.[0];

  return requiredPrefixes.includes(currentFieldPrefix);
}
