import React from 'react';
import { Badge } from '@GDM/Badge';
import { Icon, OtherAvailableFlags } from '@GDM/Icon';
import { iconDictionary } from '@utils/constants';
import { COUNTRY_CODES } from '@utils/constants/countries';
import { EnergyType, Option } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries';
import type Installation from '@utils/types/installation';
import classNames from 'classnames';
import styles from './installation-picker.module.scss';

export const FormattedOption = (
  data: Option & { country?: CountryCode; energy?: EnergyType; status?: Installation['status'] },
): JSX.Element => {
  const country = data.country;
  const validCountries = COUNTRY_CODES;
  const countryIcon = country && validCountries.includes(country) ? country : OtherAvailableFlags.XX;

  return (
    <div className={styles.container} title={data.label}>
      <Icon name={countryIcon} size={12} className="mr-2" />
      <Icon name={iconDictionary[data.energy || 'other']} size={14} />
      <span className={classNames(styles.label, 'ml-2')}>{data.label}</span>
      {data.status === 'draft' && <Badge className="ml-2" label="common.draft" variant="danger" />}
    </div>
  );
};
