import { useMemo } from 'react';
import { useContractForm, useSubPeriods } from '@pages/Contracts/Contract/Form/hooks';
import { ContractSubPeriodForm } from '@utils/types/contract';
import dayjs from 'dayjs';

export function useValidation(index: number) {
  const { getValues } = useContractForm();
  const { subPeriods } = useSubPeriods();

  return useMemo(
    () => ({
      noValues: (dates: ContractSubPeriodForm['dates']) => {
        return !!(dates?.start_date && dates?.end_date) || 'sales_management.sub_period_errors.no_values';
      },
      overlapping: (dates: ContractSubPeriodForm['dates']) => {
        const nextSubPeriod = getValues('contract_sub_periods_attributes')?.[index + 1];
        if (!nextSubPeriod) return true;

        const endDate = dayjs(dates?.end_date).endOf('day').toDate().valueOf();
        const nextPeriodStartDate = dayjs(nextSubPeriod.dates?.start_date).startOf('day').toDate().valueOf();

        return endDate < nextPeriodStartDate || 'sales_management.sub_period_errors.ends_after_next_starts';
      },
      gapBetweenPeriods: (dates: ContractSubPeriodForm['dates']) => {
        const nextSubPeriod = getValues('contract_sub_periods_attributes')?.[index + 1];
        if (!nextSubPeriod) return true;

        const expectedStartOfNextPeriod = dayjs(dates?.end_date).add(1, 'day').startOf('day').toDate().valueOf();
        const actualStartOfNextPeriod = dayjs(nextSubPeriod.dates?.start_date).startOf('day').toDate().valueOf();

        return (
          expectedStartOfNextPeriod === actualStartOfNextPeriod ||
          'sales_management.sub_period_errors.missing_time_between_this_period_and_next'
        );
      },
      beforeStartDate: (dates: ContractSubPeriodForm['dates']) => {
        const contractDates = getValues(['start_date']);

        // Make sure we have exact matches for the dates (could be same day but different hours)
        const startDate = dayjs(dates?.start_date).startOf('day').toDate().valueOf();
        const contractStartDate = dayjs(contractDates[0]).startOf('day').toDate().valueOf();

        return startDate >= contractStartDate || 'sales_management.sub_period_errors.before_start_date';
      },
      afterEndDate: (dates: ContractSubPeriodForm['dates']) => {
        const contractDates = getValues(['end_date']);

        // Make sure we have exact matches for the dates (could be same day but different hours)
        const endDate = dayjs(dates?.end_date).endOf('day').toDate();
        const contractEndDate = dayjs(contractDates[0]).endOf('day').toDate();

        return endDate <= contractEndDate || 'sales_management.sub_period_errors.after_end_date';
      },
      outOfBounds: (dates: ContractSubPeriodForm['dates']) => {
        // Make sure we have exact matches for the dates (could be same day but different hours)
        const startDate = dayjs(dates?.start_date).startOf('day').toDate();
        const endDate = dayjs(dates?.end_date).endOf('day').toDate();

        return startDate < endDate || 'sales_management.sub_period_errors.out_of_bounds';
      },
      timeMissingAtStart: (dates: ContractSubPeriodForm['dates']) => {
        if (index > 0) return true;

        const contractDates = getValues(['start_date']);
        const startDate = dayjs(dates?.start_date).startOf('day').toDate().valueOf();
        const contractStartDate = dayjs(contractDates[0]).startOf('day').toDate().valueOf();

        return startDate === contractStartDate || 'sales_management.sub_period_errors.missing_time_at_beginning';
      },
      timeMissingAtEnd: (dates: ContractSubPeriodForm['dates']) => {
        if (subPeriods?.fields && index !== subPeriods.fields.length - 1) return true;

        const contractDates = getValues(['end_date']);
        const endDate = dayjs(dates?.end_date).endOf('day').toDate().valueOf();
        const contractEndDate = dayjs(contractDates[0]).endOf('day').toDate().valueOf();

        return endDate === contractEndDate || 'sales_management.sub_period_errors.missing_time_at_end';
      },
    }),
    [getValues, index, subPeriods?.fields],
  );
}
