import { ContractFormField, FormSection } from '@pages/Contracts/Contract/contracts.types';
import { ContractType } from '@utils/types/contract';

/**
 * Defines which fields belong to which section.
 * Due to the way `react-form-hook` is typed, for sub-period fields (`fieldArray` fields),
 * we have to specify the first element to match the type, _e.g_:
 * ```ts
 * // This actually targets any one of the fields `multiplier` value.
 * ['contract_sub_periods_attributes.0.multiplier', 'sub-periods'],
 * ```
 * For the builder to consider the prop as an actual `fieldArray` sub-prop, then the field name `contract_sub_periods_attributes`
 * has to be in the required field list. This is because for some contract types (OA and CR-EDF), there is a
 * 'ghost' sub-period that is required to set specific props (_e.g._ `display_price`), where we don't
 * want the validator to validate as if it were an 'actual' sub-period.
 *
 * In this `Map`, you have to possibility to set the section any field is in, or specify a sub-map keyed to contract types
 * if the property changes section depending on the contract type.
 */
export const FIELD_SECTIONS = new Map<
  ContractFormField['name'],
  FormSection | Partial<Record<ContractType | 'default', FormSection>>
>([
  ['contract_nb', 'general-information'],
  ['installation_uuid', 'general-information'],
  ['buyer_id', 'general-information'],
  ['seller_id', 'general-information'],
  ['start_date', 'general-information'],
  ['end_date', 'general-information'],
  ['type', 'general-information'],
  ['erp_code', 'general-information'],
  ['card_i', 'general-information'],
  ['signature_date', 'general-information'],
  ['currency', 'general-information'],
  ['balancing_responsible_party_id', { ContractCppa: 'general-information' }],
  ['additional_buyer_id', { ContractCppa: 'general-information' }],
  ['include_vat', { ContractCppa: 'taxes' }],
  ['aggregation_fee', 'pricing'],
  ['annual_cap', 'cap'],
  ['above_cap_price', 'pricing'],
  ['is_indexed', 'pricing'],
  [
    'birthday_date',
    { ContractSoa: 'sub-periods', ContractCppa: 'sub-periods', ContractCapaProxy: 'sub-periods', default: 'pricing' },
  ],
  ['calc_base', 'contract-params'],
  [
    'contract_sub_periods_attributes.0.display_price',
    { ContractOa: 'pricing', ContractCrEdf: 'pricing', default: 'sub-periods' },
  ],
  ['contract_sub_periods_attributes', 'sub-periods'],
  ['contract_sub_periods_attributes.0.dates', 'sub-periods'],
  ['contract_sub_periods_attributes.0.aggreg_fees', 'sub-periods'],
  ['contract_sub_periods_attributes.0.aggreg_fees.0.value', 'sub-periods'],
  ['contract_sub_periods_attributes.0.aggreg_fees.0.type', 'sub-periods'],
  ['contract_sub_periods_attributes.0.multiplier', 'sub-periods'],
  ['contract_sub_periods_attributes.0.price_rule', 'sub-periods'],
  ['contract_sub_periods_attributes.0.price_type', 'sub-periods'],
  ['contract_sub_periods_attributes.0.price_model_attributes.area', 'sub-periods'],
  ['contract_sub_periods_attributes.0.price_model_attributes.source', 'sub-periods'],
  ['contract_sub_periods_attributes.0.price_model_attributes.price_type', 'sub-periods'],
  ['contract_sub_periods_attributes.0.extra_params.swap_fixed_price', 'sub-periods'],
  ['contract_sub_periods_attributes.0.extra_params.swap_price_type', 'sub-periods'],
  ['contract_sub_periods_attributes.0.extra_params.swap_scale_factor', 'sub-periods'],
  ['contract_sub_periods_attributes.0.market_future', 'sub-periods'],
  ['contract_sub_periods_attributes.0.market_future_id', 'sub-periods'],
  ['contract_sub_periods_attributes.0.volume_type', 'sub-periods'],
  ['contract_sub_periods_attributes.0.profile_hedge_attributes.period', 'sub-periods'],
  ['contract_sub_periods_attributes.0.profile_hedge_attributes.profile_hedge_caps_attributes', 'sub-periods'],
  ['contract_sub_periods_attributes.0.profile_hedge_attributes.profile_hedge_caps_attributes.0.cap', 'sub-periods'],
  ['contract_sub_periods_attributes.0.profile_hedge_attributes.price_above_cap_type', 'sub-periods'],
  ['contract_sub_periods_attributes.0.neg_price_rule', 'sub-periods'],
  ['cr_facturation_type', 'contract-params'],
  ['cut_time', 'contract-params'],
  ['dispositif', 'contract-params'],
  ['financing', 'contract-params'],
  ['hedge_blocks_attributes', 'hedge-blocks'],
  ['hedge_blocks_attributes.0.dates', 'hedge-blocks'],
  ['hedge_blocks_attributes.0.average_power', 'hedge-blocks'],
  ['hedge_blocks_attributes.0.price', 'hedge-blocks'],
  ['hedge_contract.spot_fee', 'hedge-blocks'],
  [
    'index_formula',
    { ContractSoa: 'sub-periods', ContractCppa: 'sub-periods', ContractCapaProxy: 'sub-periods', default: 'pricing' },
  ],
  ['invoicing_period', 'contract-params'],
  ['labour_cost_insee_index_ref', 'pricing'],
  ['ncc', 'pricing'],
  ['p_max', { ContractOa: 'cap', default: 'general-information' }],
  ['producer_price_insee_index_ref', 'pricing'],
  [
    'rate_change',
    { ContractSoa: 'sub-periods', ContractCppa: 'sub-periods', ContractCapaProxy: 'sub-periods', default: 'pricing' },
  ],
  ['sub_type', 'contract-params'],
  ['sent_to_remit', 'remit'],
  ['invoicing_platform', 'invoicing-settings'],
  ['credential_id', 'invoicing-settings'],
  ['edf_agency_id', 'invoicing-settings'],
  ['external_pdf_link', 'additional-info'],
  ['_non_contract_props.installation.siret', 'invoicing-settings'],
  ['_non_contract_props.installation.external_ref', 'general-information'],
  ['_non_contract_props.installation.owner_erp_code', 'general-information'],
  ['energy', 'general-information'],
  ['power_augmentation', 'contract-params'],
  ['power_augmentation_percentage', 'contract-params'],
  ['power_augmentation_start_date', 'contract-params'],
]);
