import React from 'react';
import { Alert } from '@GDM/Alert';
import { FormErrors } from '@GDM/FormErrors';
import { useContractForm, useContractRequest } from '../Form/hooks';

export const FormAlerts: React.FC = () => {
  const { errors, wasSuccessful } = useContractRequest();
  const { isEditing } = useContractForm();

  if (wasSuccessful)
    return (
      <Alert
        icon="Info"
        label={
          isEditing
            ? 'sales_management.info.contract_successfully_updated'
            : 'sales_management.info.contract_successfully_created'
        }
        dismissible
      />
    );

  if (errors?.length) {
    return <FormErrors errors={errors} title="sales_management.form_request_has_errors" />;
  }

  return null;
};
