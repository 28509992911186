import React from 'react';
import { Input } from '@GDM/forms';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const IndexRefLabour: React.FC = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  return (
    <Controller
      name="labour_cost_insee_index_ref"
      control={control}
      rules={fieldRules('labour_cost_insee_index_ref')}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          label="sales_management.index_ref_work"
          size="lg"
          type="number"
          value={field.value ?? ''}
          hasError={!!fieldState.error}
          data-cy="index-ref-labour"
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
