import React, { useMemo, useState } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { Select } from '@GDM/forms';
import { HelpBox } from '@GDM/HelpBox';
import { Text } from '@GDM/Text';
import { Toggle } from '@GDM/Toggle';
import { contractTypeList } from '@utils/constants';
import { ContractForm, ContractType } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import classNames from 'classnames';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';
import { useValidateUniquenessRequest } from '../../../hooks/useValidateUniquenessRequest';
import { CONTRACT_TYPE_HELP_BOXES } from './contract-type-help-boxes';
import styles from './contract-type-picker.module.scss';

export const ContractTypePicker: React.FC = () => {
  const { installations } = useInstallationsContext();
  const { control, watch, resetFields, isEditing, readonly } = useContractForm();
  const { fieldRules } = useContractFields();
  const { validate, loading: isValidating } = useValidateUniquenessRequest();
  const [enabled, setEnabled] = useState<boolean | undefined>(!isEditing);

  const installationUuid = watch('installation_uuid');
  const installation = installations?.find(({ uuid }) => uuid === installationUuid);
  const installationCountry: CountryCode = installation?.country || 'FR';
  const isGLSFInstallation =
    typeof installation?.has_meter === 'boolean' && installation.status !== 'draft' ? !installation.has_meter : null;

  const options = useMemo(
    () => contractTypeList[isGLSFInstallation ? 'GLSF' : installationCountry] || [],
    [installationCountry, isGLSFInstallation],
  );

  const onChange = (field: ControllerRenderProps<ContractForm, 'type'>, value?: ContractType): void => {
    field.onChange(value);
    resetFields();
    validate();
  };

  return (
    <Controller
      name="type"
      control={control}
      rules={fieldRules('type')}
      render={({ field, fieldState }) => (
        <>
          <div className={styles.wrapper}>
            {isEditing && (
              <Toggle
                value={enabled}
                onChange={setEnabled}
                tooltip="sales_management.info.contract_type_change"
                className={styles.toggle}
              />
            )}
            <Select
              {...field}
              label="sales_management.contract_type"
              size="lg"
              options={options}
              menuPlacement="top"
              selectedOption={field.value}
              className={styles.select}
              onChange={(option) => onChange(field, option?.value)}
              isDisabled={!installationUuid || !enabled}
              tooltip={field.value && !readonly ? 'sales_management.info.will_reset_sub_periods' : undefined}
              classNamePrefix="select-contract-type"
              readOnly={readonly}
              hasError={!!fieldState.error}
              isLoading={isValidating}
              isSearchable
            />
          </div>
          {!readonly && fieldState?.error && (
            <Text
              type="danger"
              text={fieldState?.error?.message}
              size="sm"
              className={classNames('text-small', 'mb-3', styles.error)}
            />
          )}
          {!readonly && field?.value && CONTRACT_TYPE_HELP_BOXES[field?.value] && (
            <HelpBox text={CONTRACT_TYPE_HELP_BOXES[field.value]} />
          )}
        </>
      )}
    />
  );
};
