import React, { useCallback, useMemo } from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { ContractForm } from '@utils/types/contract';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { getSections } from '../../../builder';
import { useContractFields, useContractForm, useSubPeriods } from '../../../hooks';
import { useValidateUniquenessRequest } from '../../../hooks/useValidateUniquenessRequest';

export const EndDatePicker: React.FC = () => {
  const { control, watch, trigger, setValue, readonly } = useContractForm();
  const { fieldRules } = useContractFields();
  const { subPeriods } = useSubPeriods();
  const { validate } = useValidateUniquenessRequest();
  const [startDate, contractType] = watch(['start_date', 'type']);

  const cannotEditSubPeriods = useMemo(
    () => contractType && !getSections(contractType).includes('sub-periods'),
    [contractType],
  );

  const onChange = useCallback(
    (value: Date | null, field: ControllerRenderProps<ContractForm, 'end_date'>) => {
      field.onChange(value);
      trigger(['contract_sub_periods_attributes', 'hedge_blocks_attributes', 'start_date']);

      // Check uniqueness
      validate();

      // If we're editing a contract with no visible sub-periods, then we have to
      // make sure that the invisible sub-period dates are updated too.
      // When the sub-period is visible, we just show an error instead.
      if (value && cannotEditSubPeriods && subPeriods?.fields?.length === 1)
        setValue('contract_sub_periods_attributes.0.dates.end_date', value);
    },
    [cannotEditSubPeriods, setValue, subPeriods?.fields?.length, trigger, validate],
  );

  return (
    <Controller
      name="end_date"
      control={control}
      rules={{ ...fieldRules('end_date'), validate: (value) => value > startDate }}
      render={({ field, fieldState }) => (
        <DatePicker
          {...field}
          label="common.end_date"
          size="lg"
          onChange={(date) => onChange(date, field)}
          selected={field.value}
          hasError={!!fieldState.error}
          data-cy="contract-end-date"
          minDate={startDate}
          readOnly={readonly}
        />
      )}
    />
  );
};
