import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Select } from '@GDM/forms';
import { getSymbol } from '@utils/currency/getSymbol';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const CurrencyPicker = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();
  const { currencies } = useUser();

  const options = useMemo(() => {
    return currencies
      .map((currency) => {
        const symbol = getSymbol(currency);
        const label = currency !== symbol ? `${currency} (${symbol})` : currency;

        return { value: currency, label };
      })
      .sort(sortOptionsByLabelAsc);
  }, [currencies]);

  return (
    <Controller
      name="currency"
      control={control}
      rules={{ ...fieldRules('currency') }}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.currency"
          size="lg"
          selectedOption={field.value}
          hasError={!!fieldState.error}
          onChange={(option) => {
            field.onChange(option?.value);
          }}
          tooltip={fieldState?.error?.message}
          options={options}
          classNamePrefix="contract-currency"
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
