import { COUNTRY_CODES } from '@utils/constants/countries';
import { InstallationFormField } from '../installation.types';

const everyCountriesExceptFrance = COUNTRY_CODES.filter((country) => country !== 'FR');

export const COMMON_FIELDS: InstallationFormField[] = [
  { name: 'name', required: true },
  { name: 'country', required: true },
  { name: 'energy', required: true, defaultValue: 'other' },
  { name: 'company_number', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  { name: 'bank', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  { name: 'erp_code', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  { name: 'swift_bic', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  { name: 'invoicing_emails', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  { name: 'iban', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  {
    name: 'short_name',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    requiredForFieldValues: { owner_id: 'new_owner' },
    info: {
      title: 'owner.short_name',
      text: 'admin.installations.owner_short_name_info',
    },
    onlyForCountries: everyCountriesExceptFrance,
  },
  {
    name: 'long_name',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    requiredForFieldValues: { owner_id: 'new_owner' },
    onlyForCountries: everyCountriesExceptFrance,
  },
  {
    name: 'street_address',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    requiredForFieldValues: { owner_id: 'new_owner' },
    onlyForCountries: everyCountriesExceptFrance,
  },
  {
    name: 'postcode',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    requiredForFieldValues: { owner_id: 'new_owner' },
    onlyForCountries: everyCountriesExceptFrance,
  },
  {
    name: 'city',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    onlyForCountries: everyCountriesExceptFrance,
  },
  {
    name: 'vat_number',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    requiredForFieldValues: { owner_id: 'new_owner' },
    onlyForCountries: everyCountriesExceptFrance,
  },
  { name: 'owner_id', required: true },
  { name: 'prm', onlyForCountries: ['FR'], required: true },
  { name: 'mandate_uuid', onlyForCountries: ['FR'], required: true },
  {
    name: 'aiguillage',
    onlyForCountries: ['FR'],
    requiredForFieldValues: { boitier_ip: false },
    onlyForFieldValues: [{ field: 'boitier_ip', value: false }],
  },
  {
    name: 'typecompteur',
    onlyForCountries: ['FR'],
    requiredForFieldValues: { boitier_ip: false },
    onlyForFieldValues: [{ field: 'boitier_ip', value: false }],
  },
  { name: 'producteur', onlyForCountries: ['FR'] },
  { name: 'meter_pass_address', onlyForCountries: ['ES'], required: true },
  {
    name: 'ip',
    onlyForCountries: ['ES'],
    onlyForFieldValues: [{ field: 'boitier_ip', value: true }],
    requiredForFieldValues: { boitier_ip: true },
  },
  {
    name: 'tcp_port',
    onlyForCountries: ['ES'],
    onlyForFieldValues: [{ field: 'boitier_ip', value: true }],
    requiredForFieldValues: { boitier_ip: true },
  },
  { name: 'code', onlyForCountries: ['ES'], required: true },
  { name: 'key', onlyForCountries: ['ES', 'IT'], required: true },
  {
    name: 'key',
    requiredForFieldValues: { boitier_ip: false },
    onlyForFieldValues: [{ field: 'boitier_ip', value: false }],
    onlyForCountries: ['FR'],
  },
  { name: 'model', onlyForCountries: ['IT'], required: true },
  { name: 'serial_number', onlyForCountries: ['IT'] },
  {
    name: 'numero',
    requiredForFieldValues: { boitier_ip: false },
    onlyForFieldValues: [{ field: 'boitier_ip', value: false }],
  },
  { name: 'numero', required: true, onlyForCountries: ['IT'] },
  { name: 'boitier_ip', defaultValue: false, onlyForCountries: ['FR', 'ES'] },
];
