import React from 'react';
import { Input, useField } from '@GDM/forms';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { Controller } from 'react-hook-form';

export const SerialNumberInput = () => {
  const { rules, disabled } = useField('serial_number');
  const form = useInstallationForm();

  return (
    <Controller
      name="serial_number"
      control={form.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          label="admin.installations.meter_serial_nb"
          size="lg"
          full
          data-cy="meter-serial_number"
          disabled={disabled}
          hasError={!!fieldState.error}
          errorMessage={fieldState.error?.message}
        />
      )}
    />
  );
};
