import React, { useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useContractForm } from '../../Form/hooks';
import { hedgeBlocksContext } from './hedge-blocks.context';

export const HedgeBlocksProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { control } = useContractForm();
  const [collapsedIds, setCollapsedIds] = useState<string[] | undefined>();

  const hedgeBlocks = useFieldArray({
    control: control,
    name: 'hedge_blocks_attributes',
    keyName: 'field_internal_id',
  });

  const contextValue = useMemo(
    () => ({
      hedgeBlocks,
      collapsed: collapsedIds,
      setCollapsed: setCollapsedIds,
    }),
    [collapsedIds, hedgeBlocks],
  );

  return <hedgeBlocksContext.Provider value={contextValue}>{children}</hedgeBlocksContext.Provider>;
};
