import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { InstallationWithMeterInfo as Installation } from '@utils/types/installation';
import { Mandate } from '@utils/types/mandate';
import { InstallationFiltersType } from '../InstallationsFilters';

export type InstallationsPageContext = {
  loading: boolean;

  installations: Installation[];
  filteredInstallations: Installation[];
  setFilteredInstallations: Dispatch<SetStateAction<Installation[]>>;
  selectedInstallation: Installation | null;
  setSelectedInstallation: (installation: Installation | null) => void;
  addInstallation: (installation: Installation) => void;
  addMeterInfo: (installationUuid: Installation['uuid'], meterInfo: Installation['meter']) => void;
  deleteInstallation: (installation: Installation) => void;

  mandates: Mandate[];
  addMandate: (mandate: Mandate) => void;
  filteredMandates: Mandate[];
  setFilteredMandates: Dispatch<SetStateAction<Mandate[]>>;

  filters: InstallationFiltersType;
  setFilters: Dispatch<SetStateAction<InstallationFiltersType>>;

  dataLoggerModalVisible: boolean;
  toggleDataLoggerModal: () => void;

  mandateModalVisible: boolean;
  toggleMandateModal: () => void;

  meterTypes: string[];
};
export const installationsPageContext = createContext<InstallationsPageContext>({
  loading: false,
  installations: [],
  filteredInstallations: [],
  setFilteredInstallations: () => null,
  selectedInstallation: null,
  setSelectedInstallation: () => null,
  addInstallation: () => null,
  addMeterInfo: () => null,
  deleteInstallation: () => null,

  mandates: [],
  addMandate: () => null,
  filteredMandates: [],
  setFilteredMandates: () => null,

  filters: { name: '', type: 'all', status: null },
  setFilters: () => null,

  dataLoggerModalVisible: false,
  toggleDataLoggerModal: () => null,

  mandateModalVisible: false,
  toggleMandateModal: () => null,

  meterTypes: [],
});
export const useInstallationsPageContext = () => useContext(installationsPageContext);
