import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const BirthdayDatePicker: React.FC = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  return (
    <Controller
      name="birthday_date"
      control={control}
      rules={fieldRules('birthday_date')}
      render={({ field, fieldState }) => (
        <DatePicker
          {...field}
          label="sales_management.commissioning_date"
          size="lg"
          tooltip={field.value && new Date(field.value) > new Date() ? 'sales_management.date_in_the_future' : null}
          onChange={(date) => field.onChange(date)}
          selected={field.value as Date}
          data-cy="birthday-date"
          hasError={!!fieldState.error}
          readOnly={readonly}
        />
      )}
    />
  );
};
