import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';

export const SOA_LIGHTFields: ContractFormField[] = [
  { name: 'contract_sub_periods_attributes.0.dates', required: true },
  {
    name: 'contract_sub_periods_attributes.0.display_price',
    requiredForFieldValues: { 'contract_sub_periods_attributes.0.price_type': 'fixed' },
    onlyForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_type', value: 'fixed' }],
  },
  { name: 'contract_sub_periods_attributes.0.price_type' },
  {
    name: 'contract_sub_periods_attributes.0.aggreg_fees',
    onlyForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_type', value: 'fixed' }],
  },
  { name: 'sent_to_remit' },
];
