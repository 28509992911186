import React from 'react';
import { Toggle } from '@GDM/Toggle';
import type { ContractForm } from '@utils/types/contract';
import omit from 'lodash/omit';
import { Controller, type ControllerRenderProps } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const PowerAugmentationToggle = () => {
  const { control, readonly, setValue, trigger, getValues } = useContractForm();
  const { fieldRules } = useContractFields();

  const handleChange = (field: ControllerRenderProps<ContractForm, 'power_augmentation'>) => (value?: boolean) => {
    /**
     * We reset values when the toggle is off as we don't want unwanted side effects
     * in case they would be sent to the backend.
     */
    if (!value) {
      setValue('power_augmentation_percentage', null);
      setValue('power_augmentation_start_date', null);
    } else {
      setValue('power_augmentation_start_date', getValues('start_date'));
    }

    field.onChange(value);
    trigger('contract_nb');
  };

  return (
    <Controller
      control={control}
      name="power_augmentation"
      rules={fieldRules('power_augmentation')}
      render={({ field }) => (
        <Toggle
          {...omit(field, 'ref')}
          onChange={handleChange(field)}
          readOnly={readonly}
          label="sales_management.power_augmentation"
        />
      )}
    />
  );
};
