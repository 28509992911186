import React from 'react';
import { Badge } from '@GDM/Badge';
import { Icon } from '@GDM/Icon';
import dayjs from 'dayjs';
import { useContractForm } from '../Form/hooks';
import styles from './page-subtitle.module.scss';

export const PageSubtitle: React.FC = () => {
  const { updatedAt, contractDisplayName, uuid, isDraft } = useContractForm();

  if (!uuid) return null;

  return (
    <>
      {isDraft && <Badge variant="danger" label="common.draft" className="mr-2" />}
      <span className={styles['contract-nb']}>{contractDisplayName}</span>
      {updatedAt && (
        <span className={styles['updated-at']}>
          <Icon name="Edit2" size={12} title="common.last_modification_on" className={styles.icon} />
          {dayjs(updatedAt).format('YYYY-MM-DD HH:mm')}
        </span>
      )}
    </>
  );
};
