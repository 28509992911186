import React from 'react';
import { Checkbox, CheckboxProps } from '@GDM/forms';
import { FieldValues, Path, Control, Controller, type PathValue } from 'react-hook-form';

export default function ControlledCheckbox<T extends FieldValues>({
  name,
  control,
  fieldDefaultChecked,
  ...checkboxProps
}: Omit<CheckboxProps, 'onChange' | 'checked'> & {
  name: Path<T>;
  control: Control<T>;
  fieldDefaultChecked?: PathValue<T, (string | undefined) & Path<T>>;
}) {
  return (
    <Controller
      defaultValue={fieldDefaultChecked}
      name={name}
      control={control}
      render={({ field }) => <Checkbox {...checkboxProps} onChange={field.onChange} checked={field.value} />}
    />
  );
}
