import React from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const InvoicingPeriodPicker: React.FC = () => {
  const { control, watch, readonly } = useContractForm();
  const { fieldRules } = useContractFields();
  const calcBase = watch('calc_base');

  const options: Option<ContractForm['invoicing_period']>[] = [
    { value: 'monthly', label: 'common.index_monthly' },
    { value: 'half_yearly', label: 'common.index_half_yearly' },
    { value: 'yearly', label: 'common.index_yearly' },
  ];

  return (
    <Controller
      name="invoicing_period"
      control={control}
      rules={fieldRules('invoicing_period')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="common.index_type"
          size="lg"
          options={options}
          selectedOption={field.value}
          onChange={(option) => field.onChange(option?.value)}
          isDisabled={!calcBase}
          classNamePrefix="select-frequency"
          readOnly={readonly}
          hasError={!!fieldState.error}
          isSearchable
        />
      )}
    />
  );
};
