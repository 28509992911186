import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';

export const CAPAFields: ContractFormField[] = [
  { name: 'p_max', configurable: true },
  { name: 'ncc', required: true },
  { name: 'aggregation_fee', required: true },
  { name: 'contract_sub_periods_attributes.0.dates', required: true },
  { name: 'contract_sub_periods_attributes.0.display_price', required: false, unit: '{CURRENCY}/MW' },
  { name: 'contract_sub_periods_attributes.0.price_type', required: true },
  { name: 'external_pdf_link', required: false, permission: 'display:non_engie' },
  { name: 'sent_to_remit' },
];
