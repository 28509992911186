import { useContext } from 'react';
import { MarketFuture } from '@utils/types/market_future';
import { subPeriodsContext } from '../../context';
import { SubPeriodsContext } from '../../contracts.types';
import { makeSubPeriod } from '../sections/SubPeriods/helpers';
import { useContractForm } from './useContractForm';

export const useSubPeriods = (): Omit<SubPeriodsContext, 'setCollapsed'> & {
  isCollapsed: (id: string) => boolean;
  toggleSubPeriod: (id: string) => void;
  collapseAll: () => void;
  unCollapseAll: () => void;
  fromMarketFutures: (periods: MarketFuture[]) => Promise<void>;
} => {
  const context = useContext(subPeriodsContext);
  const { watch, trigger } = useContractForm();
  const contractType = watch('type');

  const collapseAll = (): void => {
    context.setCollapsed?.(context.subPeriods?.fields.map((p) => p.field_internal_id));
  };

  const unCollapseAll = (): void => {
    context.setCollapsed?.([]);
  };

  const toggleSubPeriod = (id: string): void => {
    context.setCollapsed?.((currentList) => {
      const isCollapsed = currentList?.includes(id);

      return isCollapsed ? currentList?.filter((i) => i !== id) : [...(currentList || []), id];
    });
  };

  const isCollapsed = (id: string): boolean => {
    return context.collapsed?.includes(id) || false;
  };

  const fromMarketFutures = async (marketFutures: MarketFuture[]) => {
    if (!contractType) return;

    marketFutures.forEach((period) => {
      const { start_date, end_date } = period;

      const subPeriod = makeSubPeriod(contractType, { start_date, end_date, phase_type: null, market_future: period });

      context.subPeriods?.append(subPeriod);
    });

    // This makes sure the validation is triggered in the next loop, which is needed to make
    // sure the new sub-period dates are validated.
    if (await trigger('contract_sub_periods_attributes')) trigger('contract_sub_periods_attributes');
  };

  return {
    ...context,
    isCollapsed,
    toggleSubPeriod,
    collapseAll,
    unCollapseAll,
    fromMarketFutures,
  };
};
