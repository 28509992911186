import { useMemo } from 'react';
import useBooks from '@hooks/requests/useBooks';
import type Book from '@utils/types/book';
import type { NegativePrice, NegativePricesFilters } from '../negative-prices.types';

export const useFilterNegativePrices = (data: NegativePrice[] | undefined, filters: NegativePricesFilters) => {
  const booksQuery = useBooks();

  return useMemo(() => {
    if (!data) return [];

    const books = booksQuery.data ?? [];

    return data.filter((price) => {
      return (
        filterByEnergy(price, filters.energy) &&
        filterByCountries(price, filters.countries) &&
        filterByInstallations(price, filters.installations) &&
        filterByBooks(price, books, filters.books)
      );
    });
  }, [data, filters, booksQuery.data]);
};

const filterByEnergy = (price: NegativePrice, energy: NegativePricesFilters['energy']) => {
  return energy === 'all' || price.installation?.energy === energy;
};

const filterByCountries = (price: NegativePrice, countries: NegativePricesFilters['countries']) => {
  return countries.length === 0 || (price.installation?.country && countries.includes(price.installation.country));
};

const filterByInstallations = (price: NegativePrice, installations: NegativePricesFilters['installations']) => {
  return installations.length === 0 || (price.installation && installations.includes(price.installation.name));
};

const filterByBooks = (price: NegativePrice, books: Book[], filtersBooks: NegativePricesFilters['books']) => {
  if (filtersBooks.length === 0 || books.length === 0) return true;

  const filteredBooks = books.filter((book) => filtersBooks.includes(book.name));
  const bookInstallations = filteredBooks.flatMap((book) => book.installation_names);

  return Boolean(price.installation && bookInstallations.includes(price.installation.name));
};
