import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';

export const SOAFields: ContractFormField[] = [
  { name: 'balancing_responsible_party_id' },
  { name: 'additional_buyer_id' },
  { name: 'include_vat' },
  { name: 'p_max', configurable: true },
  { name: 'contract_sub_periods_attributes.0.dates', required: true },
  {
    name: 'contract_sub_periods_attributes.0.display_price',
  },
  {
    name: 'contract_sub_periods_attributes.0.price_type',
    required: false,
    disabledForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_rule', present: true }],
  },
  {
    name: 'contract_sub_periods_attributes.0.neg_price_rule',
    disabledForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_rule', present: true }],
  },
  {
    name: 'contract_sub_periods_attributes.0.aggreg_fees',
    required: false,
    disabledForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_rule', present: true }],
  },
  { name: 'contract_sub_periods_attributes.0.price_rule', required: false },
  {
    name: 'rate_change',
    required: false,
    onlyForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_type', value: 'indexed' }],
  },
  {
    name: 'index_formula',
    required: false,
    onlyForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_type', value: 'indexed' }],
  },
  {
    name: 'contract_sub_periods_attributes.0.price_model_attributes.source',
    onlyForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_type', value: 'spot' }],
    info: { title: 'sales_management.price_model.source', text: 'sales_management.price_model.source_info' },
  },
  {
    name: 'contract_sub_periods_attributes.0.volume_type',
  },
  {
    name: 'contract_sub_periods_attributes.0.profile_hedge_attributes.period',
  },
  {
    name: 'contract_sub_periods_attributes.0.profile_hedge_attributes.profile_hedge_caps_attributes',
  },
  {
    name: 'contract_sub_periods_attributes.0.profile_hedge_attributes.price_above_cap_type',
  },
  {
    name: 'birthday_date',
    required: false,
    onlyForFieldValues: [
      { field: 'rate_change', value: 'commissioning_date' },
      { field: 'contract_sub_periods_attributes.0.price_type', value: 'indexed' },
    ],
  },
  {
    name: 'hedge_contract.spot_fee',
    required: false,
    rules: { min: 0 },
    onlyForFieldValues: [{ field: 'hedge_blocks_attributes.0.dates', present: true }],
  },
  { name: 'hedge_blocks_attributes.0.dates', required: true },
  { name: 'hedge_blocks_attributes.0.price', required: true },
  { name: 'hedge_blocks_attributes.0.average_power', required: true },
  { name: 'external_pdf_link', required: false, permission: 'display:non_engie' },
  { name: 'sent_to_remit' },
];
