import React from 'react';
import { Input } from '@GDM/forms';
import { Text } from '@GDM/Text';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { getSymbol } from '@utils/currency/getSymbol';
import { Option } from '@utils/types/common-types';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import styles from '../aggreg-fees.module.scss';

export const AggregFee: React.FC<{
  subPeriodIndex: number;
  aggregFeeIndex: number;
  isDisabled?: boolean;
}> = ({ subPeriodIndex, aggregFeeIndex, isDisabled }) => {
  const { control, watch, setValue, getValues, readonly, trigger } = useContractForm();
  const { fieldRules } = useContractFields();

  const feeType =
    watch(`contract_sub_periods_attributes.${subPeriodIndex}.aggreg_fees.${aggregFeeIndex}.type`) || 'fixed';
  const feesList = watch(`contract_sub_periods_attributes.${subPeriodIndex}.aggreg_fees`);
  const currency = watch('currency');
  const hasHedgeProfile =
    getValues(`contract_sub_periods_attributes.${subPeriodIndex}.price_type`) === 'fixed' &&
    getValues(`contract_sub_periods_attributes.${subPeriodIndex}.volume_type`) === 'hedge_profile';

  const valueIsValid = {
    noFeeType: () => !!feeType || 'sales_management.sub_period_errors.missing_fee_type',
    percentageOutOfBounds: (value: number | null) => {
      if (feeType !== 'percentage' || value === null || value === undefined) return;

      return (value <= 100 && value >= -100) || 'sales_management.sub_period_errors.percentage_out_of_bounds';
    },
    valueIsNaN: (value: number | null) => {
      if (value !== undefined) return !isNaN(Number(value)) || 'sales_management.sub_period_errors.value_is_nan';
    },
  };

  const suffixOptions: Option<'fixed' | 'percentage'>[] = [
    { label: `${getSymbol(currency)}/MWh`, value: 'fixed' },
    { label: '%', value: 'percentage' },
  ];

  const onChangeFeeType = async (option: Option<'fixed' | 'percentage'> | null) => {
    setValue(
      `contract_sub_periods_attributes.${subPeriodIndex}.aggreg_fees.${aggregFeeIndex}.type`,
      option?.value || null,
    );
    // Make sure the whole input is validated (input value + selector) when the select updates.
    await trigger(`contract_sub_periods_attributes.${subPeriodIndex}.aggreg_fees.${aggregFeeIndex}.value`);
    trigger(`contract_sub_periods_attributes.${subPeriodIndex}.aggreg_fees.${aggregFeeIndex}.value`);
  };

  return (
    <Controller
      name={`contract_sub_periods_attributes.${subPeriodIndex}.aggreg_fees.${aggregFeeIndex}.value`}
      control={control}
      rules={{ ...fieldRules(`contract_sub_periods_attributes.${subPeriodIndex}.aggreg_fees`), validate: valueIsValid }}
      render={({ field, fieldState }) => (
        <div className={styles['fee-input-wrapper']}>
          <Input
            {...field}
            label={hasHedgeProfile ? 'sales_management.above_profile_fees' : 'sales_management.aggreg_fee'}
            labelStyle={hasHedgeProfile ? { fontWeight: 'normal' } : undefined}
            size="lg"
            type="number"
            step="0.01"
            data-cy={`contract_sub_periods_attributes.${subPeriodIndex}.aggreg_fees.${aggregFeeIndex}`}
            value={field.value ?? ''}
            min={feeType === 'percentage' ? -100 : undefined}
            max={feeType === 'percentage' ? 100 : undefined}
            hasError={!!fieldState.error}
            autoComplete="off"
            disabled={isDisabled}
            tooltip={isDisabled ? 'sales_management.info.has_price_rule' : null}
            suffixOptions={suffixOptions}
            suffixOptionSelected={feeType}
            suffixOptionsDisabled={isDisabled || feesList?.length === 2 || readonly}
            onChangeSuffixOption={onChangeFeeType}
            readOnly={readonly}
            full
          />
          {!!fieldState?.error?.message && (
            <Text
              className={classNames(styles['error-message'], 'mt-1')}
              size="sm"
              text={fieldState?.error?.message}
              type="danger"
            />
          )}
        </div>
      )}
    />
  );
};
