import React from 'react';
import { Input } from '@GDM/forms';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { getSymbol } from '@utils/currency/getSymbol';
import { Controller } from 'react-hook-form';

export const SwapFixedPrice: React.FC<{ index: number }> = ({ index }) => {
  const { control, readonly, watch } = useContractForm();
  const { fieldRules } = useContractFields();
  const currency = watch('currency');

  return (
    <Controller
      name={`contract_sub_periods_attributes.${index}.extra_params.swap_fixed_price`}
      control={control}
      rules={fieldRules(`contract_sub_periods_attributes.${index}.extra_params.swap_fixed_price`)}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          data-cy={`swap-fixed-price-input-${index}`}
          label="sales_management.swap_fixed_price"
          size="lg"
          type="number"
          value={field.value ?? ''}
          max={10000}
          step={1}
          hasError={!!fieldState.error}
          suffix={`${getSymbol(currency)}/MWh`}
          autoComplete="off"
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
