import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CredentialsProvider } from '@components/providers/CredentialsProvider';
import { useInstallationsContext } from '@context/installations.context';
import { post, put, useRequest } from '@hooks/useRequest';
import {
  ContractPageContext,
  ContractRequestContext,
  ContractRequestError,
} from '@pages/Contracts/Contract/contracts.types';
import { intermediaries_v2_market_players_path, v2_contract_path, v2_contracts_path } from '@utils/routes';
import { Contract, ContractForm, ContractFormBody } from '@utils/types/contract';
import MarketPlayer from '@utils/types/market_player';
import { User } from '@utils/types/user';
import classNames from 'classnames';
import { FormProvider as Provider, useForm } from 'react-hook-form';
import { FORM_DEFAULT_VALUES } from '../constants';
import styles from '../contract.module.scss';
import { DisclaimerModal } from '../DisclaimerModal';
import { handleSubmitSideEffects, serializeContract } from '../Form/helpers';
import { ConfigurableFieldsProvider } from './configurable-fields';
import { contractPageContext } from './contract-page.context';
import { contractRequestContext } from './contract-request.context';
import { HedgeBlocksProvider } from './hedge-blocks';
import { PriceRulesProvider } from './price-rules';
import { SubPeriodsProvider } from './sub-periods';

export const ContractFormProvider: React.FC<
  React.PropsWithChildren<{
    uuid?: string;
    marketPlayer?: MarketPlayer;
    readonly?: boolean;
    containerClassName?: string;
    user: User;
  }>
> = ({ children, containerClassName, marketPlayer, readonly, uuid, user }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isDraft, setIsDraft] = useState<boolean | undefined>(false);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [serializedBody, setSerializedBody] = useState<ContractFormBody | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>();
  const [contractDisplayName, setContractDisplayName] = useState<string | undefined>();
  const [updatedAt, setUpdatedAt] = useState<string | undefined>();
  const [requestErrors, setRequestErrors] = useState<ContractRequestError[] | null>(null);
  const createRequest = useRequest<Contract & { errors: ContractRequestError[] | null }>(
    v2_contracts_path(),
    post,
    true,
  );
  const updateRequest = useRequest<Contract & { errors: ContractRequestError[] | null }>(
    createRequest?.data?.id ? v2_contract_path(createRequest.data.id) : '',
    put,
    true,
  );
  const intermediaries = useRequest<MarketPlayer[]>(intermediaries_v2_market_players_path(), 'GET');
  const { installations } = useInstallationsContext();

  const isEditing = !!uuid && !readonly;

  const contractRequestContextValue: ContractRequestContext = useMemo(
    () => ({
      errors: requestErrors,
      wasSuccessful:
        (createRequest.loaded && /20[0|1]/g.test(`${createRequest.status}`)) ||
        (updateRequest.loaded && /20[0|1]/g.test(`${updateRequest.status}`)),
    }),
    [createRequest.loaded, createRequest.status, requestErrors, updateRequest.loaded, updateRequest.status],
  );

  const form = useForm<ContractForm>({
    defaultValues: {
      ...FORM_DEFAULT_VALUES,
      buyer_id: marketPlayer ? marketPlayer.id : FORM_DEFAULT_VALUES.buyer_id,
      currency: user.currencies[0],
    },
    mode: 'onChange',
  });

  useEffect(() => {
    const { loading, loaded, status, error, errors } = createRequest;
    const { data: editedContract, loading: editLoading, loaded: isEdited, errors: editErrors } = updateRequest;

    setErrorMessage(error?.code);

    setIsLoading(loading || editLoading);

    // Errors
    if (errors || editErrors) {
      setRequestErrors((errors || editErrors) as ContractRequestError[]);
    }

    // EDITED
    if (isEditing && isEdited && !updateRequest.error && editedContract) {
      const contractName = editedContract.contract_nb;
      const updatedAt = editedContract.updated_at;
      if (contractName) setContractDisplayName(contractName);
      if (updatedAt) setUpdatedAt(updatedAt);
      window.location.href = `/v2/contracts/${editedContract.id}/view`;
    }

    // CREATED
    if (!isEditing && loaded && !error && status === 201) {
      window.location.href = '/v2/contracts';
    }
  }, [createRequest, isEditing, updateRequest]);

  const updateContract = useCallback(
    (body?: ContractFormBody) => {
      const data = { contract: body || serializedBody };
      updateRequest.execute?.({
        url: data.contract?.id ? v2_contract_path(data.contract.id) : '',
        data,
      });
      setShowDisclaimer(false);
    },
    [serializedBody, updateRequest],
  );

  const createContract = useCallback(
    (body: ContractFormBody) => {
      const data = { contract: body };
      createRequest.execute?.({ data });
    },
    [createRequest],
  );

  const onSubmit = useCallback(
    async (data: ContractForm): Promise<void> => {
      /**
       * In some cases, endpoints other than the contract one have to be called when submitting the contract.
       * For example, if there's an installation siret that's set in the Sy by Cegedim section, this shouldn't
       * be saved on the contract, we have to patch the installation separately from the contract, but we can't
       * do that in the component itself, because we only want to patch it if the user submits the contract.
       *
       * All these requests must be handled before submitting the contract and stop the saving process if any
       * of the requests fail.
       *
       * Any request that has to be made independently, should be stored in `form._non_contract_props.{model_name}.{}`.
       */
      if (data._non_contract_props) {
        // This request should return nothing OR errors.
        const sideEffects = await handleSubmitSideEffects(data, installations);

        if (sideEffects) {
          setRequestErrors(sideEffects);

          return;
        }
      }

      // If all the side-effects are run, then we can go on to processing the form data for submission.
      const body = serializeContract(data);
      setSerializedBody(body);

      // Editing (not draft)
      if (body?.id && body.status !== 'draft') return setShowDisclaimer(true);

      // Editing (draft)
      if (body?.id && body.status === 'draft') return updateContract(body);

      // Creating
      return createContract(body);
    },
    [createContract, installations, updateContract],
  );

  const contractPageContextValue = useMemo<ContractPageContext>(
    () => ({
      contractDisplayName,
      errorMessage,
      intermediaries,
      isDraft,
      isEditing,
      isLoaded,
      isLoading,
      marketPlayer,
      readonly,
      setContractDisplayName,
      setErrorMessage,
      setIsDraft,
      setIsLoaded,
      setIsLoading,
      setUpdatedAt,
      submitForm: onSubmit,
      updatedAt,
      uuid,
    }),
    [
      contractDisplayName,
      errorMessage,
      intermediaries,
      isDraft,
      isEditing,
      isLoaded,
      isLoading,
      marketPlayer,
      onSubmit,
      readonly,
      updatedAt,
      uuid,
    ],
  );

  return (
    <contractPageContext.Provider value={contractPageContextValue}>
      <contractRequestContext.Provider value={contractRequestContextValue}>
        <Provider {...form}>
          <ConfigurableFieldsProvider>
            <PriceRulesProvider>
              <CredentialsProvider>
                <SubPeriodsProvider>
                  <HedgeBlocksProvider>
                    <DisclaimerModal
                      onConfirm={updateContract}
                      isOpen={showDisclaimer}
                      toggle={() => setShowDisclaimer((isOpen) => !isOpen)}
                    />
                    <form className={classNames(styles.container, containerClassName)}>{children}</form>
                  </HedgeBlocksProvider>
                </SubPeriodsProvider>
              </CredentialsProvider>
            </PriceRulesProvider>
          </ConfigurableFieldsProvider>
        </Provider>
      </contractRequestContext.Provider>
    </contractPageContext.Provider>
  );
};
