import React, { useEffect } from 'react';
import { Alert } from '@GDM/Alert';
import { useRequest } from '@hooks/useRequest';
import { v2_contract_path } from '@utils/routes';
import { Contract } from '@utils/types/contract';
import { Form } from '../Form';
import { useContractForm } from '../Form/hooks';

export const ViewContract: React.FC<{ contractUuid: string }> = ({ contractUuid }) => {
  const { data: contract, loading, error, loaded } = useRequest<Contract>(v2_contract_path(contractUuid), 'GET');
  const { setContract, setIsLoading, setErrorMessage } = useContractForm();

  useEffect(() => {
    setIsLoading?.(loading);
    setErrorMessage?.(error?.message);

    if (loaded && !error && !loading && contract) setContract(contract);
  }, [contract, loaded, error, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Alert variant="danger" label="sales_management.no_contract_found" />;

  return <Form />;
};
