import React, { useEffect } from 'react';
import Page from '@pages/Page';
import { CountryCode } from '@utils/types/countries';
import { User } from '@utils/types/user';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import {
  DeepPartial,
  ErrorOption,
  FieldValues,
  FormProvider,
  Path,
  SubmitHandler,
  type UseFormReturn,
} from 'react-hook-form';
import { FormField } from '../form.types';
import { FormPageActions } from '../FormPageActions';
import { ProgressStepper, ProgressWatcher, Step, useProgressStepper } from '../ProgressStepper';
import { InternalFormProvider } from './Form.context';
import styles from './form.module.scss';

const RenderPage: React.FC<
  React.PropsWithChildren<{
    pageProps?: React.ComponentProps<typeof Page>;
    backButtonHref?: string;
    isLoading?: boolean;
    user: User;
  }>
> = ({ children, pageProps, backButtonHref, isLoading }) => {
  if (!pageProps) {
    return <>{children}</>;
  }

  return (
    <Page
      pageActions={<FormPageActions isLoading={!!isLoading} backButtonHref={backButtonHref} />}
      isLoading={isLoading}
      {...pageProps}
    >
      {children}
    </Page>
  );
};

export const Form = <T extends FieldValues>({
  country,
  countryKey,
  commonFields,
  fieldSections,
  steps,
  pageProps,
  defaultValues,
  values,
  errors,
  onSubmit,
  isLoading,
  backButtonHref,
  progressCallback,
  user,
  children,
  currentStep,
  form,
}: React.PropsWithChildren<{
  country?: CountryCode | null;
  countryKey?: Path<T>;
  commonFields: FormField<T>[];
  fieldSections: Map<FormField<T>['name'], string>;
  steps: Record<string, Step>;
  pageProps?: React.ComponentProps<typeof Page>;
  defaultValues?: DeepPartial<T>;
  values?: DeepPartial<T>;
  errors?: [Path<T>, ErrorOption][];
  isLoading?: boolean;
  backButtonHref?: string;
  currentStep?: string;
  user: User;
  onSubmit: SubmitHandler<T>;
  progressCallback?: (
    markAsValid: ReturnType<typeof useProgressStepper>['markAsValid'],
    updateProgress: ReturnType<typeof useProgressStepper>['updateProgress'],
  ) => void;
  form: UseFormReturn<T>;
}>) => {
  const { watch } = form;

  const [internalCountry, setInternalCountry] = React.useState<CountryCode | null | undefined>(
    country || (countryKey ? get(defaultValues, countryKey) : undefined),
  );

  const infoBoxDefinitions = React.useMemo(() => {
    return commonFields.reduce<Record<string, { title?: string; text: string }>>((map, field) => {
      if (!field.info) return map;

      return { ...map, [field.name]: field.info };
    }, {});
  }, [commonFields]);

  /**
   * Usually you wouln't use an `useEffect` to sync props and state,
   * but react-hook-form doesn't let you update the form values directly without rendering the form
   * and the input at the same time, which cause an error
   */
  useEffect(() => {
    if (values) {
      Object.keys(values).forEach((key) => {
        form.setValue(key as Path<T>, values[key], { shouldValidate: true });
      });
    }
  }, [form, values]);

  useEffect(() => {
    errors?.forEach(([name, error]) => {
      form.setError(name, error);
    });
  }, [form, errors]);

  if (country && !isEqual(internalCountry, country)) {
    setInternalCountry(country);
  }

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (countryKey && name === countryKey) {
        const value = get(values, countryKey);

        setInternalCountry(value);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [countryKey, watch]);

  return (
    <InternalFormProvider
      country={internalCountry}
      commonFields={commonFields}
      fieldSections={fieldSections}
      progressCallback={progressCallback}
      steps={steps}
      loading={!!isLoading}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={styles.container}>
          <RenderPage pageProps={pageProps} isLoading={isLoading} backButtonHref={backButtonHref} user={user}>
            {children}
            <ProgressStepper
              steps={steps}
              className={styles.stepper}
              containerMaxWidth={60}
              infoBoxDefinitions={infoBoxDefinitions}
              singleStep={currentStep}
            >
              <ProgressWatcher />
            </ProgressStepper>
          </RenderPage>
        </form>
      </FormProvider>
    </InternalFormProvider>
  );
};
