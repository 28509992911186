import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { useProgressStepper, useStep } from '@GDM/forms';
import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';
import { ContractForm } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import { DeepPartial } from 'react-hook-form';
import { getFieldSection } from '../builder/helpers';
import { isSetAndValid, getRequiredFields, valueIsRequired } from '../helpers';
import { useContractFields } from './useContractFields';
import { useContractForm } from './useContractForm';
import { useContractPage } from './useContractPage';

/**
 * Takes either a list of contract form properties or a list of form field definitions.
 * _e.g_: `['type', 'start_date', ...]` OR `[{ name: 'type', required: true }, ...]`
 */
export const useContractProgress = (fields: Array<ContractFormField | ContractFormField['name']>): void => {
  const { watch, getValues } = useContractForm();
  const { stepId } = useStep();
  const { markAsValid, updateProgress } = useProgressStepper();
  const { installations } = useInstallationsContext();
  const { isRequired } = useContractFields();
  const { isEditing, isLoaded } = useContractPage();
  const hasRunProgressAfterInitialLoad = useRef(false);

  const [contractType, installationUuid, subPeriods, hedgeBlocks] = watch([
    'type',
    'installation_uuid',
    'contract_sub_periods_attributes',
    'hedge_blocks_attributes',
  ]);
  const installationCountry = installations?.find(({ uuid }) => uuid === installationUuid)?.country ?? null;

  const sectionRequiredFields = useMemo(
    () =>
      fields
        // Filter required fields and those that belong to the section
        .filter((field) => {
          // If it's a string, it's specified by default and must be present
          return (
            typeof field === 'string' ||
            (getFieldSection(field.name, contractType) === stepId &&
              isRequired(field) &&
              !field.excludedForCountries?.includes(installationCountry as CountryCode) &&
              (!field.onlyForCountries || field.onlyForCountries?.includes(installationCountry as CountryCode)))
          );
        })
        .map((field) => (typeof field === 'string' ? field : field?.name)),
    [contractType, fields, installationCountry, isRequired, stepId],
  );

  // `name` can be null when the form is reset, because all the values change at once (not a specific one),
  // so we need to check for that.
  const updateSectionProgress = useCallback(
    (value: DeepPartial<ContractForm>, name: ContractFormField['name'] | null = null) => {
      if (sectionRequiredFields.length === 0) {
        const progress = 100;
        if (stepId) updateProgress(stepId, progress);
        if (stepId) markAsValid(stepId, true);
      }

      if (valueIsRequired(name, sectionRequiredFields)) {
        // Work out how many actual fields are required
        const requiredFields = getRequiredFields(sectionRequiredFields, {
          contract_sub_periods_attributes: subPeriods,
          hedge_blocks_attributes: hedgeBlocks,
        });

        const completedFields = requiredFields.filter((field) => isSetAndValid(value, field));

        const progress = (completedFields.length / requiredFields.length) * 100;
        if (stepId) updateProgress(stepId, progress);
        if (stepId) markAsValid(stepId, progress === 100);
      }
    },
    [sectionRequiredFields, hedgeBlocks, markAsValid, stepId, subPeriods, updateProgress],
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name && getFieldSection(name, contractType) === stepId) updateSectionProgress(value, name);
    });

    return () => subscription.unsubscribe();
  }, [updateSectionProgress, watch, subPeriods, contractType, stepId]);

  // When a contract is first loaded for editing, we have to trigger the progress update manually
  // because the `watch` API doesn't catch the changes.
  useEffect(() => {
    if (isEditing && isLoaded && !hasRunProgressAfterInitialLoad.current) {
      updateSectionProgress(getValues());
      // We only want this to run once
      hasRunProgressAfterInitialLoad.current = true;
    }
  }, [getValues, isEditing, isLoaded, updateSectionProgress]);
};
