import React, { useMemo } from 'react';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import dayjs from 'dayjs';
import {
  granularityFormat,
  type Granularity,
  granularityChartFormat,
  dayjsGranularityMap,
} from './constants/granularities';
import type { NegativeHour } from './helpers/getFormattedNegativeHours';

export const NegativePricesChart = ({
  negativeHours,
  granularity,
  isLoading,
  startDate,
  endDate,
}: {
  negativeHours: NegativeHour[];
  granularity: Granularity;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { t } = useTranslation();

  const dateFormat = (date: string | number) =>
    dayjs.utc(date, granularityFormat[granularity]).format(granularityChartFormat[granularity]);

  const categories = useMemo(() => {
    return generateCategories(startDate, endDate, granularity);
  }, [startDate, endDate, granularity]);

  const productionHours: [string, number][] = negativeHours.map((nh) => [nh.startDate, nh.productionHours]);
  const productionOnNegativeHours = new Map<string, number>(
    negativeHours.map((nh) => [nh.startDate, nh.productionOnNegativeHours]),
  );
  /* TODO PRODUCTIBLE const productibleOnNegativeHours = new Map<string, number>(
    negativeHours.map((nh) => [nh.startDate, nh.productibleOnNegativeHours]),
  );*/

  const interruptionHours: [string, number][] = negativeHours.map((nh) => [nh.startDate, nh.interruptionHours]);

  const series: Highcharts.Options['series'] = [
    {
      name: t('common.n_negative_price_prod_hours'),
      data: categories.map((c) => {
        if (productionHours.length < 1) return null;
        const point = productionHours?.find((d) => d[0] === c);

        if (!point) return null;

        return point?.length >= 2 ? point[1] : null;
      }),
      type: 'column',
      tooltip: { valueSuffix: ' h' },
    },
    {
      name: t('common.n_interruption_hours_on_negative_price'),
      data: categories.map((c) => {
        if (interruptionHours.length < 1) return null;
        const point = interruptionHours?.find((d) => d[0] === c);

        if (!point) return null;

        return point?.length >= 2 ? point[1] : null;
      }),
      type: 'column',
      tooltip: { valueSuffix: ' h' },
    },
    {
      name: t('common.prod_during_negative_price'),
      data: categories.map((c) => {
        return productionOnNegativeHours.get(c) || null;
      }),
      type: 'line',
      tooltip: { valueSuffix: ' kWh' },
      yAxis: 1,
      marker: { enabled: true, radius: 3 },
      color: 'var(--chart-production)',
    },
    /* TODO PRODUCTIBLE {
      name: t('common.productible_during_negative_price'),
      data: categories.map((c) => {
        return productibleOnNegativeHours.get(c) || null;
      }),
      type: 'line',
      tooltip: { valueSuffix: ' kWh' },
      yAxis: 1,
      marker: { enabled: true, radius: 3 },
      color: 'var(--chart-productible)',
    },*/
  ];

  const options: Highcharts.Options = {
    xAxis: {
      type: 'datetime',
      categories,
      labels: {
        formatter: function () {
          return dateFormat(this.value);
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        maxPointWidth: 50,
      },
      series: {
        marker: { enabled: true, radius: 3 },
      },
    },
    yAxis: [
      { title: { text: `${t('common.n_hours')} (h)` }, opposite: true },
      { title: { text: `${t('common.production')} (kWh)` } },
    ],
  };

  return <Chart options={options} series={series} isLoading={isLoading} />;
};

/**
 * This method generates the categories including the missing dates between the start and end date
 */
const generateCategories = (startDate: Date | null, endDate: Date | null, granularity: Granularity) => {
  const categories = new Set<string>();
  let start = dayjs(startDate);
  const end = dayjs(endDate);

  while (start.isBefore(end)) {
    const date = start.format(granularityFormat[granularity]);

    categories.add(date);

    start = start.add(1, dayjsGranularityMap[granularity]);
  }

  return Array.from(categories).toSorted();
};
