import React, { useEffect } from 'react';
import { sectionStyles } from '@GDM/forms';
import classNames from 'classnames';
import { ContractSection } from '../../components';
import { useContractFields, useContractForm, useHedgeBlocks } from '../../hooks';
import { HedgeBlock } from './HedgeBlock';
import { HedgeBlocksActions } from './HedgeBlocksActions';
import { SpotFee } from './SpotFee';

export const HedgeBlocks: React.FC = () => {
  const { hasField } = useContractFields();
  const { hedgeBlocks, collapseAll } = useHedgeBlocks();
  const { readonly } = useContractForm();

  useEffect(() => {
    if ((hedgeBlocks?.fields && hedgeBlocks.fields.length > 5) || readonly) collapseAll?.();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (readonly && !hedgeBlocks?.fields?.length) return null;

  return (
    <ContractSection className="p-0">
      {hasField('hedge_contract.spot_fee') && (
        <div className={classNames(sectionStyles.row, 'p-3', 'pb-2')}>
          <SpotFee />
        </div>
      )}

      <div className={sectionStyles['section']}>
        {hedgeBlocks?.fields?.map((block, i) => (
          <HedgeBlock key={block.field_internal_id} index={i} blockId={block.field_internal_id} />
        ))}
      </div>

      {!readonly && (
        <div
          className={classNames(sectionStyles['section-footer'], {
            [sectionStyles['no-border']]: !hedgeBlocks?.fields?.length,
          })}
        >
          <HedgeBlocksActions />
        </div>
      )}
    </ContractSection>
  );
};
