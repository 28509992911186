import { TimeSeries } from '@utils/types/timeSeries';
import dayjs from 'dayjs';
import { TimeSeriesAggregation } from '../production.types';

/**
 * Compute aggregate (sum, count) and extreme (min, max) informations about a time series
 * in a single traversal. Permits additional processing in the same traversal with the onProcessing callback
 * @param series {TimeSeries} Time series to process
 * @param timeFilter {[number, number]} Time interval where the series will be processed. If not defined, the entire series is processed
 * @param onProcessing {(point: TimeSeries[number]) => void} Callback triggered when a value from the series is ready to be processed
 */
export const computeTimeSeriesAggregation = (
  series: TimeSeries,
  timeFilter?: [number, number],
  onProcessing?: (point: TimeSeries[number]) => void,
): TimeSeriesAggregation => {
  const initialStats: TimeSeriesAggregation = {
    min: { value: Infinity, date: Date.now() },
    max: { value: -Infinity, date: Date.now() },
    sum: 0,
    count: 0,
    start: 0,
    end: 0,
    hoursCount: {
      daily: 0,
      monthly: 0,
    },
  };

  const todayBeginningOfMonthUnix = dayjs().startOf('month').utc().unix() * 1000;

  return series.reduce((stats, [date, value]) => {
    if (timeFilter && timeFilter[0] > date) return stats;
    if (timeFilter && timeFilter[1] < date) return stats;

    if (onProcessing) onProcessing([date, value]);

    const count = stats.count + 1;
    const newMonthlyHours =
      date < todayBeginningOfMonthUnix ? 30 * 24 : (date - todayBeginningOfMonthUnix) / (60 * 60 * 1000);

    return {
      ...stats,
      timeFilter,
      count,
      min: value > stats.min.value ? stats.min : { value, date },
      max: value < stats.max.value ? stats.max : { value, date },
      sum: stats.sum + value,
      start: stats.count === 0 ? date : stats.start,
      end: date,
      hoursCount: {
        daily: count * 24,
        monthly: stats.hoursCount.monthly + newMonthlyHours,
      },
    };
  }, initialStats);
};
