import React from 'react';
import { DatePickerProps } from '@GDM/DatePicker';
import { DatePicker } from '@GDM/DatePicker/DatePicker';
import { FieldValues, Path, Control, Controller } from 'react-hook-form';

export default function ControlledDatePicker<T extends FieldValues>({
  name,
  control,
  ...datePickerProps
}: Omit<DatePickerProps, 'startDate' | 'endDate' | 'onChange'> & {
  name: Path<T>;
  control: Control<T>;
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DatePicker
          {...datePickerProps}
          startDate={field.value?.[0]}
          endDate={field.value?.[1]}
          onChange={field.onChange}
        />
      )}
    />
  );
}
