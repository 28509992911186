import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { ContractSection } from '../../components';
import { useContractFields } from '../../hooks';
import { AnnualCap } from './AnnualCap';
import { PMax } from './PMax';

export const Cap: React.FC = () => {
  const { hasFields } = useContractFields();

  return (
    <ContractSection className="p-3">
      <div className={sectionStyles.row}>
        {hasFields('annual_cap') && <AnnualCap />}
        {hasFields('p_max') && <PMax />}
      </div>
    </ContractSection>
  );
};
