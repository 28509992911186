import React, { ReactNode } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { ContractForm, ContractSubPeriod, ContractSubPeriodForm } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import classNames from 'classnames';
import { Control } from 'react-hook-form';
import { useContractForm, usePriceRules, useSubPeriods } from '../../../hooks';
import { SubPeriodHeader } from '../SubPeriodHeader';
import styles from './sub-period.module.scss';
import { DefaultSubPeriod } from './templates/Default';
import { GoSubPeriod } from './templates/Go';
import { SoaSubPeriod } from './templates/Soa';

// REFACTORING IN PROGRESS
// We stop the practice of dumping all the fields in the same component with custom hasFields calls
// to detect what to display or not
//
// We will now use pure (only dependant on props) ui templates per contract types
// If a template does not exists for a given contract type, contributors should either create one,
// or use the DefaultSubPeriod template with the legacy behaviour as a fallback
export const SubPeriod = ({ index, periodId }: { index: number; periodId: string }) => {
  // The data needed by all the templates is gathered here
  // A larger refactor would allow us to be more selective about the data we gather
  const { watch, getValues, control, readonly } = useContractForm();
  const contractType = getValues('type');
  const period = watch(`contract_sub_periods_attributes.${index}`);
  const volumeType = getValues(`contract_sub_periods_attributes.${index}.volume_type`);
  const priceType = getValues(`contract_sub_periods_attributes.${index}.price_type`);
  const rateChange = getValues('rate_change');
  const priceRule = getValues(`contract_sub_periods_attributes.${index}.price_rule`);
  const currency = watch('currency');

  const { installations } = useInstallationsContext();

  const installationUuid = watch('installation_uuid');
  const installationCountry: CountryCode =
    installations?.find(({ uuid }) => uuid === installationUuid)?.country || 'FR';

  const { priceRules } = usePriceRules();

  const somePriceRulesAreAvailable = Boolean(priceRules?.length);

  const { isCollapsed } = useSubPeriods();

  return (
    <div
      data-cy="sub-period"
      className={classNames(styles.container, { [styles['is-collapsed']]: isCollapsed?.(periodId) })}
    >
      <SubPeriodHeader index={index} periodId={periodId} />

      <SubPeriodTemplate
        contractType={contractType}
        dataProps={{
          period,
          index,
          volumeType,
          priceType,
          installationCountry,
          rateChange,
          priceRule,
          currency,
          somePriceRulesAreAvailable,
        }}
        formProps={{ control, readonly }}
      />
    </div>
  );
};

function SubPeriodTemplate({
  contractType,
  dataProps: {
    index,
    period,
    volumeType,
    priceType,
    rateChange,
    priceRule,
    currency,
    installationCountry,
    somePriceRulesAreAvailable,
  },
  formProps: { control, readonly },
}: {
  contractType: ContractForm['type'];
  dataProps: {
    index: number;
    period: ContractSubPeriodForm;
    currency: ContractForm['currency'];
    rateChange: ContractForm['rate_change'];
    installationCountry: CountryCode;
    priceRule: ContractSubPeriod['price_rule'];
    volumeType?: ContractSubPeriod['volume_type'];
    priceType?: ContractSubPeriod['price_type'];
    somePriceRulesAreAvailable: boolean;
  };
  formProps: { control: Control<ContractForm>; readonly?: boolean };
}): ReactNode {
  switch (contractType) {
    case 'ContractSoa':
    case 'ContractCppa':
      return (
        <SoaSubPeriod
          index={index}
          volumeType={volumeType}
          priceType={priceType}
          control={control}
          readonly={readonly}
          rateChange={rateChange}
          priceRule={priceRule}
          currency={currency}
          installationCountry={installationCountry}
          somePriceRulesAreAvailable={somePriceRulesAreAvailable}
        />
      );
    case 'ContractGo':
      return (
        <GoSubPeriod index={index} control={control} readonly={readonly} currency={currency} priceType={priceType} />
      );
    case null:
      return null;
    default:
      return <DefaultSubPeriod index={index} period={period} />;
  }
}
