import React, { useState, useMemo } from 'react';
import { FilterContainer, Filters } from '@GDM/Filters';
import { CountrySelect } from '@GDM/forms';
import { Col, Row } from '@GDM/layout';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import Page from '@pages/Page';
import { isCountryCode } from '@utils/filters/isCountryCode';
import { formatSystemDate } from '@utils/formatters';
import { parseM0Array } from '@utils/NationalPrices/parseM0Array';
import { data_v2_national_prices_path } from '@utils/routes';
import { getTimezone, getUserTimezone } from '@utils/timezones';
import { CountryCode } from '@utils/types/countries';
import { User } from '@utils/types/user';
import { CapturePriceChart } from './CapturePriceChart';
import { nationalPriceContext, NationalPricesContext } from './context';
import { HistoricM0Chart } from './HistoricM0Chart';
import { ImbalancePositive } from './ImbalancePositive';
import { M0Aggregators } from './M0Aggregators';
import { M0Card } from './M0Card';
import styles from './national-prices.module.scss';
import { NationalPrices as NationalPricesType } from './national-prices.types';

export const NationalPrices: React.FC<{ user: User }> = ({ user }) => {
  const { t } = useTranslation(user.locale);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode | null>(user.main_country || null);
  const {
    data: m0Data,
    loading,
    error,
  } = useRequest<NationalPricesType>(
    selectedCountry ? data_v2_national_prices_path({ country: selectedCountry }) : null,
    'GET',
  );

  const solarM0 = parseM0Array(m0Data?.solar[m0Data?.solar.length - 1]);
  const windM0 = parseM0Array(m0Data?.wind[m0Data?.wind.length - 1]);
  const baseloadM0 = parseM0Array(m0Data?.base[m0Data?.base.length - 1]);

  const contextValue = useMemo((): NationalPricesContext => {
    const timezone = selectedCountry ? getTimezone(selectedCountry) : getUserTimezone(user);
    const currency = m0Data?.currency ?? 'EUR';

    let startDate: string | null = null;
    if (loading || error) startDate = null;
    else if (m0Data?.base?.[0]) startDate = formatSystemDate(m0Data.base[0][0]);
    else if (m0Data?.solar?.[0]) startDate = formatSystemDate(m0Data.solar[0][0]);
    else if (m0Data?.wind?.[0]) startDate = formatSystemDate(m0Data.wind[0][0]);

    let endDate: string | null = null;
    if (loading || error) endDate = null;
    else if (m0Data?.base?.[m0Data.base.length - 1]) endDate = formatSystemDate(m0Data.base[m0Data.base.length - 1][0]);
    else if (m0Data?.solar?.[m0Data.solar.length - 1])
      endDate = formatSystemDate(m0Data.solar[m0Data.solar.length - 1][0]);
    else if (m0Data?.wind?.[m0Data.wind.length - 1]) endDate = formatSystemDate(m0Data.wind[m0Data.wind.length - 1][0]);

    return {
      timezone,
      startDate,
      endDate,
      selectedCountry,
      m0Data,
      currency,
    };
  }, [selectedCountry, user, loading, error, m0Data]);

  return (
    <nationalPriceContext.Provider value={contextValue}>
      <Page
        user={user}
        title="common.national_prices"
        layout="no-background"
        isLoading={loading}
        error={error?.code ? t(error.code, { country: selectedCountry }) : null}
        header={
          <Filters className="px-0">
            <FilterContainer size="fit">
              <CountrySelect
                selectedOption={selectedCountry || null}
                onChange={(option) =>
                  setSelectedCountry?.(option?.value && isCountryCode(option.value) ? option.value : null)
                }
              />
            </FilterContainer>
          </Filters>
        }
      >
        <div className={styles['card-container']}>
          {selectedCountry === 'FR' && solarM0 && (
            <div className={styles.card}>
              <M0Card isLoading={loading} m0={solarM0} type="solar" />
            </div>
          )}
          {selectedCountry === 'FR' && windM0 && (
            <div className={styles.card}>
              <M0Card isLoading={loading} m0={windM0} type="wind" />
            </div>
          )}
          {baseloadM0 && (
            <div className={styles.card}>
              <M0Card isLoading={loading} m0={baseloadM0} type={selectedCountry === 'FR' ? 'm0_base' : 'base'} />
            </div>
          )}
        </div>

        <HistoricM0Chart />

        {selectedCountry === 'FR' && m0Data && (
          <Row>
            {m0Data?.solar && (
              <Col>
                <CapturePriceChart data={m0Data.solar} captureFactor={m0Data.solar_capture_factor} type="solar" />
              </Col>
            )}
            {m0Data?.wind && (
              <Col>
                <CapturePriceChart data={m0Data.wind} captureFactor={m0Data.wind_capture_factor} type="wind" />
              </Col>
            )}
          </Row>
        )}
        {selectedCountry === 'FR' && m0Data && <M0Aggregators />}
        <ImbalancePositive />
      </Page>
    </nationalPriceContext.Provider>
  );
};
