import React, { useMemo } from 'react';
import { Select } from '@GDM/forms';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { Option } from '@utils/types/common-types';
import { ContractForm, PriceType } from '@utils/types/contract';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { CONTRACT_OPTIONS_MAP } from './options';
import { STATIC_PRICE_TYPES } from './static-price-types';

export const PriceTypePicker: React.FC<{ index: number }> = ({ index }) => {
  const { control, watch, setValue, trigger, readonly } = useContractForm();
  const { fieldRules, isDisabled, onChangeField } = useContractFields();

  const [contractType, subPeriodPhaseType] = watch(['type', `contract_sub_periods_attributes.${index}.phase_type`]);
  const disabled = isDisabled(`contract_sub_periods_attributes.${index}.price_type`);

  const options: Option<PriceType | null>[] = useMemo(
    () => (contractType ? CONTRACT_OPTIONS_MAP[contractType] || [] : []),
    [contractType],
  );

  const isOptionDisabled = (option: Option<PriceType | null>) => {
    return subPeriodPhaseType === 'testing' && option.value === 'm0';
  };

  const onChange = (
    value: PriceType | null,
    field: ControllerRenderProps<ContractForm, `contract_sub_periods_attributes.${number}.price_type`>,
  ): void => {
    onChangeField(field, value);

    if (value && STATIC_PRICE_TYPES.includes(value)) {
      setValue(`contract_sub_periods_attributes.${index}.display_price`, null);
      setValue(`contract_sub_periods_attributes.${index}.price_model_attributes.price_type`, value);
    }

    trigger('contract_sub_periods_attributes');
  };

  return (
    <Controller
      name={`contract_sub_periods_attributes.${index}.price_type`}
      control={control}
      rules={fieldRules(`contract_sub_periods_attributes.${index}.price_type`)}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="common.price_type"
          size="lg"
          menuPlacement="top"
          options={options}
          selectedOption={field.value}
          onChange={(option) => onChange(option?.value ?? null, field)}
          isDisabled={disabled}
          isOptionDisabled={isOptionDisabled}
          tooltip={disabled ? 'sales_management.info.has_price_rule' : null}
          classNamePrefix={`select-price-type-${index}`}
          readOnly={readonly}
          hasError={!!fieldState.error}
        />
      )}
    />
  );
};
