import React, { useMemo } from 'react';
import { DropDownButton } from '@GDM/Button';
import { Checkbox } from '@GDM/forms';
import { ContractFormField } from '../../contracts.types';
import { useContractFields } from '../hooks';
import { useConfigurableFields } from '../hooks/useConfigurableFields';

export const SectionHeader: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  const { visibleFields, setVisibleFields } = useConfigurableFields();
  const { sectionFields } = useContractFields();

  const configurableFields = useMemo(() => {
    return sectionFields.filter((field) => field.configurable);
  }, [sectionFields]);

  const updateField = (field: ContractFormField['name'], value: boolean) => {
    const updatedFields = { ...visibleFields, [field]: value };
    setVisibleFields(updatedFields);
  };

  return (
    <div className={className}>
      {!!configurableFields.length && (
        <DropDownButton
          tooltip="sales_management.configurable_fields.tooltip"
          className="ml-2"
          icon="Settings"
          variant="primary-2"
          position="right"
          data-cy="configurable-fields-dropdown"
          noChevron
          floating
        >
          <div className="p-2 d-flex flex-column gap-1" data-cy="configurable-fields-list">
            {configurableFields.map((field) => (
              <Checkbox
                label={`sales_management.configurable_fields.${field.name}`}
                checked={!!visibleFields[field.name]}
                onChange={(v) => updateField(field.name, v.currentTarget.checked)}
                key={field.name}
                name={`configurable-field-${field.name}`}
              />
            ))}
          </div>
        </DropDownButton>
      )}
      {children}
    </div>
  );
};
