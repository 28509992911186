import { ContractForm } from '@utils/types/contract';
import { FieldPath, UseFormGetValues } from 'react-hook-form';
import { ContractFormField } from '../../contracts.types';

export function valueMatches(
  subject: NonNullable<ContractFormField['onlyForFieldValues']>[number],
  getterFn: UseFormGetValues<ContractForm>,
  index: string | null,
): boolean {
  const { value, present, values, field, excludeValue } = subject;
  const fieldValue = getterFn(index ? (field.replace(/.([0-9]+)./g, index) as FieldPath<ContractForm>) : field);

  if (fieldValue === undefined) return false;
  if (value !== undefined) return fieldValue == value; // Use weak equality to allow for type coercion (e.g '3' should equal Number(3))
  if (present !== undefined) return !!fieldValue;
  if (values !== undefined) return !!values.find((v) => v == fieldValue); // Use weak equality to allow for type coercion (e.g '3' should equal Number(3))
  if (excludeValue !== undefined) return fieldValue !== excludeValue;

  return false;
}
