import React, { useRef, type FocusEvent, type MouseEvent } from 'react';
import { Icon, IconNames } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './toggle.module.scss';

export const Toggle: React.FC<{
  className?: string;
  disabled?: boolean;
  icon?: IconNames;
  label?: string;
  name?: string;
  onChange?: (value: boolean) => void;
  readOnly?: boolean;
  tooltip?: string | null;
  value?: boolean;
  variant?: 'primary-2';
  'data-cy'?: string;
}> = ({ className, ['data-cy']: dataCy, disabled, icon, label, name, onChange, readOnly, tooltip, value, variant }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  // force focus on input when label is clicked
  const handleClick = (e: MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    ref.current?.focus();
    ref.current?.click();
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  return (
    <label
      data-cy={dataCy}
      className={classNames(styles.container, className, !value && styles['is-off'], variant && styles[variant], {
        [styles['is-disabled']]: disabled,
        [styles['is-readonly']]: readOnly,
      })}
      onClick={handleClick}
      ref={labelRef}
    >
      <input
        name={name}
        disabled={disabled}
        ref={ref}
        type="checkbox"
        checked={!!value}
        onChange={() => (!readOnly ? onChange?.(Boolean(ref?.current?.checked)) : false)}
        onBlur={handleBlur}
      />
      {icon && <Icon name={icon} size={14} className="mr-2 ml-1" />}
      <span className={styles.label} title={t(label)}>
        {t(label)}
      </span>
      {tooltip && <Icon name="Info" size={14} title={tooltip} className={styles.tooltip} />}
    </label>
  );
};
