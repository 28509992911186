import React from 'react';
import { sectionStyles } from '@GDM/forms';
import classNames from 'classnames';
import { useContractFields, useHedgeBlocks } from '../../../hooks';
import { HedgeBlockHeader } from '../HedgeBlockHeader';
import { AveragePower } from './AveragePower';
import { DatesPicker } from './DatesPicker';
import styles from './hedge-block.module.scss';
import { Price } from './Price';

export const HedgeBlock: React.FC<{
  index: number;
  blockId: string;
}> = ({ index, blockId }) => {
  const { hasField } = useContractFields();
  const { isCollapsed } = useHedgeBlocks();

  return (
    <div className={classNames(styles.container, { [styles['is-collapsed']]: isCollapsed?.(blockId) })}>
      <HedgeBlockHeader index={index} blockId={blockId} />

      <div className={classNames(sectionStyles.row, styles['block-container'])}>
        <DatesPicker index={index} />
        {hasField(`hedge_blocks_attributes.${index}.price`) && <Price index={index} />}
        {hasField(`hedge_blocks_attributes.${index}.average_power`) && <AveragePower index={index} />}
      </div>
    </div>
  );
};
