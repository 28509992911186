import React from 'react';
import { Input, RadioButtons, sectionStyles } from '@GDM/forms';
import { getSymbol } from '@utils/currency/getSymbol';
import classNames from 'classnames';
import { ContractSection } from '../../components';
import { useContractFields, useContractForm } from '../../hooks';
import { AggregationFee } from './AggregationFee';
import { AnnualIndexation } from './AnnualIndexation/AnnualIndexation';
import { BirthdayDatePicker } from './BirthdayDatePicker';
import { IndexFormulaPicker } from './IndexFormulaPicker';
import { IndexRefLabour } from './IndexRefLabour';
import { IndexRefProduction } from './IndexRefProduction';
import { ManagementBonus } from './ManagementBonus';
import { Ncc } from './Ncc';
import { RateChangePicker } from './RateChangePicker';
import { RefPrice } from './RefPrice';

export const Pricing = () => {
  const { hasFields } = useContractFields();
  const { readonly, getValues } = useContractForm();
  const id = getValues('id');
  const contractType = getValues('type');
  const displayAnnualIndexationData =
    readonly && contractType && ['ContractCrEdf', 'ContractOa'].includes(contractType);
  const currency = getValues('currency');
  const aboveCapPrice = getValues('above_cap_price');
  const isAboveCapPriceIndexed = getValues('is_indexed');

  const showIndexedPricing = isAboveCapPriceIndexed != null && aboveCapPrice !== null;

  return (
    <>
      <ContractSection className="p-3">
        <div className={sectionStyles.row}>
          {hasFields('contract_sub_periods_attributes.0.display_price') && <RefPrice />}
          {hasFields('rate_change') && <RateChangePicker />}
          {hasFields('birthday_date') && <BirthdayDatePicker />}
          {hasFields('index_formula') && <IndexFormulaPicker />}
          {hasFields('producer_price_insee_index_ref') && <IndexRefProduction />}
          <ManagementBonus />
          {hasFields('labour_cost_insee_index_ref') && <IndexRefLabour />}
        </div>
        <div className={sectionStyles.row}>
          {hasFields('ncc') && <Ncc />}
          {hasFields('aggregation_fee') && <AggregationFee />}
        </div>
        {readonly && showIndexedPricing && (
          <div className={classNames(sectionStyles.row, 'flex')}>
            <Input
              label="contracts.capped_price"
              size="lg"
              tooltip="contracts.capped_price_details"
              type="number"
              autoComplete="off"
              readOnly={readonly}
              full
              value={aboveCapPrice}
              suffix={`${getSymbol(currency)}/MWh`}
            />
            <RadioButtons
              size="lg"
              readOnly
              selected={isAboveCapPriceIndexed}
              label="contracts.capped_price_indexation"
              options={[
                { label: 'common.yes', value: true },
                { label: 'common.no', value: false },
              ]}
            />
          </div>
        )}
        {displayAnnualIndexationData && <AnnualIndexation contractId={id} currency={currency} />}
      </ContractSection>
    </>
  );
};
