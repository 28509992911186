import React from 'react';
import { Select } from '@GDM/forms';
import { useContractForm, useContractFields } from '@pages/Contracts/Contract/Form/hooks';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { PriceAboveCapType } from '@utils/types/contract/sub-period';
import classNames from 'classnames';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import styles from '../sub-period.module.scss';

const options: Option<PriceAboveCapType>[] = [
  { value: 'capture_price', label: 'pricing.maps.capture_price' },
  { value: 'baseload', label: 'pricing.baseload_price' },
  { value: 'positive_capture_price', label: 'pricing.maps.positive_capture_price' },
];

export const PriceAboveCapTypePicker: React.FC<{ index: number }> = ({ index }) => {
  const { control, readonly, setValue } = useContractForm();
  const { fieldRules } = useContractFields();

  const fieldName = `contract_sub_periods_attributes.${index}.profile_hedge_attributes.price_above_cap_type` as const;

  const onChange = (
    value: PriceAboveCapType | undefined,
    field: ControllerRenderProps<
      ContractForm,
      `contract_sub_periods_attributes.${number}.profile_hedge_attributes.price_above_cap_type`
    >,
  ): void => {
    if (!value) return;
    field.onChange(value);

    setValue(fieldName, value);
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={fieldRules(fieldName)}
      render={({ field, fieldState }) => (
        <div className={classNames(styles['select-container'], 'wider')}>
          <Select
            {...field}
            label="sales_management.hedge_profile_above_price"
            size="lg"
            options={options}
            readOnly={readonly}
            selectedOption={field.value}
            onChange={(option) => onChange(option?.value, field)}
            hasError={!!fieldState.error}
          />
        </div>
      )}
    />
  );
};
