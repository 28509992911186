import { useCallback, useMemo } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { ContractPageContext } from '@pages/Contracts/Contract/contracts.types';
import { formatSystemDate } from '@utils/formatters';
import { Contract, ContractForm } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import MarketPlayer from '@utils/types/market_player';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { FORM_DEFAULT_VALUES, SUB_PERIODS_DEFAULT_VALUE } from '../../constants';
import { COMMON_FIELDS } from '../builder/config';
import { parseContract } from '../helpers';
import { useContractPage } from './useContractPage';

export const useContractForm = (): UseFormReturn<ContractForm> &
  ContractPageContext & { setContract: (contract: Contract) => void; resetFields: () => void } => {
  const formContext = useFormContext<ContractForm>();
  const contractContext = useContractPage();
  const { installations } = useInstallationsContext();

  const setContract = useCallback(async (contract: Contract) => {
    const formData = parseContract(contract);
    const { setContractDisplayName, setUpdatedAt, setIsDraft } = contractContext;

    // eslint-disable-next-line no-console
    if ([setContractDisplayName, setUpdatedAt, setIsDraft].some((v) => !v)) console.error('Missing context methods!');

    setContractDisplayName?.(formData.contract_nb);
    setUpdatedAt?.(formData.updated_at);
    setIsDraft?.(contract.status === 'draft');
    formContext.reset(formData);
    if (await formContext.trigger()) formContext.trigger(); // Make sure errors are shown after everything is loaded
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** Resets all non-general fields. */
  const resetFields = (): void => {
    const subPeriods = formContext.getValues('contract_sub_periods_attributes');
    const priceModel = subPeriods[0]?.price_model_attributes ?? null;

    const FIELDS_EXCLUDED_FROM_RESET = COMMON_FIELDS.map(({ name }) => name);

    const excludedValues: Partial<ContractForm> = formContext
      .getValues(FIELDS_EXCLUDED_FROM_RESET)
      .reduce<Partial<ContractForm>>((values, current, i) => {
        return { ...values, [FIELDS_EXCLUDED_FROM_RESET[i]]: current };
      }, {});

    const dates = { start_date: excludedValues.start_date, end_date: excludedValues.end_date };

    const resetValues = {
      ...FORM_DEFAULT_VALUES,
      ...excludedValues,
      contract_sub_periods_attributes: [
        {
          ...SUB_PERIODS_DEFAULT_VALUE[0],
          start_date: formatSystemDate(dates.start_date),
          end_date: formatSystemDate(dates.end_date),
          dates,
          price_model_attributes: priceModel,
        },
      ],
    };

    formContext.reset(resetValues);
  };

  // Filter intermediaries based on the installation country
  const installationUuid = formContext.watch('installation_uuid');
  const admissibleIntermediaries: MarketPlayer[] = useMemo(() => {
    const installation = installations?.find(({ uuid }) => uuid === installationUuid);
    const installationCountry: CountryCode = installation?.country || 'FR';

    return contractContext.intermediaries?.data?.filter(({ country }) => country === installationCountry) || [];
  }, [contractContext.intermediaries?.data, installationUuid, installations]);

  return {
    ...formContext,
    ...contractContext,
    intermediaries: {
      ...contractContext.intermediaries,
      data: admissibleIntermediaries,
    },
    setContract,
    resetFields,
  };
};
