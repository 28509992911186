import React, { useEffect } from 'react';
import { UserMenuDropDown } from '@components/Sidebar';
import { useUser } from '@context/User.context';
import useSetLanguage from '@hooks/requests/useSetLanguage';
import { Locale } from '@utils/types/common-types';
import { LanguageRow } from './LanguageRow';
import styles from './user-menu-language-picker.module.scss';

export const UserMenuLanguagePicker: React.FC = () => {
  const user = useUser();
  const { changeLanguage, loaded } = useSetLanguage();

  const onLocaleSelect = (locale: Locale) => {
    changeLanguage(locale);
  };

  useEffect(() => {
    if (loaded) window.location.reload();
  }, [loaded]);

  return (
    <UserMenuDropDown
      title={
        <div className={styles['title']}>
          <LanguageRow locale={user.locale} />
        </div>
      }
      panel={
        <ul className={styles['panel']}>
          <li onClick={() => onLocaleSelect('fr')} className={styles['element']}>
            <LanguageRow locale="fr" />
          </li>
          <li onClick={() => onLocaleSelect('en')} className={styles['element']}>
            <LanguageRow locale="en" />
          </li>
          <li onClick={() => onLocaleSelect('es')} className={styles['element']}>
            <LanguageRow locale="es" />
          </li>
          <li onClick={() => onLocaleSelect('it')} className={styles['element']}>
            <LanguageRow locale="it" />
          </li>
        </ul>
      }
    />
  );
};
