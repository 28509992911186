import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useInstallationsContext } from '@context/installations.context';
import { sectionStyles } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { VAT_RATES, VAT_EXEMPTION_MESSAGES } from '@utils/constants/countries/vat';
import { ContractSection } from '../../components/ContractSection';
import { useContractForm } from '../../hooks';
import { useMarketPlayers } from '../../hooks/requests/useMarketPlayers';
import { useInstallationCountry } from '../GeneralInfo/hooks/useInstallationCountry';

export const Taxes = () => {
  const { control, watch, readonly } = useContractForm();
  const { t } = useTranslation();

  const installationUuid = watch('installation_uuid');
  const { installations } = useInstallationsContext();
  const installationCountry = useInstallationCountry(installations, installationUuid);
  const rate = VAT_RATES[installationCountry] * 100;

  const { data: marketPlayers, isPending } = useMarketPlayers();
  const buyerId = watch('buyer_id');
  const buyer = marketPlayers?.find(({ id }) => id === buyerId);

  const country = buyer?.country || '';
  const message = VAT_EXEMPTION_MESSAGES[country] || '';

  return (
    <ContractSection className="p-3">
      <div className={sectionStyles.row}>
        <ControlledSelect
          name="include_vat"
          control={control}
          options={[
            { label: t('contracts.apply_vat', { rate }), value: true },
            { label: message ? t('contracts.exclude_vat', { message }) : 'common.no', value: false },
          ]}
          label="contracts.vat_application"
          isLoading={isPending}
          size="lg"
          readOnly={readonly}
        />
      </div>
    </ContractSection>
  );
};
