import React, { useMemo } from 'react';
import { Input } from '@GDM/forms';
import { ContractForm } from '@utils/types/contract';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';
import { noSpacesIfHasCegedimActive } from './validators';

export const ContractNumber: React.FC = () => {
  const { control, readonly, getValues } = useContractForm();
  const { fieldRules } = useContractFields();

  const validateContractNumber = useMemo(
    () => ({
      noSpacesIfHasCegedimActive: (contractNb: ContractForm['contract_nb']) =>
        noSpacesIfHasCegedimActive(contractNb, getValues('invoicing_platform')),
    }),
    [getValues],
  );

  return (
    <Controller
      name="contract_nb"
      control={control}
      rules={{ ...fieldRules('contract_nb'), validate: validateContractNumber }}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          label="sales_management.contract_nb"
          size="lg"
          value={field.value ?? ''}
          hasError={!!fieldState.error}
          tooltip={fieldState?.error?.message}
          data-cy="contract-number"
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
