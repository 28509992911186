import React from 'react';
import { Icon } from '@GDM/Icon';
import { Modal } from '@GDM/Modal';
import useTranslation from '@hooks/useTranslation';
import styles from './disclaimer-modal.module.scss';

export const DisclaimerModal: React.FC<{ toggle: () => void; onConfirm: () => void; isOpen: boolean }> = ({
  toggle,
  onConfirm,
  isOpen,
}) => {
  const { t } = useTranslation();

  const Header = (
    <div className={styles.header}>
      <Icon name="AlertTriangle" size={16} className={styles.icon} />
      <span>{t('sales_management.contract_disclaimer_warning')}</span>
    </div>
  );

  return (
    <Modal
      header={Header}
      isOpen={isOpen}
      toggle={toggle}
      submitAction={() => onConfirm()}
      saveButtonText="common.confirm"
      size="sm"
    >
      {t('sales_management.contract_disclaimer')}
    </Modal>
  );
};
