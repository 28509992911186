import React, { useMemo } from 'react';
import { Select, useField } from '@GDM/forms';
import { useMeterTypes } from '@hooks/requests/useMeterTypes';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import type { InstallationForm } from '@pages/Installations/Installation/installation.types';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Controller } from 'react-hook-form';

export const MeterTypeSelect = () => {
  const form = useInstallationForm();
  const reqMeterTypes = useMeterTypes();
  const { rules, disabled } = useField('typecompteur');

  const options: Option<InstallationForm['typecompteur']>[] = useMemo(() => {
    if (!reqMeterTypes.data) return [];

    return reqMeterTypes.data.map((type) => ({ value: type, label: type })).sort(sortOptionsByLabelAsc);
  }, [reqMeterTypes.data]);

  return (
    <Controller
      name="typecompteur"
      control={form.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Select
          classNamePrefix="meter-type-select"
          options={options}
          label="common.meter_type"
          size="lg"
          full
          onChange={(opt) => field.onChange(opt?.value)}
          selectedOption={field.value}
          isDisabled={disabled}
          isLoading={reqMeterTypes.loading}
          hasError={!!fieldState.error}
          errorMessage={fieldState.error?.message}
          isClearable
        />
      )}
    />
  );
};
