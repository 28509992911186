import React, { forwardRef, useMemo } from 'react';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { generateUuid } from '@utils/string';
import { Option } from '@utils/types/common-types';
import classNames from 'classnames';
import LegacySelect, {
  ActionMeta,
  components,
  GroupBase,
  OnChangeValue,
  SelectComponentsConfig,
  StylesConfig,
} from 'react-select';
import { ValueContainer } from './CustomComponents/ValueContainer';
import { filterOption } from './filter-option';
import { getFormatOptionLabel } from './getFormatOptionLabel';
import { getOptionComponent } from './getOptionComponent';
import styles from './select.module.scss';
import { MultiSelectProps, SingleSelectProps } from './select.types';

type OnChange<T = string> = (newValue: OnChangeValue<Option<T>, boolean>, actionMeta: ActionMeta<Option<T>>) => void;

function BaseSelect<T extends string>(
  props: SingleSelectProps<T> | MultiSelectProps<T>,
  ref: React.Ref<HTMLDivElement>,
): JSX.Element {
  const { t } = useTranslation();
  const classList = classNames(styles.select, props.size && styles[props.size], props.className, {
    [styles['is-multi-select']]: props.isMulti,
    [styles.full]: props.full,
  });
  const classNamePrefix = props.classNamePrefix;
  const id = useMemo(() => props.name ?? generateUuid(), [props.name]);

  const translatedOptions: Option<T>[] =
    props.options?.map((option) => ({
      ...option,
      label: t(option.label),
    })) || [];

  const customComponents: SelectComponentsConfig<Option<T>, boolean, GroupBase<Option<T>>> = {
    Option: getOptionComponent(props.isCountry, props.isInstallationOrBook),
    DropdownIndicator: () => {
      return !props.readOnly ? (
        <Icon
          data-cy={`${classNamePrefix}-dropdown`}
          name="ChevronDown"
          size={14}
          className={styles['dropdown-indicator']}
        />
      ) : null;
    },
    ClearIndicator: (props) => {
      return (
        <components.ClearIndicator {...props}>
          <Icon data-cy={`${classNamePrefix}-clear`} name="X" size={14} className={styles['clear-indicator']} />
        </components.ClearIndicator>
      );
    },
    ValueContainer: props.showAllTags ? components.ValueContainer : ValueContainer,
  };

  const colorStyles: StylesConfig<Option<T>> = {
    option: (styles, { data }) => {
      return {
        ...styles,
        fontStyle: data.isItalic ? 'italic' : 'inherit',
      };
    },
  };

  const value = props.selectedOptions
    ? translatedOptions.filter((option) => props.selectedOptions?.includes(option.value))
    : translatedOptions.filter((option) => option.value === props.selectedOption);

  const selectAll = () => {
    if (props.isMulti) props.onChange?.(props.options);
  };

  return (
    <div className={classList} ref={ref}>
      {(!!props.label || props.hasSelectAll) && (
        <label htmlFor={id} style={props.labelStyle}>
          <div className={styles['label-text']}>
            <span
              title={props.label && t(props.label)}
              dangerouslySetInnerHTML={props.label ? { __html: t(props.label) } : undefined}
            />
            {props.tooltip && (
              <Icon name="Info" size={14} title={props.tooltip} className={classNames('ml-1', styles.tooltip)} />
            )}
            {props.isLoading && <Spinner className={classNames(styles.spinner, 'ml-2')} size="sm" />}
          </div>
          {props.labelButton && (
            <Button
              onClick={props.onClickLabelButton}
              variant="link"
              className="ml-2"
              size="xs"
              icon={props.iconLabelButton}
            >
              {t(props.labelButton)}
            </Button>
          )}
          {props.hasSelectAll && props.isMulti && (
            <Button
              onClick={selectAll}
              variant="link"
              size="xs"
              className="ml-2"
              text="common.select_all"
              disabled={props.selectedOptions && props.selectedOptions.length === props.options.length}
            />
          )}
        </label>
      )}
      <LegacySelect
        inputId={id}
        options={translatedOptions}
        isSearchable={props.isSearchable}
        onChange={props.onChange as unknown as OnChange<T>}
        value={value}
        classNamePrefix={props.classNamePrefix}
        unstyled
        isDisabled={props.isDisabled || props.readOnly}
        components={{ ...customComponents, ...props.components }}
        styles={colorStyles}
        filterOption={filterOption}
        classNames={{
          control: () =>
            classNames(
              styles.control,
              props.isDisabled && styles['control-disabled'],
              props.hasError && styles['has-error'],
            ),
          indicatorSeparator: () => styles['indicator-separator'],
          menu: () => styles.menu,
          option: (state) =>
            classNames(
              styles.option,
              state.isSelected && styles['option-selected'],
              state.isFocused && styles['option-focused'],
              state.isDisabled && styles['option-disabled'],
              'cy-option',
            ),
          placeholder: () => styles.placeholder,
          multiValue: () => styles['multi-value'],
          valueContainer: () => classNames(styles['value-container'], props.inline && styles['inline']),
          container: () => classNames(props.readOnly && styles['readonly-container']),
          noOptionsMessage: () => classNames(styles.option, styles['no-options']),
        }}
        noOptionsMessage={() => <>{t(props.noOptionsMessage || 'common.select_default_no_options')}</>}
        placeholder={props.readOnly ? '--' : t(props.placeholder || 'common.select_default_placeholder')}
        isMulti={props.isMulti}
        closeMenuOnSelect={!props.isMulti}
        menuPlacement={props.menuPlacement}
        isOptionDisabled={props.isOptionDisabled}
        isClearable={props.isClearable}
        formatOptionLabel={getFormatOptionLabel(props.isCountry) || props.formatOptionLabel}
        hideSelectedOptions={false}
      />
      {props.errorMessage && <Text size="sm" className="mt-1" type="danger" text={props.errorMessage} />}
    </div>
  );
}

export const Select = forwardRef(BaseSelect) as <T>(props: SingleSelectProps<T> | MultiSelectProps<T>) => JSX.Element;
