import React, { useMemo } from 'react';
import { Input, Select } from '@GDM/forms';
import { Text } from '@GDM/Text';
import { useContractFields, useContractForm, usePriceRules } from '@pages/Contracts/Contract/Form/hooks';
import { Option } from '@utils/types/common-types';
import { PriceRule } from '@utils/types/contract';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import styles from '../sub-period.module.scss';
import { PRICE_RULES_FOR_WHICH_PRICE_RULES_ARE_DISABLED } from './constants';

export const PriceRulePicker: React.FC<{ index: number }> = ({ index }) => {
  const { control, watch, readonly } = useContractForm();
  const { fieldRules } = useContractFields();
  const { priceRules } = usePriceRules();
  const priceType = watch(`contract_sub_periods_attributes.${index}.price_type`);
  const hasIncompatiblePriceType = priceType
    ? PRICE_RULES_FOR_WHICH_PRICE_RULES_ARE_DISABLED.includes(priceType)
    : false;

  const options: Option<PriceRule['id'] | null>[] = useMemo(
    () => [
      { value: null, label: 'common.none' },
      ...(priceRules?.map((rule) => ({
        value: rule.id,
        label: rule.name,
      })) || []),
    ],
    [priceRules],
  );

  return (
    <Controller
      name={`contract_sub_periods_attributes.${index}.price_rule`}
      control={control}
      rules={fieldRules(`contract_sub_periods_attributes.${index}.price_rule`)}
      render={({ field, fieldState }) => (
        <>
          {readonly && !priceRules?.find(({ id }) => id === field.value?.id) ? (
            <Input label="sales_management.price_rule" value={field.value?.name || ''} size="lg" readOnly full />
          ) : (
            <div className={classNames(styles['select-container'], 'wider')}>
              <Select
                {...field}
                label="sales_management.price_rule"
                size="lg"
                options={options}
                menuPlacement="top"
                tooltip={!readonly ? 'sales_management.price_rule_tooltip' : null}
                placeholder={field.value?.name}
                selectedOption={field.value?.id}
                isDisabled={hasIncompatiblePriceType}
                onChange={(option) =>
                  field.onChange(option?.value ? priceRules?.find(({ id }) => id === option.value) : null)
                }
                classNamePrefix={`select-price-rule-${index}`}
                readOnly={readonly}
                hasError={!!fieldState.error}
              />
              {/* When a value is selected but not present in the list (when editing.) */}
              {!!field.value?.id && !priceRules?.find(({ id }) => id === field.value?.id) && (
                <Text
                  className="mt-1"
                  size="sm"
                  text="sales_management.form_errors.price_rule_not_found"
                  type="danger"
                />
              )}
            </div>
          )}
        </>
      )}
    />
  );
};
