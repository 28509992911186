import { useState } from 'react';
import type { CountryCode } from '@utils/types/countries';
import isEqual from 'lodash/isEqual';
import { steps as defaultSteps, meterStep, meterStepCountries } from '../constants/steps';

export const useSteps = (country: CountryCode | null) => {
  const [steps, setSteps] = useState(defaultSteps);
  const [prevCountry, setPrevCountry] = useState<CountryCode | null>(null);

  if (country && !isEqual(country, prevCountry)) {
    const newSteps = { ...defaultSteps };

    if (meterStepCountries.includes(country)) {
      newSteps.meter = meterStep;
    }

    setSteps(newSteps);
    setPrevCountry(country);
  }

  return steps;
};
