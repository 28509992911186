import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';

export const CAPAProxyFields: ContractFormField[] = [
  { name: 'p_max', configurable: true },
  { name: 'contract_sub_periods_attributes.0.dates', required: true },
  {
    name: 'contract_sub_periods_attributes.0.display_price',
    required: true,
    disabledForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_rule', present: true }],
  },
  {
    name: 'contract_sub_periods_attributes.0.price_type',
    required: true,
    disabledForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_rule', present: true }],
  },
  {
    name: 'contract_sub_periods_attributes.0.aggreg_fees',
    required: false,
    disabledForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_rule', present: true }],
  },
  { name: 'contract_sub_periods_attributes.0.price_rule', required: false },
  {
    name: 'rate_change',
    required: false,
    onlyForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_type', value: 'indexed' }],
  },
  {
    name: 'index_formula',
    required: false,
    onlyForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_type', value: 'indexed' }],
  },
  {
    name: 'birthday_date',
    required: false,
    onlyForFieldValues: [
      { field: 'rate_change', value: 'commissioning_date' },
      { field: 'contract_sub_periods_attributes.0.price_type', value: 'indexed' },
    ],
  },
  { name: 'external_pdf_link', required: false, permission: 'display:non_engie' },
  { name: 'sent_to_remit' },
];
