import React from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const CalcBasePicker: React.FC = () => {
  const { control, setValue, readonly, trigger } = useContractForm();
  const { fieldRules } = useContractFields();

  const options: Option<ContractForm['calc_base']>[] = [
    { value: 'load_curve', label: 'common.load_curve' },
    { value: 'index', label: 'common.index' },
  ];

  const onChange = async (
    value: ContractForm['calc_base'] | null | undefined,
    field: ControllerRenderProps<ContractForm, 'calc_base'>,
  ): Promise<void> => {
    field.onChange(value);

    // Reset alternate values if not in use
    if (value === 'index') setValue('cut_time', null);
    if (value === 'load_curve') setValue('invoicing_period', 'monthly');

    if (await trigger(['cut_time', 'invoicing_period', 'power_augmentation']))
      trigger(['cut_time', 'invoicing_period', 'power_augmentation']);
  };

  return (
    <Controller
      name="calc_base"
      control={control}
      rules={fieldRules('calc_base')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.calc_base_select"
          size="lg"
          options={options}
          selectedOption={field.value}
          onChange={(option) => onChange(option?.value, field)}
          classNamePrefix="select-calc-base"
          readOnly={readonly}
          hasError={!!fieldState.error}
          isSearchable
        />
      )}
    />
  );
};
