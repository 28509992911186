import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Badge } from '@GDM/Badge';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import { formatMarketFutureDate } from '@utils/date';
import { formatSystemDate } from '@utils/formatters';
import { PhaseType } from '@utils/types/contract';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useContractForm, useSubPeriods } from '../../../hooks';
import { makeSubPeriod } from '../helpers';
import styles from '../SubPeriod/sub-period.module.scss';

export const SubPeriodHeader: React.FC<{
  index: number;
  periodId: string;
}> = ({ index, periodId }) => {
  const { getValues, trigger, watch, getFieldState, readonly } = useContractForm();
  const { isCollapsed, toggleSubPeriod, subPeriods } = useSubPeriods();
  const { t } = useTranslation();
  const { locale } = useUser();

  const titleMap: Record<NonNullable<PhaseType>, string> = useMemo(
    () => ({
      testing: 'sales_management.phases.testing',
      intermediate: 'sales_management.phases.intermediate',
    }),
    [],
  );

  const addPeriodBefore = () => {
    const dates = { start_date: getValues('start_date'), end_date: getValues('end_date') };
    const previousPeriod = getValues('contract_sub_periods_attributes')?.[index - 1];
    const currentPeriod = getValues('contract_sub_periods_attributes')?.[index];
    const currentStartDate = dayjs(currentPeriod.dates?.start_date).startOf('day');
    const contractType = getValues('type');

    if (index > 0) {
      const previousEndDate = dayjs(previousPeriod.dates?.end_date).startOf('day');

      // Check if there's space between this period and the previous one
      if (previousEndDate.add(1, 'day').valueOf() < currentStartDate.valueOf()) {
        dates.start_date = previousEndDate.add(1, 'day').startOf('day').toDate();
        dates.end_date = currentStartDate.subtract(1, 'day').endOf('day').toDate();
      } else {
        // Otherwise just give it a length of one day (but it will clash).
        dates.start_date = currentStartDate.subtract(2, 'day').endOf('day').toDate();
        dates.end_date = currentStartDate.subtract(1, 'day').endOf('day').toDate();
      }
    } else if (index === 0 && currentStartDate.valueOf() !== dayjs(getValues('start_date')).startOf('day').valueOf()) {
      // If there's no previous period, and there's space between it and the start of the contract:
      dates.end_date = currentStartDate.subtract(1, 'day').endOf('day').toDate();
    }

    if (!contractType) return;

    const newPeriod = makeSubPeriod(contractType, {
      start_date: formatSystemDate(dates.start_date),
      end_date: formatSystemDate(dates.end_date),
      price_model_attributes: currentPeriod?.price_model_attributes ?? null,
    });

    subPeriods?.insert(index, newPeriod);
    trigger('contract_sub_periods_attributes');
  };

  const onRemovePeriod = () => {
    subPeriods?.remove(index);
    trigger('contract_sub_periods_attributes');
  };

  const period = watch(`contract_sub_periods_attributes.${index}`);
  const phaseType = period.phase_type;
  const title = (!!phaseType && titleMap[phaseType]) || `${t('sales_management.phases.period')} ${index + 1}`;
  const startDate = period.dates?.start_date;
  const endDate = period.dates?.end_date;
  const formattedDates = `${formatSystemDate(startDate)} / ${formatSystemDate(endDate)}`;
  const periodError = getFieldState(`contract_sub_periods_attributes.${index}`)?.error;
  const marketFutureName = formatMarketFutureDate(period.market_future, locale);
  const subPeriodIsCollapsed = isCollapsed?.(periodId);

  return (
    <div className={classNames(styles.header, subPeriodIsCollapsed && styles['is-collapsed'])}>
      <div className={styles.title}>
        <span onClick={() => toggleSubPeriod?.(periodId)}>{t(title)}</span>
        {subPeriodIsCollapsed && (
          <div className={classNames(styles['collapsed-dates'], periodError && styles['has-error'])}>
            <span className={styles['collapsed-prefix']}>&ndash;&nbsp;</span>
            <Badge variant={periodError ? 'danger' : 'primary-1'} label={marketFutureName} />{' '}
            <span>{formattedDates}</span>
            {periodError && (
              <Icon
                name="AlertCircle"
                title="common.contains_errors"
                size={14}
                className={styles['collapsed-warning']}
              />
            )}
          </div>
        )}
      </div>
      {!readonly && (
        <>
          <Button
            icon="Plus"
            title="sales_management.add_period_before"
            onClick={addPeriodBefore}
            disabled={phaseType === 'testing'}
            floating
          />
          <Button
            icon="Trash2"
            variant="secondary"
            title="common.delete"
            data-cy="delete-sub-period"
            onClick={onRemovePeriod}
            disabled={subPeriods?.fields && subPeriods.fields.length <= 1}
            className="ml-1"
            floating
          />
        </>
      )}
    </div>
  );
};
