import React from 'react';
import { Input } from '@GDM/forms';
import { getSymbol } from '@utils/currency/getSymbol';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const AggregationFee: React.FC = () => {
  const { control, readonly, watch } = useContractForm();
  const { fieldRules } = useContractFields();
  const currency = watch('currency');

  return (
    <Controller
      name="aggregation_fee"
      control={control}
      rules={fieldRules('aggregation_fee')}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          label="sales_management.aggreg_fee"
          size="lg"
          suffix={`${getSymbol(currency)}/MW`}
          type="number"
          value={field.value ?? ''}
          hasError={!!fieldState.error}
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
