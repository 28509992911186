import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { HelpBox } from '@GDM/HelpBox';
import classNames from 'classnames';
import { useProgressStepper } from '../context/useProgressStepper';
import styles from './aside.module.scss';

export const Aside: React.FC = () => {
  const { infoBox } = useProgressStepper();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const offsetTopWrapper = (wrapperRef.current?.getBoundingClientRect().top ?? 0) + window.scrollY;
  const cappedOffsetTop =
    (infoBox?.offsetTop ?? 0) < offsetTopWrapper ? 0 : (infoBox?.offsetTop ?? 0) - offsetTopWrapper;

  const [helpBoxStyle, setHelpBoxStyle] = useState<CSSProperties | undefined>(
    infoBox && cappedOffsetTop ? { transform: `translateY(calc(${cappedOffsetTop}px - 1em))` } : undefined,
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      if (!wrapperRef.current) return;

      const helpBox = wrapperRef.current.children[0]?.getBoundingClientRect();
      const containerHeight = wrapperRef.current?.getBoundingClientRect().height;

      if (!helpBox || !containerHeight) return;

      if (helpBox.height > containerHeight - cappedOffsetTop) {
        setHelpBoxStyle({ bottom: 0 });
      } else {
        setHelpBoxStyle(
          infoBox && cappedOffsetTop ? { transform: `translateY(calc(${cappedOffsetTop}px - 1em))` } : undefined,
        );
      }
    });
  }, [cappedOffsetTop, infoBox]);

  return (
    <div className={classNames(styles.aside)} ref={wrapperRef}>
      {infoBox && (
        <HelpBox
          variant="light"
          title={infoBox.title}
          text={infoBox.text}
          style={helpBoxStyle}
          className={styles['help-box']}
        />
      )}
    </div>
  );
};
