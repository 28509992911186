import React from 'react';
import { Input, useField } from '@GDM/forms';
import { Controller, useFormContext } from 'react-hook-form';
import type { OwnerForm } from '../../../ownerForm.types';

export const CityInput = () => {
  const form = useFormContext<OwnerForm>();
  const { rules, disabled } = useField('city');

  return (
    <Controller
      control={form.control}
      name="city"
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          type="text"
          label="common.city"
          size="lg"
          full
          disabled={disabled}
          data-cy="city"
          hasError={!!fieldState.error}
          errorMessage={fieldState.error?.message}
        />
      )}
    />
  );
};
