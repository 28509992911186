import { useCallback } from 'react';
import { post, useRequest } from '@hooks/useRequest';
import { v2_contracts_validate_seller_uniqueness_path } from '@utils/routes';
import { useContractForm } from './useContractForm';

export const useValidateUniquenessRequest = (): {
  /**
   * Runs an async validation that checks against the API if the provided combination of
   * installation, seller, dates and type is unique and not already covered by another contract.
   */
  validate: () => Promise<void>;
  loading: boolean;
} => {
  const { getValues, setError, clearErrors, trigger } = useContractForm();
  const { execute, loading } = useRequest(v2_contracts_validate_seller_uniqueness_path(), post, true);

  const validate = useCallback(async () => {
    const [seller_id, buyer_id, start_date, end_date, id, contractType, installation_uuid] = getValues([
      'seller_id',
      'buyer_id',
      'start_date',
      'end_date',
      'id',
      'type',
      'installation_uuid',
    ]);

    if (seller_id && buyer_id && start_date && end_date && contractType && installation_uuid) {
      const type = contractType.replace('-LIGHT', '');

      const response = await execute?.({ seller_id, buyer_id, start_date, end_date, id, type, installation_uuid });

      // If it's valid, it will return nothing, otherwise it will return an error message.
      if (response) {
        setError('type', { message: response });
        setError('installation_uuid', { message: response });
        setError('start_date', { message: response });
        setError('end_date', { message: response });
        setError('buyer_id', { message: response });
      } else {
        clearErrors(['type', 'installation_uuid', 'start_date', 'end_date']);
        // Make sure other errors appear
        trigger(['type', 'installation_uuid', 'start_date', 'end_date']);
      }
    }
  }, [clearErrors, execute, getValues, setError, trigger]);

  return { validate, loading };
};
