import React from 'react';
import { SingleSelectProps, MultiSelectProps } from '@GDM/forms';
import { Select } from '@GDM/forms/Select/Select';
import { Option } from '@utils/types/common-types';
import { FieldValues, Path, ControllerProps, PathValue, Controller } from 'react-hook-form';

export default function ControlledSelect<T extends FieldValues>({
  name,
  control,
  options,
  rules,
  shouldUnregister,
  defaultValue,
  ...selectProps
}: Omit<
  SingleSelectProps<PathValue<T, Path<T>>> | MultiSelectProps<PathValue<T, Path<T>>[number]>,
  'onChange' | 'selectedOptions' | 'selectedOption'
> &
  Omit<ControllerProps<T>, 'render'>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <Select
          {...selectProps}
          name={field.name}
          options={options}
          onChange={(option: Option<PathValue<T, Path<T>>> | Option<PathValue<T, Path<T>>>[] | null) => {
            const value = Array.isArray(option) ? option.map(({ value }) => value) : option?.value;
            field.onChange(value);
          }}
          selectedOption={!selectProps.isMulti ? field.value : undefined}
          selectedOptions={(selectProps.isMulti && field.value) || undefined}
          errorMessage={fieldState.error?.message}
          hasError={Boolean(fieldState.error)}
        />
      )}
    />
  );
}
