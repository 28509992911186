import React from 'react';
import { Toggle } from '@GDM/Toggle';
import { Controller } from 'react-hook-form';
import { useContractForm } from '../../../hooks';

export const SentToRemit: React.FC = () => {
  const { control, readonly } = useContractForm();

  return (
    <Controller
      name="sent_to_remit"
      control={control}
      render={({ field }) => (
        <Toggle
          name={field.name}
          value={field.value}
          onChange={(value) => field.onChange(value)}
          label="sales_management.sent_to_remit"
          tooltip="sales_management.remit_tooltip"
          readOnly={readonly}
        />
      )}
    />
  );
};
