import React from 'react';
import { RadioButtons, RadioButtonsProps, RadioOption } from '@GDM/forms';
import { FieldValues, Path, Control, PathValue, Controller } from 'react-hook-form';

export default function ControlledRadioButtons<T extends FieldValues>({
  name,
  control,
  options,
  afterChange,
  ...radioButtonsProps
}: Omit<RadioButtonsProps<PathValue<T, Path<T>>>, 'onChange' | 'options' | 'selected'> & {
  name: Path<T>;
  control: Control<T>;
  options: RadioOption<PathValue<T, Path<T>>>[];
  afterChange?: (optionValue?: PathValue<T, Path<T>>) => void;
  placeholder?: string;
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RadioButtons
          {...radioButtonsProps}
          onChange={(e) => {
            field.onChange(e);
            if (afterChange) afterChange(e);
          }}
          options={options}
          selected={field.value}
        />
      )}
    />
  );
}
