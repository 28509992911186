import React from 'react';
import { InstallationProvider } from '@components/Installations/InstallationProvider';
import Page from '@pages/Page';
import MarketPlayer from '@utils/types/market_player';
import { User } from '@utils/types/user';
import { contractPageContext, ContractFormProvider } from './context';
import styles from './contract.module.scss';
import { EditContract } from './EditContract';
import { NewContract } from './NewContract';
import { PageActions } from './PageActions';
import { PageSubtitle } from './PageSubtitle';
import { ViewContract } from './ViewContract';

const Contract: React.FC<{ user: User; contractUuid?: string; offtaker?: MarketPlayer; readonly?: boolean }> = ({
  user,
  contractUuid,
  offtaker,
  readonly,
}) => {
  let title = 'contracts.titles.new';
  if (readonly) title = 'contracts.titles.view';
  if (!readonly && contractUuid) title = 'contracts.titles.edit';

  return (
    <InstallationProvider type="operational_with_draft" withMeterInfo>
      <ContractFormProvider
        uuid={contractUuid}
        marketPlayer={offtaker}
        readonly={readonly}
        containerClassName={styles['full-height-layout']}
        user={user}
      >
        <contractPageContext.Consumer>
          {(context) => (
            <Page
              title={title}
              subtitle={<PageSubtitle />}
              error={context.errorMessage}
              isLoading={context.isLoading}
              user={user}
              pageActions={<PageActions />}
              layout="no-background"
            >
              {!contractUuid && <NewContract />}
              {contractUuid && !readonly && <EditContract contractUuid={contractUuid} />}
              {contractUuid && readonly && <ViewContract contractUuid={contractUuid} />}
            </Page>
          )}
        </contractPageContext.Consumer>
      </ContractFormProvider>
    </InstallationProvider>
  );
};

export default Contract;
