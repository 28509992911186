import React from 'react';
import classNames from 'classnames';
import styles from './section.module.scss';

export const Section: React.FC<React.PropsWithChildren<{ className?: string; 'data-cy'?: string }>> = ({
  children,
  className,
  'data-cy': dataCy,
}) => {
  return (
    <div className={classNames(styles.container, className)} data-cy={dataCy}>
      {children}
    </div>
  );
};
