import React, { useMemo } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { ContractSubType } from '@utils/types/contract_sub_type';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';
import { SUB_TYPES } from './sub-types';

export const SubTypePicker: React.FC = () => {
  const { control, watch, readonly } = useContractForm();
  const { fieldRules, onChangeField } = useContractFields();
  const { installations } = useInstallationsContext();
  const [contractType, installationUuid] = watch(['type', 'installation_uuid']);
  const energyType = installations?.find(({ uuid }) => uuid === installationUuid)?.energy;

  const options = useMemo<Option<ContractForm['sub_type']>[]>(() => {
    let subTypes: ContractSubType[] = [];
    if (contractType && energyType) subTypes = SUB_TYPES[contractType]?.[energyType] || [];
    if (!subTypes.length && contractType) subTypes = SUB_TYPES[contractType]?.['default'] || [];

    return subTypes.map((i) => ({ value: i, label: i.toUpperCase() }));
  }, [contractType, energyType]);

  return (
    <Controller
      name="sub_type"
      control={control}
      rules={fieldRules('sub_type')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.sub_type_select"
          size="lg"
          options={options}
          selectedOption={field.value}
          onChange={(option) => onChangeField(field, option?.value)}
          classNamePrefix="select-sub-type"
          readOnly={readonly}
          hasError={!!fieldState.error}
          isSearchable
        />
      )}
    />
  );
};
