import { formatSystemDate } from '@utils/formatters';
import { ContractForm } from '@utils/types/contract';
import dayjs from 'dayjs';

const start_date = new Date();
const end_date = dayjs(start_date).add(1, 'year').toDate();

export const SUB_PERIODS_DEFAULT_VALUE: ContractForm['contract_sub_periods_attributes'] = [
  {
    id: null,
    start_date: formatSystemDate(start_date),
    end_date: formatSystemDate(end_date),
    dates: {
      start_date: new Date(start_date),
      end_date: new Date(end_date),
    },
    display_price: null,
    aggreg_fees: [{ value: null, type: 'fixed' }],
    price_model: null,
    price_model_attributes: null,
    price_rule: null,
    price_unit: null,
    multiplier: null,
  },
];

export const FORM_DEFAULT_VALUES: Partial<ContractForm> = {
  end_date,
  installation_uuid: null,
  buyer_id: null,
  seller_id: null,
  start_date,
  status: null,
  sub_type: null,
  type: null,
  power_augmentation: false,
  contract_sub_periods_attributes: SUB_PERIODS_DEFAULT_VALUE,
};
