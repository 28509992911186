import React, { useMemo } from 'react';
import { Select } from '@GDM/forms';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { Option } from '@utils/types/common-types';
import { PriceModel } from '@utils/types/contract';
import { Controller } from 'react-hook-form';
import { getSourceOptions } from './options';

export const SourcePicker: React.FC<{ index: number }> = ({ index }) => {
  const { control, watch, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  const [area] = watch([`contract_sub_periods_attributes.${index}.price_model_attributes.area`]);

  const options: Option<PriceModel['source']>[] = useMemo(() => getSourceOptions(area), [area]);

  // Hide the picker if there's only one option because it can be inferred from the area.
  // This kind of logic should be avoided as much as possible and the config files should
  // be used instead, but in this case it makes sense.
  if (options.length <= 1) return null;

  return (
    <Controller
      name={`contract_sub_periods_attributes.${index}.price_model_attributes.source`}
      control={control}
      rules={fieldRules(`contract_sub_periods_attributes.${index}.price_model_attributes.source`)}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.price_model.source"
          size="lg"
          options={options}
          selectedOption={field.value}
          onChange={(option) => field.onChange(option?.value)}
          isDisabled={!area}
          tooltip={!area ? 'sales_management.price_model.source_needs_area' : null}
          classNamePrefix={`select-price-model-source-${index}`}
          readOnly={readonly}
          hasError={!!fieldState.error}
        />
      )}
    />
  );
};
