import React from 'react';
import { Step } from '@GDM/forms';
import { FormSection } from '@pages/Contracts/Contract/contracts.types';
import { SectionHeader } from '../../components';
import {
  AdditionalInfo,
  Cap,
  ContractParams,
  GeneralInfo,
  HedgeBlockHeaderActions,
  HedgeBlocks,
  Pricing,
  Remit,
  SubPeriodHeaderActions,
  SubPeriods,
} from '../../sections';
import { InvoiceSettings } from '../../sections/InvoiceSettings';
import { Taxes } from '../../sections/Taxes/Taxes';

/**
 * Steps that will appear for every contract type and are not configurable.
 * Anything here will appear by default before the user has chosen a contract type.
 */
export const DEFAULT_STEPS: Partial<Record<FormSection, Step>> = {
  'general-information': {
    title: 'contracts.steps.general_information',
    progress: 40,
    component: <GeneralInfo />,
    headerActions: <SectionHeader />,
  },
};

/**
 * All steps that will appear based on the contract type chosen by the user.
 * The order in which they appear is determined by the order in which they are defined here.
 */
export const VARIABLE_STEPS: Partial<Record<FormSection, Step>> = {
  'additional-info': {
    title: 'contracts.steps.additional_info',
    progress: 0,
    component: <AdditionalInfo />,
    headerActions: <SectionHeader />,
  },
  'contract-params': {
    title: 'contracts.steps.contract_params',
    progress: 0,
    component: <ContractParams />,
    headerActions: <SectionHeader />,
  },
  pricing: {
    title: 'contracts.steps.pricing',
    progress: 0,
    component: <Pricing />,
    headerActions: <SectionHeader />,
  },
  cap: {
    title: 'contracts.steps.cap',
    progress: 0,
    component: <Cap />,
    headerActions: <SectionHeader />,
  },
  taxes: {
    title: 'common.taxes',
    progress: 0,
    component: <Taxes />,
    headerActions: <SectionHeader />,
  },
  'sub-periods': {
    title: 'contracts.steps.sub_periods',
    progress: 0,
    component: <SubPeriods />,
    headerActions: <SubPeriodHeaderActions />, // 'Inherits' from SectionHeader
  },
  'hedge-blocks': {
    title: 'contracts.steps.hedge_blocks',
    progress: 0,
    component: <HedgeBlocks />,
    headerActions: <HedgeBlockHeaderActions />, // 'Inherits' from SectionHeader
  },
  'invoicing-settings': {
    title: 'contracts.steps.invoice_settings',
    progress: 0,
    component: <InvoiceSettings />,
    headerActions: <SectionHeader />,
  },
  remit: {
    title: 'common.remit',
    progress: 0,
    component: <Remit />,
    headerActions: <SectionHeader />,
  },
};
