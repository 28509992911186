import React, { useContext } from 'react';
import { DropDownButton } from '@GDM/Button';
import { DatePicker } from '@GDM/DatePicker';
import { FilterContainer, Filters } from '@GDM/Filters';
import { Checkbox, Input, RadioButtons } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { energyOptions } from '@utils/constants/energyOptions';
import { EnergyType } from '@utils/types/common-types';
import { EventType } from '@utils/types/event';
import classNames from 'classnames';
import { BookFilter } from '../BookFilter';
import { filteredEventsContext } from '../filtered-events.context';
import styles from './events-filters.module.scss';

type Filters = {
  installationName: string;
  installationEnergy: EnergyType | 'all';
  eventType: EventType[];
};
type Props = {
  light?: boolean;
};

export const EventsFilters = ({ light }: Props) => {
  const { t } = useTranslation();

  const { setFilters, filters, type, startDate, setStartDate, endDate, setEndDate } = useContext(filteredEventsContext);

  const onEventTypeChange = (eventType: EventType) => () => {
    const eventTypesSet = new Set(filters.eventTypes || []);
    if (eventTypesSet.has(eventType)) eventTypesSet.delete(eventType);
    else eventTypesSet.add(eventType);
    setFilters({ ...filters, eventTypes: [...eventTypesSet.values()] });
  };

  return (
    <Filters className={classNames('p-0', styles['events-filters'])}>
      <FilterContainer size="datepicker">
        <DatePicker
          startDate={startDate}
          endDate={endDate}
          onChange={(dates) => {
            if (Array.isArray(dates)) {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            } else {
              setStartDate(null);
              setEndDate(null);
            }
          }}
          maxDate={new Date()}
          hasPreDefinedPeriods
          selectsRange
        />
      </FilterContainer>

      {!light && (
        <>
          <FilterContainer>
            <Input
              placeholder={t('common.installation')}
              value={filters.installationName}
              onChange={(e) => setFilters({ ...filters, installationName: e.target.value })}
              full
            />
          </FilterContainer>

          <FilterContainer size="fit">
            <BookFilter onChange={(installationNames) => setFilters({ ...filters, installationNames })} />
          </FilterContainer>

          <FilterContainer size="fit">
            <RadioButtons
              name="energy-type"
              options={energyOptions}
              onChange={(energyType) => setFilters({ ...filters, installationEnergy: energyType })}
              selected={filters.installationEnergy}
            />
          </FilterContainer>
        </>
      )}

      <FilterContainer size="fit">
        <DropDownButton label="common.type" icon="Filter" size="xs" dropDownMenuClassName="p-3">
          <div className={styles['events-filters-dropdown']}>
            {(!type || type === 'monitoring') && (
              <>
                <Checkbox
                  checked={filters.eventTypes.includes('ProductionStop')}
                  onChange={onEventTypeChange('ProductionStop')}
                  label={t('monitoring.alerts.modal.production_stop')}
                />
                <Checkbox
                  checked={filters.eventTypes.includes('HighUnderPerformance')}
                  onChange={onEventTypeChange('HighUnderPerformance')}
                  label={t('monitoring.alerts.modal.high_under_performance')}
                />
                <Checkbox
                  checked={filters.eventTypes.includes('OutOfRangeReactive')}
                  onChange={onEventTypeChange('OutOfRangeReactive')}
                  label={t('monitoring.alerts.modal.out_of_range_reactive')}
                />
                <Checkbox
                  checked={filters.eventTypes.includes('CommunicationFailure')}
                  onChange={onEventTypeChange('CommunicationFailure')}
                  label={t('monitoring.alerts.modal.communication_failure')}
                />
                <Checkbox
                  checked={filters.eventTypes.includes('UnderPerformance')}
                  onChange={onEventTypeChange('UnderPerformance')}
                  label={t('monitoring.alerts.modal.under_performance')}
                />
              </>
            )}

            {(!type || type === 'sales') && (
              <>
                <Checkbox
                  checked={filters.eventTypes.includes('AnnualProductionExceedsCap')}
                  onChange={onEventTypeChange('AnnualProductionExceedsCap')}
                  label={t('monitoring.alerts.modal.prod_annual_exceeds_cap')}
                />
                <Checkbox
                  checked={filters.eventTypes.includes('AnnualProductionExceedsDafr')}
                  onChange={onEventTypeChange('AnnualProductionExceedsDafr')}
                  label={t('monitoring.alerts.modal.prod_annual_exceeds_dafr')}
                />
                <Checkbox
                  checked={filters.eventTypes.includes('ProductionOnNegativePrice')}
                  onChange={onEventTypeChange('ProductionOnNegativePrice')}
                  label={t('monitoring.alerts.modal.prod_on_negative_price')}
                />
                <Checkbox
                  checked={filters.eventTypes.includes('InvoiceMissingData')}
                  onChange={onEventTypeChange('InvoiceMissingData')}
                  label={t('monitoring.alerts.modal.invoice_missing_data')}
                />
                <Checkbox
                  checked={filters.eventTypes.includes('HighDiffPublishedProduction')}
                  onChange={onEventTypeChange('HighDiffPublishedProduction')}
                  label={t('monitoring.alerts.modal.high_diff_published_production')}
                />
              </>
            )}
          </div>
        </DropDownButton>
      </FilterContainer>
    </Filters>
  );
};
