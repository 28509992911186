import fetchWrapper from '@utils/fetch';
import { all_v2_contracts_path, v2_contracts_indices_periods_path } from '@utils/routes';
import { Contract } from '@utils/types/contract';
import { ContractIndices } from '@utils/types/contract/indices';

export const getContracts = async (): Promise<Contract[]> => {
  const response = await fetchWrapper(all_v2_contracts_path());

  return response.json();
};

export const getContractIndices = async (id: string): Promise<ContractIndices> => {
  const response = await fetchWrapper(v2_contracts_indices_periods_path(id));

  return response.json();
};
