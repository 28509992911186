import { EnergyType } from '@utils/types/common-types';
import { ContractType } from '@utils/types/contract';
import {
  contractCrEdfHydroSubTypes,
  contractCrEdfSolarSubTypes,
  contractCrEdfWindSubTypes,
  contractOaSubTypes,
  ContractSubType,
} from '@utils/types/contract_sub_type';

export const SUB_TYPES: Partial<Record<ContractType, { [key in EnergyType | 'default']?: ContractSubType[] }>> = {
  ContractOa: {
    default: [...contractOaSubTypes],
  },
  ContractCrEdf: {
    wind: [...contractCrEdfWindSubTypes],
    solar: [...contractCrEdfSolarSubTypes],
    hydro: [...contractCrEdfHydroSubTypes],
  },
};
