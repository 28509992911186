import React, { useMemo } from 'react';
import { useRequest } from '@hooks/useRequest';
import { v2_price_rules_path } from '@utils/routes';
import { PriceRule } from '@utils/types/contract';
import { priceRulesContext } from './price-rules.context';

export const PriceRulesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: priceRules, loading, error } = useRequest<PriceRule[]>(v2_price_rules_path(), 'GET');

  const contextValue = useMemo(
    () => ({ isLoading: loading, errorMessage: error?.message ?? null, priceRules }),
    [error?.message, loading, priceRules],
  );

  return <priceRulesContext.Provider value={contextValue}>{children}</priceRulesContext.Provider>;
};
