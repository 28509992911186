import React from 'react';
import { NumberCell } from '@GDM/Table';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { ColumnDef } from '@tanstack/react-table';
import { isANumber } from '@utils/isANumber';
import { ActivityTrack } from '@utils/types/activity-track';

export const ActivityTrackColumns = () => {
  const columns: ColumnDef<ActivityTrack>[] = [
    {
      header: 'common.email',
      accessorFn: (row) => row.email,
    },
    {
      header: 'common.start_date',
      accessorKey: 'start_date',
    },
    {
      header: 'common.end_date',
      accessorKey: 'end_date',
    },
    {
      header: 'subscriptions.contracted_power',
      accessorKey: 'contracted_power_in_kw',
      cell: ({ getValue }) => (
        <div className="ta:right">
          <ValueWithUnit value={isANumber(getValue()) ? getValue<number>() * 1000 : null} unit="kW" />
        </div>
      ),
    },
    {
      header: 'subscriptions.forecast_meters',
      accessorKey: 'nb_forecast_meters',
      cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
    },
    {
      header: 'subscriptions.number_of_meters',
      accessorKey: 'nb_meters',
      cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
    },
  ];

  return columns;
};
