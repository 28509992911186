import { ContractFormFieldArrays } from '@utils/types/contract';
import { ContractFormField } from '../../contracts.types';

/**
 * Makes sure all fieldArray field values are included in the count.
 * @param baseRequiredFields contains the required fields and potentially the required fields of the first element of the field array.
 * @param fieldArrays a map of all the required fieldArray types
 */
export function getRequiredFields(
  baseRequiredFields: ContractFormField['name'][],
  fieldArrays: Partial<ContractFormFieldArrays>,
): ContractFormField['name'][] {
  // Find the props that are field array values
  const fieldArrayProps = baseRequiredFields.filter((field) => field.match(/.([0-9]+)./));

  // If there are no field names with '.' in them, then there's nothing to add.
  if (!fieldArrayProps.length) return baseRequiredFields;

  // Go through the list of field array types ('contract_sub_periods' | 'contract_sub_periods_attributes'...)
  // and construct a list with each prop that's required.
  const requiredFieldArrayNames: ContractFormField['name'][] = Object.entries(fieldArrays)
    .map(([fieldArrayName, fieldArray]) => {
      const fieldArrayRequiredProps = fieldArrayProps
        // Take the fields that are prepended with the current e.g `contract_sub_periods_attributes.0.display_price` ...
        .filter((prop) => prop.includes(fieldArrayName))
        // Take everything after the field array index (can be more than
        // one level deep, e.g.: extra_params.swap_price_type)
        .map((field) => field.match(/[a-z0-9_-]+.[0-9]+.([a-z0-9._-]+)/)?.[1]);

      const fieldArrayNames: ContractFormField['name'][] = [];

      if (!fieldArray?.length || !fieldArrayRequiredProps?.length) return [];

      for (let i = 0; i < fieldArray.length; i++) {
        const indexedFieldNames = fieldArrayRequiredProps.map(
          (name) => `${fieldArrayName}.${i}.${name}` as ContractFormField['name'],
        );
        fieldArrayNames.push(...indexedFieldNames);
      }

      return fieldArrayNames;
    })
    .flat();

  return Array.from(new Set([...baseRequiredFields, ...requiredFieldArrayNames]));
}
