import React, { useMemo } from 'react';
import { Select } from '@GDM/forms';
import useIndexFormulas from '@hooks/requests/useIndexFormulas';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';
import { CONTRACT_TYPES_WITH_EDF_FORMULAS } from './contract-types-with-edf-formulas';

export const IndexFormulaPicker: React.FC = () => {
  const { control, watch, readonly } = useContractForm();
  const { fieldRules } = useContractFields();
  const { data: indexFormulas, loading: indexFormulasLoading } = useIndexFormulas();
  const contractType = watch('type');

  const options: Option<ContractForm['index_formula']>[] = useMemo(() => {
    if (!indexFormulas) return [];
    const { edfFormulas, formulas } = indexFormulas;
    const filteredFormulaList = CONTRACT_TYPES_WITH_EDF_FORMULAS.includes(contractType) ? edfFormulas : formulas;

    return [
      ...filteredFormulaList?.map(({ formula }) => ({
        label: formula,
        value: formula,
      })),
    ];
  }, [contractType, indexFormulas]);

  return (
    <Controller
      name="index_formula"
      control={control}
      rules={fieldRules('index_formula')}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          label="sales_management.index_formula_select"
          menuPlacement="top"
          size="lg"
          options={options}
          isLoading={indexFormulasLoading}
          selectedOption={field.value}
          onChange={(option) => field.onChange(option?.value)}
          tooltip={!readonly ? 'sales_management.index_formula_missing' : null}
          classNamePrefix="select-index-formula"
          readOnly={readonly}
          hasError={!!fieldState.error}
          isSearchable
        />
      )}
    />
  );
};
