import React from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { Input } from '@GDM/forms';
import { useContractForm } from '../../../hooks';

// READONLY FIELD
export const ExternalRef: React.FC = () => {
  const { watch } = useContractForm();
  const { installations } = useInstallationsContext();
  const installationUuid = watch('installation_uuid');
  const installation = installations?.find(({ uuid }) => uuid === installationUuid);

  return (
    <Input
      name="_non_contract_props.installation.external_ref"
      value={installation?.external_ref ?? ''}
      label="sales_management.configurable_fields._non_contract_props.installation.external_ref"
      size="lg"
      readOnly
      full
    />
  );
};
