import React from 'react';
import { Input } from '@GDM/forms';
import { Text } from '@GDM/Text';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { getSymbol } from '@utils/currency/getSymbol';
import { Controller } from 'react-hook-form';

export const SpotFee: React.FC = () => {
  const { control, readonly, trigger, watch } = useContractForm();
  const { fieldConfig } = useContractFields();
  const currency = watch('currency');
  const config = fieldConfig('hedge_contract.spot_fee');

  return (
    <Controller
      name="hedge_contract.spot_fee"
      control={control}
      rules={config?.rules}
      render={({ field, fieldState }) => (
        <>
          <Input
            {...field}
            data-cy="hedge-spot-fee"
            label="common.spot_fee"
            size="lg"
            type="number"
            step="0.01"
            min={0}
            onChange={async (e) => {
              field.onChange(e);
              if (await trigger('hedge_blocks_attributes')) await trigger('hedge_blocks_attributes');
            }}
            value={field.value ?? ''}
            hasError={!!fieldState.error}
            suffix={config?.unit || `${getSymbol(currency)}/MWh`}
            autoComplete="off"
            readOnly={readonly}
            tooltip="sales_management.hedge_info.spot_fee"
            full
          />
          {fieldState.error && <Text className="mt-1" size="sm" text={fieldState?.error?.message} type="danger" />}
        </>
      )}
    />
  );
};
