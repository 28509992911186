/**
 * This is a list of all the sections in the contract form. All types will be derived from this list.
 */
export const FORM_SECTION_NAMES = [
  'additional-info',
  'cap',
  'contract-params',
  'general-information',
  'hedge-blocks',
  'invoicing-settings',
  'pricing',
  'remit',
  'sub-periods',
  'taxes',
] as const;
