import React, { useCallback, useMemo } from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { Text } from '@GDM/Text';
import useMarketFutures from '@hooks/requests/useMarketFutures';
import { endOfMonth, endOfPreviousMonth, startOfNextMonth } from '@utils/date';
import { ContractForm, ContractSubPeriodForm } from '@utils/types/contract';
import dayjs from 'dayjs';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { useContractForm } from '../../../../hooks';
import styles from '../hedge-block.module.scss';

export const DatesPicker: React.FC<{ index: number }> = ({ index }) => {
  const { control, getValues, setValue, watch, trigger, readonly } = useContractForm();

  const contractDates = watch(['start_date', 'end_date']);

  const minDate = startOfNextMonth(contractDates[0]);
  const maxDate = endOfPreviousMonth(contractDates[1]);

  const { data: marketFutures, error } = useMarketFutures(minDate, maxDate);

  const preSelectedPeriod = marketFutures?.find(
    (i) => i.id === getValues(`hedge_blocks_attributes.${index}.market_future_id`),
  )?.name;

  const customPeriods: Array<{ name: string; dates: [Date, Date] }> = useMemo(
    () => [
      ...(marketFutures?.map(({ name, start_date, end_date }) => ({
        name,
        dates: [new Date(start_date), new Date(end_date)] as [Date, Date],
      })) || []),
      { name: 'sales_management.contract_dates', dates: contractDates },
    ],
    [contractDates, marketFutures],
  );

  const onChange = useCallback(
    (
      value: [Date | null, Date | null],
      field: ControllerRenderProps<ContractForm, `hedge_blocks_attributes.${number}.dates`>,
    ): void => {
      field.onChange({
        ...field.value,
        start_date: value[0],
        end_date: value[1] && endOfMonth(value[1]),
      });
      trigger('hedge_blocks_attributes');
    },
    [trigger],
  );

  const onClickPeriod = useCallback(
    (period: string) => {
      const selected = marketFutures?.find((i) => i.name === period);

      if (selected) {
        setValue(`hedge_blocks_attributes.${index}.market_future`, selected);
        setValue(`hedge_blocks_attributes.${index}.market_future_id`, selected.id);
      }
    },
    [index, marketFutures, setValue],
  );

  const validateSubPeriodDates = useMemo(
    () => ({
      noValues: (dates?: ContractSubPeriodForm['dates']) => {
        return !!(dates?.start_date && dates?.end_date) || 'sales_management.sub_period_errors.no_values';
      },
      endBeforeStart: (dates?: ContractSubPeriodForm['dates']) => {
        if (!dates?.start_date || !dates?.end_date) return true;

        return !!(dates?.start_date < dates?.end_date) || 'sales_management.sub_period_errors.out_of_bounds';
      },
      beforeStartDate: (dates?: ContractSubPeriodForm['dates']) => {
        const contractDates = getValues(['start_date']);
        if (!dates?.start_date) return true;

        // Make sure we have exact matches for the dates (could be same day but different hours)
        const startDate = dayjs(dates.start_date).startOf('day').toDate().valueOf();
        const contractStartDate = dayjs(contractDates[0]).startOf('day').toDate().valueOf();

        return startDate >= contractStartDate || 'sales_management.sub_period_errors.before_start_date';
      },
      afterEndDate: (dates?: ContractSubPeriodForm['dates']) => {
        const contractDates = getValues(['end_date']);
        if (!dates?.end_date) return true;

        // Make sure we have exact matches for the dates (could be same day but different hours)
        const endDate = dayjs(dates.end_date).endOf('day').toDate();
        const contractEndDate = dayjs(contractDates[0]).endOf('day').toDate();

        return endDate <= contractEndDate || 'sales_management.sub_period_errors.after_end_date';
      },
    }),
    [getValues],
  );

  return (
    <div className={styles['date-picker-container']}>
      <Controller
        name={`hedge_blocks_attributes.${index}.dates`}
        control={control}
        rules={{ validate: validateSubPeriodDates }}
        render={({ field, fieldState }) => (
          <>
            <DatePicker
              {...field}
              // Makes React Form Hook Controller and React DatePicker work together
              // https://github.com/react-hook-form/react-hook-form/issues/9126#issuecomment-1298424347
              ref={(ref) => field.ref({ focus: ref?.setFocus })}
              label="sales_management.sub_period.start_end_dates"
              size="lg"
              startDate={field.value?.start_date}
              endDate={field.value?.end_date}
              onChange={(dates) => onChange(dates, field)}
              onClickPeriod={onClickPeriod}
              customPeriods={customPeriods}
              selectedPeriod={preSelectedPeriod}
              hasError={!!error || !!fieldState.error}
              maxDate={maxDate}
              minDate={minDate}
              data-cy={`hedge-dates-picker-${index}`}
              readOnly={readonly}
              picks="months"
              selectsRange
              showPeriodBadge
            />
            {error && (
              <Text
                className="mt-1"
                size="sm"
                text="sales_management.form_errors.problem_loading_market_future"
                type="danger"
              />
            )}
            {fieldState.error && <Text className="mt-1" size="sm" text={fieldState?.error?.message} type="danger" />}
          </>
        )}
      />
    </div>
  );
};
