import React, { createContext } from 'react';
import { EnergyType } from '@utils/types/common-types';
import { Event, EventType } from '@utils/types/event';

export type EventFilters = {
  installationNames: string[];
  installationName: string;
  installationEnergy: EnergyType | 'all';
  eventTypes: EventType[];
};

export type EventFiltersContext = {
  allEvents: Event[];
  setAllEvents: React.Dispatch<React.SetStateAction<EventFiltersContext['allEvents']>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<EventFiltersContext['startDate']>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<EventFiltersContext['endDate']>>;
  filteredEvents: Event[];
  setFilters: (filters: EventFilters) => void;
  filters: EventFilters;
  type?: 'monitoring' | 'sales';
};

export const filteredEventsContext = createContext<EventFiltersContext>({
  allEvents: [],
  setAllEvents: () => null,
  startDate: null,
  setStartDate: () => null,
  endDate: null,
  setEndDate: () => null,
  filteredEvents: [],
  setFilters: () => {},
  filters: {
    installationNames: [],
    installationName: '',
    installationEnergy: 'all',
    eventTypes: [],
  },
});
