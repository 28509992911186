import React from 'react';
import { ContractSection } from '../../components';
import { useContractFields } from '../../hooks';
import { ExternalPdfLink } from './ExternalPdfLink';

export const AdditionalInfo: React.FC = () => {
  const { hasFields } = useContractFields();

  return <ContractSection className="p-3">{hasFields('external_pdf_link') && <ExternalPdfLink />}</ContractSection>;
};
