import { DataTypeFiltersForm as DataTypeFiltersForm } from '@utils/types/dataFilter';

// filter form
export const defaultValues: DataTypeFiltersForm = {
  showConso: true,
  showProdExtrapolated: true,
  showCurtailmentLine: true,
  showReactive: true,
  showPotentialPower: true,
  showProdForecast: true,
  showProdDispatch: true,
  showTension: false,
  showInverters: true,
  showProduction: true,
  showProdCorrected: true,
  showProdCorrectedHistory: true,
  showMarketPrices: false,
  showSurplus: true,
  showSelfConsumption: true,
};
