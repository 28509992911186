import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';
import { ContractType } from '@utils/types/contract';
import { CAPAFields } from './contract-types/CAPA';
import { CAPAProxyFields } from './contract-types/CAPAProxy';
import { CRFields } from './contract-types/CR';
import { CrEdfFields } from './contract-types/CR-EDF';
import { GOFields } from './contract-types/GO';
import { GO_LIGHTFields } from './contract-types/GO-LIGHT';
import { OAFields } from './contract-types/OA';
import { SOAFields } from './contract-types/SOA';
import { SOA_LIGHTFields } from './contract-types/SOA-LIGHT';
import { SWAPFields } from './contract-types/SWAP';

/**
 * Contains the fields specific to each contract type, sub-periods excluded.
 */
export const CONTRACT_TYPE_FIELDS: Record<ContractType, ContractFormField[]> = Object.freeze({
  ContractOa: OAFields,
  ContractCrEdf: CrEdfFields,
  ContractCr: CRFields,
  ContractSoa: SOAFields,
  // Corporate PPA has the same configuration as SOA, if this changes, create a new config file for CPPA
  ContractCppa: SOAFields,
  ContractCapaProxy: CAPAProxyFields,
  ContractCapa: CAPAFields,
  ContractGo: GOFields,
  // GC has the same configuration as GO, if this changes, create a new config file for GC
  ContractGc: GOFields,
  ContractSwap: SWAPFields,
  // 'Custom' contract types for GLSF installations. They are remapped before being sent to the BE
  'ContractGo-LIGHT': GO_LIGHTFields,
  'ContractSoa-LIGHT': SOA_LIGHTFields,
});
