import React from 'react';
import { Input } from '@GDM/forms';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const Ncc: React.FC = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  return (
    <Controller
      name="ncc"
      control={control}
      rules={fieldRules('ncc')}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          label="sales_management.ncc"
          size="lg"
          suffix="MW"
          type="number"
          value={field.value ?? ''}
          hasError={!!fieldState.error}
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
