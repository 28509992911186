import React from 'react';
import { Input } from '@GDM/forms';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const AnnualCap: React.FC = () => {
  const { control, readonly, getValues } = useContractForm();
  const { fieldRules } = useContractFields();

  const contractType = getValues('type');
  const energy = getValues('energy');

  if (contractType === 'ContractOa' && energy === 'wind') return null;

  return (
    <Controller
      name="annual_cap"
      control={control}
      rules={fieldRules('annual_cap')}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          label="sales_management.annual_cap"
          size="lg"
          type="number"
          value={field.value ?? ''}
          hasError={!!fieldState.error}
          suffix="kWh"
          data-cy="annual-cap"
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
