import { ContractFormField, FormSection } from '@pages/Contracts/Contract/contracts.types';
import { ContractType } from '@utils/types/contract';
import { CONTRACT_TYPE_FIELDS, FIELD_SECTIONS } from './config';

export const getSections = (contractType?: ContractType | null): FormSection[] => {
  const contractFields = contractType ? CONTRACT_TYPE_FIELDS[contractType] : null;

  if (!contractFields || !contractType) return [];

  const requiredSections = new Set<FormSection>();
  contractFields.forEach((value) => {
    const section = getFieldSection(value.name, contractType);

    if (section) requiredSections.add(section);
  });

  return Array.from(requiredSections);
};

export function getFieldSection(
  name: ContractFormField['name'],
  contractType?: ContractType | null,
): FormSection | undefined {
  // Name can be an indexed field array field, but we only define
  // the .0. field in the FIELD_SECTIONS Map, so we need to remap the index
  name = name.replaceAll(/\.[0-9]+\./g, '.0.') as ContractFormField['name'];
  const fieldDefinition = FIELD_SECTIONS.get(name);
  if (!fieldDefinition) {
    // eslint-disable-next-line no-console
    console.error(
      `Whoops! The field ${name} is not defined in the FIELD_SECTIONS Map.`,
      "Make sure it's properly added and that it's not misspelt.",
    );

    return;
  }

  if (typeof fieldDefinition === 'string') return fieldDefinition;

  return fieldDefinition[contractType as ContractType] || fieldDefinition.default;
}
