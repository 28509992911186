import React from 'react';
import { Input } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { useContractFields, useContractForm } from '@pages/Contracts/Contract/Form/hooks';
import { Controller } from 'react-hook-form';

export const SwapScaleFactor: React.FC<{ index: number }> = ({ index }) => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();
  const { t } = useTranslation();
  const rules = fieldRules(`contract_sub_periods_attributes.${index}.extra_params.swap_scale_factor`);

  return (
    <Controller
      name={`contract_sub_periods_attributes.${index}.extra_params.swap_scale_factor`}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          data-cy={`swap-scale-factor-${index}`}
          label="sales_management.swap_scale_factor"
          size="lg"
          type="number"
          value={field.value ?? ''}
          tooltip={
            fieldState.error &&
            t('errors.out_of_bounds', { max: `${rules?.max ?? '--'}`, min: `${rules?.min ?? '--'}` })
          }
          min={-1}
          max={1}
          step={0.01}
          hasError={!!fieldState.error}
          autoComplete="off"
          readOnly={readonly}
          full
        />
      )}
    />
  );
};
