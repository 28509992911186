import { nextYear } from '@utils/date';
import { formatSystemDate } from '@utils/formatters';
import { ContractSubPeriodForm, ContractType, PriceType } from '@utils/types/contract';
import { v4 as uuid } from 'uuid';
import { SUB_PERIODS_DEFAULT_VALUE } from '../../../../constants';

export const makeSubPeriod = (
  contract_type: ContractType,
  {
    start_date,
    end_date,
    phase_type = null,
    period_type,
    price_rule,
    price_model_attributes,
    market_future,
  }: Partial<ContractSubPeriodForm>,
): ContractSubPeriodForm => {
  let price_type: PriceType | null = contract_type === 'ContractCr' ? 'm0' : 'fixed';
  if (phase_type === 'testing') price_type = 'imbalance_pos';
  if (phase_type === 'intermediate') price_type = 'spot';

  let dates = null;
  if (start_date && end_date) dates = { start_date: new Date(start_date), end_date: new Date(end_date) };

  return {
    ...SUB_PERIODS_DEFAULT_VALUE[0],
    id: uuid(),
    start_date: start_date || formatSystemDate(),
    end_date: end_date || formatSystemDate(nextYear(new Date())),
    dates,
    price_type,
    phase_type,
    period_type: period_type || 'full',
    price_model_attributes: price_model_attributes ?? null,
    price_rule: price_rule ?? null,
    market_future,
    market_future_id: market_future?.id,
  };
};
