import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { isValidDate } from '@utils/isValidDate';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const SignatureDatePicker: React.FC = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  return (
    <Controller
      name="signature_date"
      control={control}
      rules={{ ...fieldRules('signature_date') }}
      render={({ field, fieldState }) => (
        <DatePicker
          {...field}
          label="sales_management.configurable_fields.signature_date"
          size="lg"
          selected={isValidDate(field.value) ? new Date(field.value || '') : null}
          hasError={!!fieldState.error}
          readOnly={readonly}
        />
      )}
    />
  );
};
