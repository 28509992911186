import React, { ReactNode } from 'react';
import { Input } from '@GDM/forms';
import { getSymbol } from '@utils/currency/getSymbol';
import { Controller } from 'react-hook-form';
import { useContractForm } from '../../../hooks';

export const ManagementBonus = (): ReactNode => {
  const { control, readonly, getValues } = useContractForm();

  const subType = getValues('sub_type');
  const currency = getValues('currency');

  const subTypeIsValid = subType === 'cr16' || subType === 'cr17';

  if (!readonly || !subTypeIsValid) return null;

  return (
    <Controller
      name="management_bonus"
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          label="invoice.revenues.types.management_fees"
          size="lg"
          type="number"
          value={field.value ?? ''}
          hasError={!!fieldState.error}
          data-cy="mandagement-fee"
          suffix={`${getSymbol(currency)}/MWh`}
          autoComplete="off"
          readOnly
          full
        />
      )}
    />
  );
};
