import React, { useMemo } from 'react';
import { RadioButtons, RadioOption } from '@GDM/forms';
import { ContractForm } from '@utils/types/contract';
import { Controller } from 'react-hook-form';
import { useContractFields, useContractForm } from '../../../hooks';

export const ParticipativeFinancing: React.FC = () => {
  const { control, readonly } = useContractForm();
  const { fieldRules } = useContractFields();

  const options = useMemo<RadioOption<ContractForm['financing']>[]>(
    () => [
      { value: 'financement_participatif', label: 'sales_management.financement_participatif' },
      { value: 'investissement_participatif', label: 'sales_management.investissement_participatif' },
      { value: 'none', label: 'sales_management.no_participatif' },
    ],
    [],
  );

  return (
    <div>
      <Controller
        name="financing"
        control={control}
        rules={fieldRules('financing')}
        render={({ field }) => (
          <RadioButtons
            label="sales_management.participatory_financing"
            options={options}
            onChange={(value) => field.onChange(value)}
            selected={field.value}
            name="financing"
            size="lg"
            readOnly={readonly}
          />
        )}
      />
    </div>
  );
};
